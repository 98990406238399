import { Auth } from "aws-amplify";
import { configureAmplify } from "./AmplifyService";
import { updateUserAttributes } from "./UserService";
configureAmplify();

const signIn = async params => {
  try {
    return await Auth.signIn(params.username, params.password);
  } catch (e) {
    throw e;
  }
};

const getCognitoUser = async () => {
  return Auth.currentAuthenticatedUser({
    bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
  });
};

const signUp = async (username, password) => {
  try {
    return await Auth.signUp({
      username,
      password
    });
  } catch (e) {
    throw e;
  }
};

export { signIn, signUp, getCognitoUser };
