import awsconfig from "./aws-exports";
import * as QUERIES from "./graphql/queries";
import * as MUTATIONS from "./graphql/mutations";
import * as SUBS from "./graphql/subscriptions";
import * as CUSTOM_QUERIES from "./custom-graphql/queries";
import * as CUSTOM_MUTATIONS from "./custom-graphql/mutations";

const AWS_CONFIG = awsconfig;

export {
  AWS_CONFIG,
  QUERIES,
  MUTATIONS,
  SUBS,
  CUSTOM_QUERIES,
  CUSTOM_MUTATIONS
}

