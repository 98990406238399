/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateCustomerInput = {
  id?: string | null,
  userID: string,
  universityID: string,
  studentID?: string | null,
  cartID: string,
  cbordPaymentToken?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  phoneNumber?: string | null,
  image_url?: string | null,
  image?: S3ObjectInput | null,
  readGroups?: Array< string | null > | null,
  _version?: number | null,
};

export type S3ObjectInput = {
  bucket: string,
  region: string,
  key: string,
};

export type ModelCustomerConditionInput = {
  universityID?: ModelIDInput | null,
  studentID?: ModelIDInput | null,
  cartID?: ModelIDInput | null,
  cbordPaymentToken?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  image_url?: ModelStringInput | null,
  and?: Array< ModelCustomerConditionInput | null > | null,
  or?: Array< ModelCustomerConditionInput | null > | null,
  not?: ModelCustomerConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type Customer = {
  __typename: "Customer",
  id?: string,
  userID?: string,
  universityID?: string,
  studentID?: string | null,
  cartID?: string,
  cbordPaymentToken?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  phoneNumber?: string | null,
  image_url?: string | null,
  image?: S3Object,
  readGroups?: Array< string | null > | null,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  createdAt?: string,
  updatedAt?: string,
  cart?: Cart,
};

export type S3Object = {
  __typename: "S3Object",
  bucket?: string,
  region?: string,
  key?: string,
};

export type Cart = {
  __typename: "Cart",
  id?: string,
  owner?: string | null,
  content?: string | null,
  updatedAt?: string | null,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  createdAt?: string,
};

export type UpdateCustomerInput = {
  id: string,
  userID?: string | null,
  universityID?: string | null,
  studentID?: string | null,
  cartID?: string | null,
  cbordPaymentToken?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  phoneNumber?: string | null,
  image_url?: string | null,
  image?: S3ObjectInput | null,
  readGroups?: Array< string | null > | null,
  _version?: number | null,
};

export type DeleteCustomerInput = {
  id: string,
  _version?: number | null,
};

export type CreateDelivererInput = {
  id?: string | null,
  userID: string,
  universityID: string,
  firstName?: string | null,
  lastName?: string | null,
  phoneNumber?: string | null,
  image_url?: string | null,
  image?: S3ObjectInput | null,
  status?: AccountStatus | null,
  invitationalEmail?: string | null,
  active?: boolean | null,
  _version?: number | null,
};

export enum AccountStatus {
  ACTIVE = "ACTIVE",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  INACTIVE = "INACTIVE",
}


export type ModelDelivererConditionInput = {
  universityID?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  image_url?: ModelStringInput | null,
  status?: ModelAccountStatusInput | null,
  invitationalEmail?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  and?: Array< ModelDelivererConditionInput | null > | null,
  or?: Array< ModelDelivererConditionInput | null > | null,
  not?: ModelDelivererConditionInput | null,
};

export type ModelAccountStatusInput = {
  eq?: AccountStatus | null,
  ne?: AccountStatus | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Deliverer = {
  __typename: "Deliverer",
  id?: string,
  userID?: string,
  universityID?: string,
  firstName?: string | null,
  lastName?: string | null,
  phoneNumber?: string | null,
  image_url?: string | null,
  image?: S3Object,
  status?: AccountStatus | null,
  invitationalEmail?: string | null,
  active?: boolean | null,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  createdAt?: string,
  updatedAt?: string,
  orders?: ModelOrderConnection,
  university?: University,
};

export type ModelOrderConnection = {
  __typename: "ModelOrderConnection",
  items?:  Array<Order | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Order = {
  __typename: "Order",
  id?: string,
  owner?: string | null,
  customerID?: string,
  venueID?: string,
  eventID?: string | null,
  delivererID?: string | null,
  price?: number | null,
  totalPrice?: number | null,
  status?: OrderStatus | null,
  notes?: string | null,
  createdAt?: string | null,
  timeSlot?: string | null,
  timeSlotID?: string | null,
  paymentMethod?: string | null,
  chargeID?: string | null,
  serviceOption?: TimeSlotType | null,
  locationName?: string | null,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  updatedAt?: string,
  customer?: Customer,
  history?: ModelOrderStateConnection,
  orderItems?: ModelOrderItemConnection,
  venue?: Venue,
};

export enum OrderStatus {
  COMPLETED = "COMPLETED",
  DRAFT = "DRAFT",
  READY_FOR_PICKUP = "READY_FOR_PICKUP",
  PENDING_VENDOR = "PENDING_VENDOR",
  REJECTED = "REJECTED",
  CANCELLED = "CANCELLED",
  IN_PROGRESS = "IN_PROGRESS",
  IN_TRANSIT = "IN_TRANSIT",
  INCOMPLETE = "INCOMPLETE",
}


export enum TimeSlotType {
  DINE_IN = "DINE_IN",
  PICK_UP = "PICK_UP",
  DELIVERY = "DELIVERY",
}


export type ModelOrderStateConnection = {
  __typename: "ModelOrderStateConnection",
  items?:  Array<OrderState | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type OrderState = {
  __typename: "OrderState",
  id?: string,
  owner?: string | null,
  orderID?: string,
  status?: OrderStatus | null,
  createdAt?: string | null,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  updatedAt?: string,
};

export type ModelOrderItemConnection = {
  __typename: "ModelOrderItemConnection",
  items?:  Array<OrderItem | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type OrderItem = {
  __typename: "OrderItem",
  id?: string,
  owner?: string | null,
  orderID?: string,
  foodID?: string,
  price?: number | null,
  amount?: number,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  createdAt?: string,
  updatedAt?: string,
  food?: Food,
};

export type Food = {
  __typename: "Food",
  id?: string,
  owner?: string | null,
  venueID?: string,
  name?: string | null,
  description?: string | null,
  price?: number | null,
  active?: boolean | null,
  calories?: number | null,
  foodType?: FoodType | null,
  image_url?: string | null,
  image?: S3Object,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  createdAt?: string,
  updatedAt?: string,
  menus?: ModelMenuFoodConnection,
};

export enum FoodType {
  DRINK = "DRINK",
  SNACK = "SNACK",
  ENTREE = "ENTREE",
  VEGAN = "VEGAN",
}


export type ModelMenuFoodConnection = {
  __typename: "ModelMenuFoodConnection",
  items?:  Array<MenuFood | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type MenuFood = {
  __typename: "MenuFood",
  id?: string,
  owner?: string | null,
  menuID?: string,
  foodID?: string,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  createdAt?: string,
  updatedAt?: string,
  menu?: Menu,
  food?: Food,
};

export type Menu = {
  __typename: "Menu",
  id?: string,
  owner?: string | null,
  venueID?: string,
  name?: string | null,
  description?: string | null,
  active?: boolean | null,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  createdAt?: string,
  updatedAt?: string,
  events?: ModelEventMenuConnection,
  foods?: ModelMenuFoodConnection,
};

export type ModelEventMenuConnection = {
  __typename: "ModelEventMenuConnection",
  items?:  Array<EventMenu | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type EventMenu = {
  __typename: "EventMenu",
  id?: string,
  owner?: string | null,
  eventID?: string,
  menuID?: string,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  createdAt?: string,
  updatedAt?: string,
  event?: Event,
  menu?: Menu,
};

export type Event = {
  __typename: "Event",
  id?: string,
  owner?: string | null,
  venueID?: string,
  name?: string | null,
  description?: string | null,
  active?: boolean | null,
  recurrenceFrequency?: RecurrenceFrequency | null,
  recurrenceInterval?: number | null,
  recurrenceCount?: number | null,
  recurrentDays?: Array< Weekday | null > | null,
  startDate?: string | null,
  endDate?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  duration?: number | null,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  createdAt?: string,
  updatedAt?: string,
  exceptions?: ModelEventExceptionConnection,
  menus?: ModelEventMenuConnection,
  timeSlots?: ModelTimeSlotConnection,
};

export enum RecurrenceFrequency {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
}


export enum Weekday {
  MON = "MON",
  TUE = "TUE",
  WED = "WED",
  THU = "THU",
  FRI = "FRI",
  SAT = "SAT",
  SUN = "SUN",
}


export type ModelEventExceptionConnection = {
  __typename: "ModelEventExceptionConnection",
  items?:  Array<EventException | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type EventException = {
  __typename: "EventException",
  id?: string,
  owner?: string | null,
  eventID?: string,
  name?: string | null,
  description?: string | null,
  type?: EventExceptionType | null,
  date?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  createdAt?: string,
  updatedAt?: string,
  menus?: ModelEventMenuConnection,
};

export enum EventExceptionType {
  CANCELLED = "CANCELLED",
  UPDATED = "UPDATED",
}


export type ModelTimeSlotConnection = {
  __typename: "ModelTimeSlotConnection",
  items?:  Array<TimeSlot | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type TimeSlot = {
  __typename: "TimeSlot",
  id?: string,
  owner?: string | null,
  venueID?: string | null,
  eventID?: string | null,
  type?: TimeSlotType | null,
  capacity?: number | null,
  position?: number | null,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  createdAt?: string,
  updatedAt?: string,
};

export type Venue = {
  __typename: "Venue",
  id?: string,
  universityID?: string,
  owner?: string | null,
  name?: string | null,
  businessName?: string | null,
  description?: string | null,
  status?: VendorStatus | null,
  type?: EventType | null,
  categoty?: VenueCategory | null,
  buffetPrice?: number | null,
  image_url?: string | null,
  image?: S3Object,
  payInApp?: boolean | null,
  payInPerson?: boolean | null,
  payWithCard?: boolean | null,
  payWithCash?: boolean | null,
  payWithStudentID?: boolean | null,
  payWithCurrency?: boolean | null,
  currency?: string | null,
  dineInAvailable?: boolean | null,
  reservationAvailable?: boolean | null,
  pickUpAvailable?: boolean | null,
  deliveryAvailable?: boolean | null,
  timeSlotsDineInCapacity?: number | null,
  timeSlotsPickUpCapacity?: number | null,
  timeSlotsDeliveryCapacity?: number | null,
  timeslotsIgnore?: boolean | null,
  timeslotsInterval?: number | null,
  timeSlotsStatus?: TimeSlotsStatus | null,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  createdAt?: string,
  updatedAt?: string,
  vendors?: ModelVendorConnection,
  events?: ModelEventConnection,
  reports?: ModelReportConnection,
};

export enum VendorStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  DEACTIVATED = "DEACTIVATED",
}


export enum EventType {
  BUFFET = "BUFFET",
  A_LA_CART = "A_LA_CART",
}


export enum VenueCategory {
  STADIUM = "STADIUM",
  DINING_HALL = "DINING_HALL",
}


export enum TimeSlotsStatus {
  ERROR = "ERROR",
  PROVISIONING = "PROVISIONING",
  READY = "READY",
  NEED_UPDATE = "NEED_UPDATE",
}


export type ModelVendorConnection = {
  __typename: "ModelVendorConnection",
  items?:  Array<Vendor | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Vendor = {
  __typename: "Vendor",
  id?: string,
  userID?: string,
  venueID?: string,
  universityID?: string,
  firstName?: string | null,
  lastName?: string | null,
  phoneNumber?: string | null,
  image_url?: string | null,
  image?: S3Object,
  status?: AccountStatus | null,
  invitationalEmail?: string | null,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  createdAt?: string,
  updatedAt?: string,
  parentUniversity?: University,
  parentVenue?: Venue,
};

export type University = {
  __typename: "University",
  id?: string,
  owner?: string | null,
  name?: string | null,
  description?: string | null,
  domain?: string | null,
  cbordUniversity?: string | null,
  cbordEnabled?: boolean | null,
  utcOffset?: number | null,
  image_url?: string | null,
  image?: S3Object,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  createdAt?: string,
  updatedAt?: string,
  businesses?: ModelBusinessConnection,
  venues?: ModelVenueConnection,
};

export type ModelBusinessConnection = {
  __typename: "ModelBusinessConnection",
  items?:  Array<Business | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Business = {
  __typename: "Business",
  id?: string,
  universityID?: string,
  providerID?: string,
  owner?: string | null,
  name?: string | null,
  description?: string | null,
  locationOptionOnSite?: boolean | null,
  locationOptionVisit?: boolean | null,
  shortLocation?: string | null,
  telephone?: string | null,
  email?: string | null,
  links?: Array< string | null > | null,
  status?: BusinessStatus | null,
  image_url?: string | null,
  image?: S3Object,
  categories?: Array< string | null > | null,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  createdAt?: string,
  updatedAt?: string,
  provider?: Provider,
  university?: University,
};

export enum BusinessStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  DEACTIVATED = "DEACTIVATED",
}


export type Provider = {
  __typename: "Provider",
  id?: string,
  userID?: string,
  universityID?: string,
  firstName?: string | null,
  lastName?: string | null,
  phoneNumber?: string | null,
  schoolMajor?: string | null,
  schoolYear?: string | null,
  schoolOrganizations?: Array< string | null > | null,
  links?: Array< string | null > | null,
  image_url?: string | null,
  image?: S3Object,
  status?: AccountStatus | null,
  invitationalEmail?: string | null,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  createdAt?: string,
  updatedAt?: string,
  university?: University,
  businesses?: ModelBusinessConnection,
};

export type ModelVenueConnection = {
  __typename: "ModelVenueConnection",
  items?:  Array<Venue | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelEventConnection = {
  __typename: "ModelEventConnection",
  items?:  Array<Event | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelReportConnection = {
  __typename: "ModelReportConnection",
  items?:  Array<Report | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Report = {
  __typename: "Report",
  id?: string,
  owner?: string | null,
  venueID?: string,
  reportUri?: string | null,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateDelivererInput = {
  id: string,
  userID?: string | null,
  universityID?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  phoneNumber?: string | null,
  image_url?: string | null,
  image?: S3ObjectInput | null,
  status?: AccountStatus | null,
  invitationalEmail?: string | null,
  active?: boolean | null,
  _version?: number | null,
};

export type DeleteDelivererInput = {
  id: string,
  _version?: number | null,
};

export type CreateDirectorInput = {
  id?: string | null,
  userID: string,
  universityID: string,
  firstName?: string | null,
  lastName?: string | null,
  phoneNumber?: string | null,
  image_url?: string | null,
  image?: S3ObjectInput | null,
  status?: AccountStatus | null,
  invitationalEmail?: string | null,
  _version?: number | null,
};

export type ModelDirectorConditionInput = {
  universityID?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  image_url?: ModelStringInput | null,
  status?: ModelAccountStatusInput | null,
  invitationalEmail?: ModelStringInput | null,
  and?: Array< ModelDirectorConditionInput | null > | null,
  or?: Array< ModelDirectorConditionInput | null > | null,
  not?: ModelDirectorConditionInput | null,
};

export type Director = {
  __typename: "Director",
  id?: string,
  userID?: string,
  universityID?: string,
  firstName?: string | null,
  lastName?: string | null,
  phoneNumber?: string | null,
  image_url?: string | null,
  image?: S3Object,
  status?: AccountStatus | null,
  invitationalEmail?: string | null,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  createdAt?: string,
  updatedAt?: string,
  university?: University,
};

export type UpdateDirectorInput = {
  id: string,
  userID?: string | null,
  universityID?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  phoneNumber?: string | null,
  image_url?: string | null,
  image?: S3ObjectInput | null,
  status?: AccountStatus | null,
  invitationalEmail?: string | null,
  _version?: number | null,
};

export type DeleteDirectorInput = {
  id: string,
  _version?: number | null,
};

export type CreateProviderInput = {
  id?: string | null,
  userID: string,
  universityID: string,
  firstName?: string | null,
  lastName?: string | null,
  phoneNumber?: string | null,
  schoolMajor?: string | null,
  schoolYear?: string | null,
  schoolOrganizations?: Array< string | null > | null,
  links?: Array< string | null > | null,
  image_url?: string | null,
  image?: S3ObjectInput | null,
  status?: AccountStatus | null,
  invitationalEmail?: string | null,
  _version?: number | null,
};

export type ModelProviderConditionInput = {
  universityID?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  schoolMajor?: ModelStringInput | null,
  schoolYear?: ModelStringInput | null,
  schoolOrganizations?: ModelStringInput | null,
  links?: ModelStringInput | null,
  image_url?: ModelStringInput | null,
  status?: ModelAccountStatusInput | null,
  invitationalEmail?: ModelStringInput | null,
  and?: Array< ModelProviderConditionInput | null > | null,
  or?: Array< ModelProviderConditionInput | null > | null,
  not?: ModelProviderConditionInput | null,
};

export type UpdateProviderInput = {
  id: string,
  userID?: string | null,
  universityID?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  phoneNumber?: string | null,
  schoolMajor?: string | null,
  schoolYear?: string | null,
  schoolOrganizations?: Array< string | null > | null,
  links?: Array< string | null > | null,
  image_url?: string | null,
  image?: S3ObjectInput | null,
  status?: AccountStatus | null,
  invitationalEmail?: string | null,
  _version?: number | null,
};

export type DeleteProviderInput = {
  id: string,
  _version?: number | null,
};

export type CreateVendorInput = {
  id?: string | null,
  userID: string,
  venueID: string,
  universityID: string,
  firstName?: string | null,
  lastName?: string | null,
  phoneNumber?: string | null,
  image_url?: string | null,
  image?: S3ObjectInput | null,
  status?: AccountStatus | null,
  invitationalEmail?: string | null,
  _version?: number | null,
};

export type ModelVendorConditionInput = {
  venueID?: ModelIDInput | null,
  universityID?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  image_url?: ModelStringInput | null,
  status?: ModelAccountStatusInput | null,
  invitationalEmail?: ModelStringInput | null,
  and?: Array< ModelVendorConditionInput | null > | null,
  or?: Array< ModelVendorConditionInput | null > | null,
  not?: ModelVendorConditionInput | null,
};

export type UpdateVendorInput = {
  id: string,
  userID?: string | null,
  venueID?: string | null,
  universityID?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  phoneNumber?: string | null,
  image_url?: string | null,
  image?: S3ObjectInput | null,
  status?: AccountStatus | null,
  invitationalEmail?: string | null,
  _version?: number | null,
};

export type DeleteVendorInput = {
  id: string,
  _version?: number | null,
};

export type CreateStaffInput = {
  id?: string | null,
  userID: string,
  venueID: string,
  universityID: string,
  firstName?: string | null,
  lastName?: string | null,
  phoneNumber?: string | null,
  image_url?: string | null,
  image?: S3ObjectInput | null,
  status?: AccountStatus | null,
  invitationalEmail?: string | null,
  _version?: number | null,
};

export type ModelStaffConditionInput = {
  venueID?: ModelIDInput | null,
  universityID?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  image_url?: ModelStringInput | null,
  status?: ModelAccountStatusInput | null,
  invitationalEmail?: ModelStringInput | null,
  and?: Array< ModelStaffConditionInput | null > | null,
  or?: Array< ModelStaffConditionInput | null > | null,
  not?: ModelStaffConditionInput | null,
};

export type Staff = {
  __typename: "Staff",
  id?: string,
  userID?: string,
  venueID?: string,
  universityID?: string,
  firstName?: string | null,
  lastName?: string | null,
  phoneNumber?: string | null,
  image_url?: string | null,
  image?: S3Object,
  status?: AccountStatus | null,
  invitationalEmail?: string | null,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  createdAt?: string,
  updatedAt?: string,
  parentUniversity?: University,
  parentVenue?: Venue,
};

export type UpdateStaffInput = {
  id: string,
  userID?: string | null,
  venueID?: string | null,
  universityID?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  phoneNumber?: string | null,
  image_url?: string | null,
  image?: S3ObjectInput | null,
  status?: AccountStatus | null,
  invitationalEmail?: string | null,
  _version?: number | null,
};

export type DeleteStaffInput = {
  id: string,
  _version?: number | null,
};

export type CreateCartInput = {
  id?: string | null,
  owner?: string | null,
  content?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type ModelCartConditionInput = {
  content?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCartConditionInput | null > | null,
  or?: Array< ModelCartConditionInput | null > | null,
  not?: ModelCartConditionInput | null,
};

export type UpdateCartInput = {
  id: string,
  owner?: string | null,
  content?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type DeleteCartInput = {
  id: string,
  _version?: number | null,
};

export type CreateReservationInput = {
  id?: string | null,
  owner?: string | null,
  customerID: string,
  venueID: string,
  eventID: string,
  status?: ReservationStatus | null,
  createdAt?: string | null,
  timeSlot?: string | null,
  timeSlotID?: string | null,
  serviceOption?: TimeSlotType | null,
  _version?: number | null,
};

export enum ReservationStatus {
  PLACED = "PLACED",
  COMPLETED = "COMPLETED",
  REJECTED = "REJECTED",
  CANCELLED = "CANCELLED",
  EXPIRED = "EXPIRED",
}


export type ModelReservationConditionInput = {
  customerID?: ModelIDInput | null,
  venueID?: ModelIDInput | null,
  eventID?: ModelIDInput | null,
  status?: ModelReservationStatusInput | null,
  createdAt?: ModelStringInput | null,
  timeSlot?: ModelStringInput | null,
  timeSlotID?: ModelStringInput | null,
  serviceOption?: ModelTimeSlotTypeInput | null,
  and?: Array< ModelReservationConditionInput | null > | null,
  or?: Array< ModelReservationConditionInput | null > | null,
  not?: ModelReservationConditionInput | null,
};

export type ModelReservationStatusInput = {
  eq?: ReservationStatus | null,
  ne?: ReservationStatus | null,
};

export type ModelTimeSlotTypeInput = {
  eq?: TimeSlotType | null,
  ne?: TimeSlotType | null,
};

export type Reservation = {
  __typename: "Reservation",
  id?: string,
  owner?: string | null,
  customerID?: string,
  venueID?: string,
  eventID?: string,
  status?: ReservationStatus | null,
  createdAt?: string | null,
  timeSlot?: string | null,
  timeSlotID?: string | null,
  serviceOption?: TimeSlotType | null,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  updatedAt?: string,
  customer?: Customer,
  venue?: Venue,
};

export type UpdateReservationInput = {
  id: string,
  owner?: string | null,
  customerID?: string | null,
  venueID?: string | null,
  eventID?: string | null,
  status?: ReservationStatus | null,
  createdAt?: string | null,
  timeSlot?: string | null,
  timeSlotID?: string | null,
  serviceOption?: TimeSlotType | null,
  _version?: number | null,
};

export type DeleteReservationInput = {
  id: string,
  _version?: number | null,
};

export type CreateOrderStateInput = {
  id?: string | null,
  owner?: string | null,
  orderID: string,
  status?: OrderStatus | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type ModelOrderStateConditionInput = {
  orderID?: ModelIDInput | null,
  status?: ModelOrderStatusInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelOrderStateConditionInput | null > | null,
  or?: Array< ModelOrderStateConditionInput | null > | null,
  not?: ModelOrderStateConditionInput | null,
};

export type ModelOrderStatusInput = {
  eq?: OrderStatus | null,
  ne?: OrderStatus | null,
};

export type UpdateOrderStateInput = {
  id: string,
  owner?: string | null,
  orderID?: string | null,
  status?: OrderStatus | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type DeleteOrderStateInput = {
  id: string,
  _version?: number | null,
};

export type CreateOrderInput = {
  id?: string | null,
  owner?: string | null,
  customerID: string,
  venueID: string,
  eventID?: string | null,
  delivererID?: string | null,
  price?: number | null,
  totalPrice?: number | null,
  status?: OrderStatus | null,
  notes?: string | null,
  createdAt?: string | null,
  timeSlot?: string | null,
  timeSlotID?: string | null,
  paymentMethod?: string | null,
  chargeID?: string | null,
  serviceOption?: TimeSlotType | null,
  locationName?: string | null,
  _version?: number | null,
};

export type ModelOrderConditionInput = {
  customerID?: ModelIDInput | null,
  venueID?: ModelIDInput | null,
  eventID?: ModelIDInput | null,
  delivererID?: ModelIDInput | null,
  price?: ModelIntInput | null,
  totalPrice?: ModelIntInput | null,
  status?: ModelOrderStatusInput | null,
  notes?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  timeSlot?: ModelStringInput | null,
  timeSlotID?: ModelStringInput | null,
  paymentMethod?: ModelStringInput | null,
  chargeID?: ModelStringInput | null,
  serviceOption?: ModelTimeSlotTypeInput | null,
  locationName?: ModelStringInput | null,
  and?: Array< ModelOrderConditionInput | null > | null,
  or?: Array< ModelOrderConditionInput | null > | null,
  not?: ModelOrderConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateOrderInput = {
  id: string,
  owner?: string | null,
  customerID?: string | null,
  venueID?: string | null,
  eventID?: string | null,
  delivererID?: string | null,
  price?: number | null,
  totalPrice?: number | null,
  status?: OrderStatus | null,
  notes?: string | null,
  createdAt?: string | null,
  timeSlot?: string | null,
  timeSlotID?: string | null,
  paymentMethod?: string | null,
  chargeID?: string | null,
  serviceOption?: TimeSlotType | null,
  locationName?: string | null,
  _version?: number | null,
};

export type DeleteOrderInput = {
  id: string,
  _version?: number | null,
};

export type CreateOrderItemInput = {
  id?: string | null,
  owner?: string | null,
  orderID: string,
  foodID: string,
  price?: number | null,
  amount: number,
  _version?: number | null,
};

export type ModelOrderItemConditionInput = {
  orderID?: ModelIDInput | null,
  foodID?: ModelIDInput | null,
  price?: ModelIntInput | null,
  amount?: ModelIntInput | null,
  and?: Array< ModelOrderItemConditionInput | null > | null,
  or?: Array< ModelOrderItemConditionInput | null > | null,
  not?: ModelOrderItemConditionInput | null,
};

export type UpdateOrderItemInput = {
  id: string,
  owner?: string | null,
  orderID?: string | null,
  foodID?: string | null,
  price?: number | null,
  amount?: number | null,
  _version?: number | null,
};

export type DeleteOrderItemInput = {
  id: string,
  _version?: number | null,
};

export type CreateUniversityInput = {
  id?: string | null,
  owner?: string | null,
  name?: string | null,
  description?: string | null,
  domain?: string | null,
  cbordUniversity?: string | null,
  cbordEnabled?: boolean | null,
  utcOffset?: number | null,
  image_url?: string | null,
  image?: S3ObjectInput | null,
  _version?: number | null,
};

export type ModelUniversityConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  cbordUniversity?: ModelStringInput | null,
  cbordEnabled?: ModelBooleanInput | null,
  utcOffset?: ModelIntInput | null,
  image_url?: ModelStringInput | null,
  and?: Array< ModelUniversityConditionInput | null > | null,
  or?: Array< ModelUniversityConditionInput | null > | null,
  not?: ModelUniversityConditionInput | null,
};

export type UpdateUniversityInput = {
  id: string,
  owner?: string | null,
  name?: string | null,
  description?: string | null,
  domain?: string | null,
  cbordUniversity?: string | null,
  cbordEnabled?: boolean | null,
  utcOffset?: number | null,
  image_url?: string | null,
  image?: S3ObjectInput | null,
  _version?: number | null,
};

export type DeleteUniversityInput = {
  id: string,
  _version?: number | null,
};

export type CreateBusinessInput = {
  id?: string | null,
  universityID: string,
  providerID: string,
  owner?: string | null,
  name?: string | null,
  description?: string | null,
  locationOptionOnSite?: boolean | null,
  locationOptionVisit?: boolean | null,
  shortLocation?: string | null,
  telephone?: string | null,
  email?: string | null,
  links?: Array< string | null > | null,
  status?: BusinessStatus | null,
  image_url?: string | null,
  image?: S3ObjectInput | null,
  categories?: Array< string | null > | null,
  _version?: number | null,
};

export type ModelBusinessConditionInput = {
  universityID?: ModelIDInput | null,
  providerID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  locationOptionOnSite?: ModelBooleanInput | null,
  locationOptionVisit?: ModelBooleanInput | null,
  shortLocation?: ModelStringInput | null,
  telephone?: ModelStringInput | null,
  email?: ModelStringInput | null,
  links?: ModelStringInput | null,
  status?: ModelBusinessStatusInput | null,
  image_url?: ModelStringInput | null,
  categories?: ModelStringInput | null,
  and?: Array< ModelBusinessConditionInput | null > | null,
  or?: Array< ModelBusinessConditionInput | null > | null,
  not?: ModelBusinessConditionInput | null,
};

export type ModelBusinessStatusInput = {
  eq?: BusinessStatus | null,
  ne?: BusinessStatus | null,
};

export type UpdateBusinessInput = {
  id: string,
  universityID?: string | null,
  providerID?: string | null,
  owner?: string | null,
  name?: string | null,
  description?: string | null,
  locationOptionOnSite?: boolean | null,
  locationOptionVisit?: boolean | null,
  shortLocation?: string | null,
  telephone?: string | null,
  email?: string | null,
  links?: Array< string | null > | null,
  status?: BusinessStatus | null,
  image_url?: string | null,
  image?: S3ObjectInput | null,
  categories?: Array< string | null > | null,
  _version?: number | null,
};

export type DeleteBusinessInput = {
  id: string,
  _version?: number | null,
};

export type CreateVenueInput = {
  id?: string | null,
  universityID: string,
  owner?: string | null,
  name?: string | null,
  businessName?: string | null,
  description?: string | null,
  status?: VendorStatus | null,
  type?: EventType | null,
  categoty?: VenueCategory | null,
  buffetPrice?: number | null,
  image_url?: string | null,
  image?: S3ObjectInput | null,
  payInApp?: boolean | null,
  payInPerson?: boolean | null,
  payWithCard?: boolean | null,
  payWithCash?: boolean | null,
  payWithStudentID?: boolean | null,
  payWithCurrency?: boolean | null,
  currency?: string | null,
  dineInAvailable?: boolean | null,
  reservationAvailable?: boolean | null,
  pickUpAvailable?: boolean | null,
  deliveryAvailable?: boolean | null,
  timeSlotsDineInCapacity?: number | null,
  timeSlotsPickUpCapacity?: number | null,
  timeSlotsDeliveryCapacity?: number | null,
  timeslotsIgnore?: boolean | null,
  timeslotsInterval?: number | null,
  timeSlotsStatus?: TimeSlotsStatus | null,
  _version?: number | null,
};

export type ModelVenueConditionInput = {
  universityID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  businessName?: ModelStringInput | null,
  description?: ModelStringInput | null,
  status?: ModelVendorStatusInput | null,
  type?: ModelEventTypeInput | null,
  categoty?: ModelVenueCategoryInput | null,
  buffetPrice?: ModelIntInput | null,
  image_url?: ModelStringInput | null,
  payInApp?: ModelBooleanInput | null,
  payInPerson?: ModelBooleanInput | null,
  payWithCard?: ModelBooleanInput | null,
  payWithCash?: ModelBooleanInput | null,
  payWithStudentID?: ModelBooleanInput | null,
  payWithCurrency?: ModelBooleanInput | null,
  currency?: ModelStringInput | null,
  dineInAvailable?: ModelBooleanInput | null,
  reservationAvailable?: ModelBooleanInput | null,
  pickUpAvailable?: ModelBooleanInput | null,
  deliveryAvailable?: ModelBooleanInput | null,
  timeSlotsDineInCapacity?: ModelIntInput | null,
  timeSlotsPickUpCapacity?: ModelIntInput | null,
  timeSlotsDeliveryCapacity?: ModelIntInput | null,
  timeslotsIgnore?: ModelBooleanInput | null,
  timeslotsInterval?: ModelIntInput | null,
  timeSlotsStatus?: ModelTimeSlotsStatusInput | null,
  and?: Array< ModelVenueConditionInput | null > | null,
  or?: Array< ModelVenueConditionInput | null > | null,
  not?: ModelVenueConditionInput | null,
};

export type ModelVendorStatusInput = {
  eq?: VendorStatus | null,
  ne?: VendorStatus | null,
};

export type ModelEventTypeInput = {
  eq?: EventType | null,
  ne?: EventType | null,
};

export type ModelVenueCategoryInput = {
  eq?: VenueCategory | null,
  ne?: VenueCategory | null,
};

export type ModelTimeSlotsStatusInput = {
  eq?: TimeSlotsStatus | null,
  ne?: TimeSlotsStatus | null,
};

export type UpdateVenueInput = {
  id: string,
  universityID?: string | null,
  owner?: string | null,
  name?: string | null,
  businessName?: string | null,
  description?: string | null,
  status?: VendorStatus | null,
  type?: EventType | null,
  categoty?: VenueCategory | null,
  buffetPrice?: number | null,
  image_url?: string | null,
  image?: S3ObjectInput | null,
  payInApp?: boolean | null,
  payInPerson?: boolean | null,
  payWithCard?: boolean | null,
  payWithCash?: boolean | null,
  payWithStudentID?: boolean | null,
  payWithCurrency?: boolean | null,
  currency?: string | null,
  dineInAvailable?: boolean | null,
  reservationAvailable?: boolean | null,
  pickUpAvailable?: boolean | null,
  deliveryAvailable?: boolean | null,
  timeSlotsDineInCapacity?: number | null,
  timeSlotsPickUpCapacity?: number | null,
  timeSlotsDeliveryCapacity?: number | null,
  timeslotsIgnore?: boolean | null,
  timeslotsInterval?: number | null,
  timeSlotsStatus?: TimeSlotsStatus | null,
  _version?: number | null,
};

export type DeleteVenueInput = {
  id: string,
  _version?: number | null,
};

export type CreateEventInput = {
  id?: string | null,
  owner?: string | null,
  venueID: string,
  name?: string | null,
  description?: string | null,
  active?: boolean | null,
  recurrenceFrequency?: RecurrenceFrequency | null,
  recurrenceInterval?: number | null,
  recurrenceCount?: number | null,
  recurrentDays?: Array< Weekday | null > | null,
  startDate?: string | null,
  endDate?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  duration?: number | null,
  _version?: number | null,
};

export type ModelEventConditionInput = {
  venueID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  recurrenceFrequency?: ModelRecurrenceFrequencyInput | null,
  recurrenceInterval?: ModelIntInput | null,
  recurrenceCount?: ModelIntInput | null,
  recurrentDays?: ModelWeekdayListInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  duration?: ModelIntInput | null,
  and?: Array< ModelEventConditionInput | null > | null,
  or?: Array< ModelEventConditionInput | null > | null,
  not?: ModelEventConditionInput | null,
};

export type ModelRecurrenceFrequencyInput = {
  eq?: RecurrenceFrequency | null,
  ne?: RecurrenceFrequency | null,
};

export type ModelWeekdayListInput = {
  eq?: Array< Weekday | null > | null,
  ne?: Array< Weekday | null > | null,
  contains?: Weekday | null,
  notContains?: Weekday | null,
};

export type UpdateEventInput = {
  id: string,
  owner?: string | null,
  venueID?: string | null,
  name?: string | null,
  description?: string | null,
  active?: boolean | null,
  recurrenceFrequency?: RecurrenceFrequency | null,
  recurrenceInterval?: number | null,
  recurrenceCount?: number | null,
  recurrentDays?: Array< Weekday | null > | null,
  startDate?: string | null,
  endDate?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  duration?: number | null,
  _version?: number | null,
};

export type DeleteEventInput = {
  id: string,
  _version?: number | null,
};

export type CreateEventExceptionInput = {
  id?: string | null,
  owner?: string | null,
  eventID: string,
  name?: string | null,
  description?: string | null,
  type?: EventExceptionType | null,
  date?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  _version?: number | null,
};

export type ModelEventExceptionConditionInput = {
  eventID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelEventExceptionTypeInput | null,
  date?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  and?: Array< ModelEventExceptionConditionInput | null > | null,
  or?: Array< ModelEventExceptionConditionInput | null > | null,
  not?: ModelEventExceptionConditionInput | null,
};

export type ModelEventExceptionTypeInput = {
  eq?: EventExceptionType | null,
  ne?: EventExceptionType | null,
};

export type UpdateEventExceptionInput = {
  id: string,
  owner?: string | null,
  eventID?: string | null,
  name?: string | null,
  description?: string | null,
  type?: EventExceptionType | null,
  date?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  _version?: number | null,
};

export type DeleteEventExceptionInput = {
  id: string,
  _version?: number | null,
};

export type CreateEventMenuInput = {
  id?: string | null,
  owner?: string | null,
  eventID: string,
  menuID: string,
  _version?: number | null,
};

export type ModelEventMenuConditionInput = {
  eventID?: ModelIDInput | null,
  menuID?: ModelIDInput | null,
  and?: Array< ModelEventMenuConditionInput | null > | null,
  or?: Array< ModelEventMenuConditionInput | null > | null,
  not?: ModelEventMenuConditionInput | null,
};

export type UpdateEventMenuInput = {
  id: string,
  owner?: string | null,
  eventID?: string | null,
  menuID?: string | null,
  _version?: number | null,
};

export type DeleteEventMenuInput = {
  id: string,
  _version?: number | null,
};

export type CreateMenuInput = {
  id?: string | null,
  owner?: string | null,
  venueID: string,
  name?: string | null,
  description?: string | null,
  active?: boolean | null,
  _version?: number | null,
};

export type ModelMenuConditionInput = {
  venueID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  and?: Array< ModelMenuConditionInput | null > | null,
  or?: Array< ModelMenuConditionInput | null > | null,
  not?: ModelMenuConditionInput | null,
};

export type UpdateMenuInput = {
  id: string,
  owner?: string | null,
  venueID?: string | null,
  name?: string | null,
  description?: string | null,
  active?: boolean | null,
  _version?: number | null,
};

export type DeleteMenuInput = {
  id: string,
  _version?: number | null,
};

export type CreateMenuFoodInput = {
  id?: string | null,
  owner?: string | null,
  menuID: string,
  foodID: string,
  _version?: number | null,
};

export type ModelMenuFoodConditionInput = {
  menuID?: ModelIDInput | null,
  foodID?: ModelIDInput | null,
  and?: Array< ModelMenuFoodConditionInput | null > | null,
  or?: Array< ModelMenuFoodConditionInput | null > | null,
  not?: ModelMenuFoodConditionInput | null,
};

export type UpdateMenuFoodInput = {
  id: string,
  owner?: string | null,
  menuID?: string | null,
  foodID?: string | null,
  _version?: number | null,
};

export type DeleteMenuFoodInput = {
  id: string,
  _version?: number | null,
};

export type CreateFoodInput = {
  id?: string | null,
  owner?: string | null,
  venueID: string,
  name?: string | null,
  description?: string | null,
  price?: number | null,
  active?: boolean | null,
  calories?: number | null,
  foodType?: FoodType | null,
  image_url?: string | null,
  image?: S3ObjectInput | null,
  _version?: number | null,
};

export type ModelFoodConditionInput = {
  venueID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  price?: ModelIntInput | null,
  active?: ModelBooleanInput | null,
  calories?: ModelIntInput | null,
  foodType?: ModelFoodTypeInput | null,
  image_url?: ModelStringInput | null,
  and?: Array< ModelFoodConditionInput | null > | null,
  or?: Array< ModelFoodConditionInput | null > | null,
  not?: ModelFoodConditionInput | null,
};

export type ModelFoodTypeInput = {
  eq?: FoodType | null,
  ne?: FoodType | null,
};

export type UpdateFoodInput = {
  id: string,
  owner?: string | null,
  venueID?: string | null,
  name?: string | null,
  description?: string | null,
  price?: number | null,
  active?: boolean | null,
  calories?: number | null,
  foodType?: FoodType | null,
  image_url?: string | null,
  image?: S3ObjectInput | null,
  _version?: number | null,
};

export type DeleteFoodInput = {
  id: string,
  _version?: number | null,
};

export type CreateTimeSlotInput = {
  id?: string | null,
  owner?: string | null,
  venueID?: string | null,
  eventID?: string | null,
  type?: TimeSlotType | null,
  capacity?: number | null,
  position?: number | null,
  _version?: number | null,
};

export type ModelTimeSlotConditionInput = {
  venueID?: ModelIDInput | null,
  eventID?: ModelIDInput | null,
  type?: ModelTimeSlotTypeInput | null,
  capacity?: ModelIntInput | null,
  position?: ModelIntInput | null,
  and?: Array< ModelTimeSlotConditionInput | null > | null,
  or?: Array< ModelTimeSlotConditionInput | null > | null,
  not?: ModelTimeSlotConditionInput | null,
};

export type UpdateTimeSlotInput = {
  id: string,
  owner?: string | null,
  venueID?: string | null,
  eventID?: string | null,
  type?: TimeSlotType | null,
  capacity?: number | null,
  position?: number | null,
  _version?: number | null,
};

export type DeleteTimeSlotInput = {
  id: string,
  _version?: number | null,
};

export type CreateReportInput = {
  id?: string | null,
  owner?: string | null,
  venueID: string,
  reportUri?: string | null,
  _version?: number | null,
};

export type ModelReportConditionInput = {
  venueID?: ModelIDInput | null,
  reportUri?: ModelStringInput | null,
  and?: Array< ModelReportConditionInput | null > | null,
  or?: Array< ModelReportConditionInput | null > | null,
  not?: ModelReportConditionInput | null,
};

export type UpdateReportInput = {
  id: string,
  owner?: string | null,
  venueID?: string | null,
  reportUri?: string | null,
  _version?: number | null,
};

export type DeleteReportInput = {
  id: string,
  _version?: number | null,
};

export type CreateAvailabilityInput = {
  id?: string | null,
  owner?: string | null,
  businessID: string,
  name: string,
  description?: string | null,
  active: boolean,
  recurrentDays: Array< Weekday | null >,
  startDate: string,
  endDate?: string | null,
  startTime: string,
  duration: number,
  _version?: number | null,
};

export type ModelAvailabilityConditionInput = {
  businessID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  recurrentDays?: ModelWeekdayListInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  duration?: ModelIntInput | null,
  and?: Array< ModelAvailabilityConditionInput | null > | null,
  or?: Array< ModelAvailabilityConditionInput | null > | null,
  not?: ModelAvailabilityConditionInput | null,
};

export type Availability = {
  __typename: "Availability",
  id?: string,
  owner?: string | null,
  businessID?: string,
  name?: string,
  description?: string | null,
  active?: boolean,
  recurrentDays?: Array< Weekday | null >,
  startDate?: string,
  endDate?: string | null,
  startTime?: string,
  duration?: number,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  createdAt?: string,
  updatedAt?: string,
  exceptions?: ModelAvailabilityExceptionConnection,
};

export type ModelAvailabilityExceptionConnection = {
  __typename: "ModelAvailabilityExceptionConnection",
  items?:  Array<AvailabilityException | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type AvailabilityException = {
  __typename: "AvailabilityException",
  id?: string,
  owner?: string | null,
  availabilityID?: string,
  name?: string | null,
  description?: string | null,
  type?: EventExceptionType | null,
  date?: string,
  startTime?: string,
  duration?: number,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateAvailabilityInput = {
  id: string,
  owner?: string | null,
  businessID?: string | null,
  name?: string | null,
  description?: string | null,
  active?: boolean | null,
  recurrentDays?: Array< Weekday | null > | null,
  startDate?: string | null,
  endDate?: string | null,
  startTime?: string | null,
  duration?: number | null,
  _version?: number | null,
};

export type DeleteAvailabilityInput = {
  id: string,
  _version?: number | null,
};

export type CreateAvailabilityExceptionInput = {
  id?: string | null,
  owner?: string | null,
  availabilityID: string,
  name?: string | null,
  description?: string | null,
  type?: EventExceptionType | null,
  date: string,
  startTime: string,
  duration: number,
  _version?: number | null,
};

export type ModelAvailabilityExceptionConditionInput = {
  availabilityID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelEventExceptionTypeInput | null,
  date?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  duration?: ModelIntInput | null,
  and?: Array< ModelAvailabilityExceptionConditionInput | null > | null,
  or?: Array< ModelAvailabilityExceptionConditionInput | null > | null,
  not?: ModelAvailabilityExceptionConditionInput | null,
};

export type UpdateAvailabilityExceptionInput = {
  id: string,
  owner?: string | null,
  availabilityID?: string | null,
  name?: string | null,
  description?: string | null,
  type?: EventExceptionType | null,
  date?: string | null,
  startTime?: string | null,
  duration?: number | null,
  _version?: number | null,
};

export type DeleteAvailabilityExceptionInput = {
  id: string,
  _version?: number | null,
};

export type CreateServiceInput = {
  id?: string | null,
  owner?: string | null,
  businessID: string,
  name: string,
  description: string,
  duration: number,
  price: number,
  active: boolean,
  _version?: number | null,
};

export type ModelServiceConditionInput = {
  businessID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  duration?: ModelIntInput | null,
  price?: ModelIntInput | null,
  active?: ModelBooleanInput | null,
  and?: Array< ModelServiceConditionInput | null > | null,
  or?: Array< ModelServiceConditionInput | null > | null,
  not?: ModelServiceConditionInput | null,
};

export type Service = {
  __typename: "Service",
  id?: string,
  owner?: string | null,
  businessID?: string,
  name?: string,
  description?: string,
  duration?: number,
  price?: number,
  active?: boolean,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  createdAt?: string,
  updatedAt?: string,
  business?: Business,
};

export type UpdateServiceInput = {
  id: string,
  owner?: string | null,
  businessID?: string | null,
  name?: string | null,
  description?: string | null,
  duration?: number | null,
  price?: number | null,
  active?: boolean | null,
  _version?: number | null,
};

export type DeleteServiceInput = {
  id: string,
  _version?: number | null,
};

export type CreateCategoryInput = {
  id?: string | null,
  owner?: string | null,
  title: string,
  description: string,
  _version?: number | null,
};

export type ModelCategoryConditionInput = {
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelCategoryConditionInput | null > | null,
  or?: Array< ModelCategoryConditionInput | null > | null,
  not?: ModelCategoryConditionInput | null,
};

export type Category = {
  __typename: "Category",
  id?: string,
  owner?: string | null,
  title?: string,
  description?: string,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateCategoryInput = {
  id: string,
  owner?: string | null,
  title?: string | null,
  description?: string | null,
  _version?: number | null,
};

export type DeleteCategoryInput = {
  id: string,
  _version?: number | null,
};

export type CreateBookingInput = {
  id?: string | null,
  owner?: string | null,
  businessID: string,
  customerID: string,
  serviceID: string,
  price?: number | null,
  status?: BookingStatus | null,
  createdAt?: string | null,
  chargeID?: string | null,
  note?: string | null,
  locationName?: string | null,
  date: string,
  time: string,
  duration: number,
  _version?: number | null,
};

export enum BookingStatus {
  PLACED = "PLACED",
  COMPLETED = "COMPLETED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  CANCELLED = "CANCELLED",
  EXPIRED = "EXPIRED",
  PAYMENT_ERROR = "PAYMENT_ERROR",
  NO_SHOW = "NO_SHOW",
}


export type ModelBookingConditionInput = {
  businessID?: ModelIDInput | null,
  customerID?: ModelIDInput | null,
  serviceID?: ModelIDInput | null,
  price?: ModelIntInput | null,
  status?: ModelBookingStatusInput | null,
  createdAt?: ModelStringInput | null,
  chargeID?: ModelStringInput | null,
  note?: ModelStringInput | null,
  locationName?: ModelStringInput | null,
  date?: ModelStringInput | null,
  time?: ModelStringInput | null,
  duration?: ModelIntInput | null,
  and?: Array< ModelBookingConditionInput | null > | null,
  or?: Array< ModelBookingConditionInput | null > | null,
  not?: ModelBookingConditionInput | null,
};

export type ModelBookingStatusInput = {
  eq?: BookingStatus | null,
  ne?: BookingStatus | null,
};

export type Booking = {
  __typename: "Booking",
  id?: string,
  owner?: string | null,
  businessID?: string,
  customerID?: string,
  serviceID?: string,
  price?: number | null,
  status?: BookingStatus | null,
  createdAt?: string | null,
  chargeID?: string | null,
  note?: string | null,
  locationName?: string | null,
  date?: string,
  time?: string,
  duration?: number,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  updatedAt?: string,
  customer?: Customer,
  business?: Business,
  service?: Service,
};

export type UpdateBookingInput = {
  id: string,
  owner?: string | null,
  businessID?: string | null,
  customerID?: string | null,
  serviceID?: string | null,
  price?: number | null,
  status?: BookingStatus | null,
  createdAt?: string | null,
  chargeID?: string | null,
  note?: string | null,
  locationName?: string | null,
  date?: string | null,
  time?: string | null,
  duration?: number | null,
  _version?: number | null,
};

export type DeleteBookingInput = {
  id: string,
  _version?: number | null,
};

export type ModelCustomerFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  universityID?: ModelIDInput | null,
  studentID?: ModelIDInput | null,
  cartID?: ModelIDInput | null,
  cbordPaymentToken?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  image_url?: ModelStringInput | null,
  readGroups?: ModelStringInput | null,
  and?: Array< ModelCustomerFilterInput | null > | null,
  or?: Array< ModelCustomerFilterInput | null > | null,
  not?: ModelCustomerFilterInput | null,
};

export type ModelCustomerConnection = {
  __typename: "ModelCustomerConnection",
  items?:  Array<Customer | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelDelivererFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  universityID?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  image_url?: ModelStringInput | null,
  status?: ModelAccountStatusInput | null,
  invitationalEmail?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  and?: Array< ModelDelivererFilterInput | null > | null,
  or?: Array< ModelDelivererFilterInput | null > | null,
  not?: ModelDelivererFilterInput | null,
};

export type ModelDelivererConnection = {
  __typename: "ModelDelivererConnection",
  items?:  Array<Deliverer | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelDirectorFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  universityID?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  image_url?: ModelStringInput | null,
  status?: ModelAccountStatusInput | null,
  invitationalEmail?: ModelStringInput | null,
  and?: Array< ModelDirectorFilterInput | null > | null,
  or?: Array< ModelDirectorFilterInput | null > | null,
  not?: ModelDirectorFilterInput | null,
};

export type ModelDirectorConnection = {
  __typename: "ModelDirectorConnection",
  items?:  Array<Director | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelProviderFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  universityID?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  schoolMajor?: ModelStringInput | null,
  schoolYear?: ModelStringInput | null,
  schoolOrganizations?: ModelStringInput | null,
  links?: ModelStringInput | null,
  image_url?: ModelStringInput | null,
  status?: ModelAccountStatusInput | null,
  invitationalEmail?: ModelStringInput | null,
  and?: Array< ModelProviderFilterInput | null > | null,
  or?: Array< ModelProviderFilterInput | null > | null,
  not?: ModelProviderFilterInput | null,
};

export type ModelProviderConnection = {
  __typename: "ModelProviderConnection",
  items?:  Array<Provider | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelVendorFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  venueID?: ModelIDInput | null,
  universityID?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  image_url?: ModelStringInput | null,
  status?: ModelAccountStatusInput | null,
  invitationalEmail?: ModelStringInput | null,
  and?: Array< ModelVendorFilterInput | null > | null,
  or?: Array< ModelVendorFilterInput | null > | null,
  not?: ModelVendorFilterInput | null,
};

export type ModelStaffFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  venueID?: ModelIDInput | null,
  universityID?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  image_url?: ModelStringInput | null,
  status?: ModelAccountStatusInput | null,
  invitationalEmail?: ModelStringInput | null,
  and?: Array< ModelStaffFilterInput | null > | null,
  or?: Array< ModelStaffFilterInput | null > | null,
  not?: ModelStaffFilterInput | null,
};

export type ModelStaffConnection = {
  __typename: "ModelStaffConnection",
  items?:  Array<Staff | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCartFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  content?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCartFilterInput | null > | null,
  or?: Array< ModelCartFilterInput | null > | null,
  not?: ModelCartFilterInput | null,
};

export type ModelCartConnection = {
  __typename: "ModelCartConnection",
  items?:  Array<Cart | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelReservationFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  customerID?: ModelIDInput | null,
  venueID?: ModelIDInput | null,
  eventID?: ModelIDInput | null,
  status?: ModelReservationStatusInput | null,
  createdAt?: ModelStringInput | null,
  timeSlot?: ModelStringInput | null,
  timeSlotID?: ModelStringInput | null,
  serviceOption?: ModelTimeSlotTypeInput | null,
  and?: Array< ModelReservationFilterInput | null > | null,
  or?: Array< ModelReservationFilterInput | null > | null,
  not?: ModelReservationFilterInput | null,
};

export type ModelReservationConnection = {
  __typename: "ModelReservationConnection",
  items?:  Array<Reservation | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelOrderStateFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  orderID?: ModelIDInput | null,
  status?: ModelOrderStatusInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelOrderStateFilterInput | null > | null,
  or?: Array< ModelOrderStateFilterInput | null > | null,
  not?: ModelOrderStateFilterInput | null,
};

export type ModelOrderFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  customerID?: ModelIDInput | null,
  venueID?: ModelIDInput | null,
  eventID?: ModelIDInput | null,
  delivererID?: ModelIDInput | null,
  price?: ModelIntInput | null,
  totalPrice?: ModelIntInput | null,
  status?: ModelOrderStatusInput | null,
  notes?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  timeSlot?: ModelStringInput | null,
  timeSlotID?: ModelStringInput | null,
  paymentMethod?: ModelStringInput | null,
  chargeID?: ModelStringInput | null,
  serviceOption?: ModelTimeSlotTypeInput | null,
  locationName?: ModelStringInput | null,
  and?: Array< ModelOrderFilterInput | null > | null,
  or?: Array< ModelOrderFilterInput | null > | null,
  not?: ModelOrderFilterInput | null,
};

export type ModelOrderItemFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  orderID?: ModelIDInput | null,
  foodID?: ModelIDInput | null,
  price?: ModelIntInput | null,
  amount?: ModelIntInput | null,
  and?: Array< ModelOrderItemFilterInput | null > | null,
  or?: Array< ModelOrderItemFilterInput | null > | null,
  not?: ModelOrderItemFilterInput | null,
};

export type ModelUniversityFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  cbordUniversity?: ModelStringInput | null,
  cbordEnabled?: ModelBooleanInput | null,
  utcOffset?: ModelIntInput | null,
  image_url?: ModelStringInput | null,
  and?: Array< ModelUniversityFilterInput | null > | null,
  or?: Array< ModelUniversityFilterInput | null > | null,
  not?: ModelUniversityFilterInput | null,
};

export type ModelUniversityConnection = {
  __typename: "ModelUniversityConnection",
  items?:  Array<University | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelBusinessFilterInput = {
  id?: ModelIDInput | null,
  universityID?: ModelIDInput | null,
  providerID?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  locationOptionOnSite?: ModelBooleanInput | null,
  locationOptionVisit?: ModelBooleanInput | null,
  shortLocation?: ModelStringInput | null,
  telephone?: ModelStringInput | null,
  email?: ModelStringInput | null,
  links?: ModelStringInput | null,
  status?: ModelBusinessStatusInput | null,
  image_url?: ModelStringInput | null,
  categories?: ModelStringInput | null,
  and?: Array< ModelBusinessFilterInput | null > | null,
  or?: Array< ModelBusinessFilterInput | null > | null,
  not?: ModelBusinessFilterInput | null,
};

export type ModelVenueFilterInput = {
  id?: ModelIDInput | null,
  universityID?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  name?: ModelStringInput | null,
  businessName?: ModelStringInput | null,
  description?: ModelStringInput | null,
  status?: ModelVendorStatusInput | null,
  type?: ModelEventTypeInput | null,
  categoty?: ModelVenueCategoryInput | null,
  buffetPrice?: ModelIntInput | null,
  image_url?: ModelStringInput | null,
  payInApp?: ModelBooleanInput | null,
  payInPerson?: ModelBooleanInput | null,
  payWithCard?: ModelBooleanInput | null,
  payWithCash?: ModelBooleanInput | null,
  payWithStudentID?: ModelBooleanInput | null,
  payWithCurrency?: ModelBooleanInput | null,
  currency?: ModelStringInput | null,
  dineInAvailable?: ModelBooleanInput | null,
  reservationAvailable?: ModelBooleanInput | null,
  pickUpAvailable?: ModelBooleanInput | null,
  deliveryAvailable?: ModelBooleanInput | null,
  timeSlotsDineInCapacity?: ModelIntInput | null,
  timeSlotsPickUpCapacity?: ModelIntInput | null,
  timeSlotsDeliveryCapacity?: ModelIntInput | null,
  timeslotsIgnore?: ModelBooleanInput | null,
  timeslotsInterval?: ModelIntInput | null,
  timeSlotsStatus?: ModelTimeSlotsStatusInput | null,
  and?: Array< ModelVenueFilterInput | null > | null,
  or?: Array< ModelVenueFilterInput | null > | null,
  not?: ModelVenueFilterInput | null,
};

export type ModelEventFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  venueID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  recurrenceFrequency?: ModelRecurrenceFrequencyInput | null,
  recurrenceInterval?: ModelIntInput | null,
  recurrenceCount?: ModelIntInput | null,
  recurrentDays?: ModelWeekdayListInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  duration?: ModelIntInput | null,
  and?: Array< ModelEventFilterInput | null > | null,
  or?: Array< ModelEventFilterInput | null > | null,
  not?: ModelEventFilterInput | null,
};

export type ModelEventExceptionFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  eventID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelEventExceptionTypeInput | null,
  date?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  and?: Array< ModelEventExceptionFilterInput | null > | null,
  or?: Array< ModelEventExceptionFilterInput | null > | null,
  not?: ModelEventExceptionFilterInput | null,
};

export type ModelEventMenuFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  eventID?: ModelIDInput | null,
  menuID?: ModelIDInput | null,
  and?: Array< ModelEventMenuFilterInput | null > | null,
  or?: Array< ModelEventMenuFilterInput | null > | null,
  not?: ModelEventMenuFilterInput | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelMenuFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  venueID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  and?: Array< ModelMenuFilterInput | null > | null,
  or?: Array< ModelMenuFilterInput | null > | null,
  not?: ModelMenuFilterInput | null,
};

export type ModelMenuConnection = {
  __typename: "ModelMenuConnection",
  items?:  Array<Menu | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelMenuFoodFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  menuID?: ModelIDInput | null,
  foodID?: ModelIDInput | null,
  and?: Array< ModelMenuFoodFilterInput | null > | null,
  or?: Array< ModelMenuFoodFilterInput | null > | null,
  not?: ModelMenuFoodFilterInput | null,
};

export type ModelFoodFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  venueID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  price?: ModelIntInput | null,
  active?: ModelBooleanInput | null,
  calories?: ModelIntInput | null,
  foodType?: ModelFoodTypeInput | null,
  image_url?: ModelStringInput | null,
  and?: Array< ModelFoodFilterInput | null > | null,
  or?: Array< ModelFoodFilterInput | null > | null,
  not?: ModelFoodFilterInput | null,
};

export type ModelFoodConnection = {
  __typename: "ModelFoodConnection",
  items?:  Array<Food | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTimeSlotFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  venueID?: ModelIDInput | null,
  eventID?: ModelIDInput | null,
  type?: ModelTimeSlotTypeInput | null,
  capacity?: ModelIntInput | null,
  position?: ModelIntInput | null,
  and?: Array< ModelTimeSlotFilterInput | null > | null,
  or?: Array< ModelTimeSlotFilterInput | null > | null,
  not?: ModelTimeSlotFilterInput | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelReportFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  venueID?: ModelIDInput | null,
  reportUri?: ModelStringInput | null,
  and?: Array< ModelReportFilterInput | null > | null,
  or?: Array< ModelReportFilterInput | null > | null,
  not?: ModelReportFilterInput | null,
};

export type ModelAvailabilityFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  businessID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  recurrentDays?: ModelWeekdayListInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  duration?: ModelIntInput | null,
  and?: Array< ModelAvailabilityFilterInput | null > | null,
  or?: Array< ModelAvailabilityFilterInput | null > | null,
  not?: ModelAvailabilityFilterInput | null,
};

export type ModelAvailabilityConnection = {
  __typename: "ModelAvailabilityConnection",
  items?:  Array<Availability | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAvailabilityExceptionFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  availabilityID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelEventExceptionTypeInput | null,
  date?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  duration?: ModelIntInput | null,
  and?: Array< ModelAvailabilityExceptionFilterInput | null > | null,
  or?: Array< ModelAvailabilityExceptionFilterInput | null > | null,
  not?: ModelAvailabilityExceptionFilterInput | null,
};

export type ModelServiceFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  businessID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  duration?: ModelIntInput | null,
  price?: ModelIntInput | null,
  active?: ModelBooleanInput | null,
  and?: Array< ModelServiceFilterInput | null > | null,
  or?: Array< ModelServiceFilterInput | null > | null,
  not?: ModelServiceFilterInput | null,
};

export type ModelServiceConnection = {
  __typename: "ModelServiceConnection",
  items?:  Array<Service | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCategoryFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelCategoryFilterInput | null > | null,
  or?: Array< ModelCategoryFilterInput | null > | null,
  not?: ModelCategoryFilterInput | null,
};

export type ModelCategoryConnection = {
  __typename: "ModelCategoryConnection",
  items?:  Array<Category | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelBookingFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  businessID?: ModelIDInput | null,
  customerID?: ModelIDInput | null,
  serviceID?: ModelIDInput | null,
  price?: ModelIntInput | null,
  status?: ModelBookingStatusInput | null,
  createdAt?: ModelStringInput | null,
  chargeID?: ModelStringInput | null,
  note?: ModelStringInput | null,
  locationName?: ModelStringInput | null,
  date?: ModelStringInput | null,
  time?: ModelStringInput | null,
  duration?: ModelIntInput | null,
  and?: Array< ModelBookingFilterInput | null > | null,
  or?: Array< ModelBookingFilterInput | null > | null,
  not?: ModelBookingFilterInput | null,
};

export type ModelBookingConnection = {
  __typename: "ModelBookingConnection",
  items?:  Array<Booking | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type CreateCustomerMutationVariables = {
  input?: CreateCustomerInput,
  condition?: ModelCustomerConditionInput | null,
};

export type CreateCustomerMutation = {
  createCustomer?:  {
    __typename: "Customer",
    id: string,
    userID: string,
    universityID: string,
    studentID?: string | null,
    cartID: string,
    cbordPaymentToken?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    readGroups?: Array< string | null > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    cart?:  {
      __typename: "Cart",
      id: string,
      owner?: string | null,
      content?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
    } | null,
  } | null,
};

export type UpdateCustomerMutationVariables = {
  input?: UpdateCustomerInput,
  condition?: ModelCustomerConditionInput | null,
};

export type UpdateCustomerMutation = {
  updateCustomer?:  {
    __typename: "Customer",
    id: string,
    userID: string,
    universityID: string,
    studentID?: string | null,
    cartID: string,
    cbordPaymentToken?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    readGroups?: Array< string | null > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    cart?:  {
      __typename: "Cart",
      id: string,
      owner?: string | null,
      content?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
    } | null,
  } | null,
};

export type DeleteCustomerMutationVariables = {
  input?: DeleteCustomerInput,
  condition?: ModelCustomerConditionInput | null,
};

export type DeleteCustomerMutation = {
  deleteCustomer?:  {
    __typename: "Customer",
    id: string,
    userID: string,
    universityID: string,
    studentID?: string | null,
    cartID: string,
    cbordPaymentToken?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    readGroups?: Array< string | null > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    cart?:  {
      __typename: "Cart",
      id: string,
      owner?: string | null,
      content?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
    } | null,
  } | null,
};

export type CreateDelivererMutationVariables = {
  input?: CreateDelivererInput,
  condition?: ModelDelivererConditionInput | null,
};

export type CreateDelivererMutation = {
  createDeliverer?:  {
    __typename: "Deliverer",
    id: string,
    userID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    active?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    orders?:  {
      __typename: "ModelOrderConnection",
      items?:  Array< {
        __typename: "Order",
        id: string,
        owner?: string | null,
        customerID: string,
        venueID: string,
        eventID?: string | null,
        delivererID?: string | null,
        price?: number | null,
        totalPrice?: number | null,
        status?: OrderStatus | null,
        notes?: string | null,
        createdAt?: string | null,
        timeSlot?: string | null,
        timeSlotID?: string | null,
        paymentMethod?: string | null,
        chargeID?: string | null,
        serviceOption?: TimeSlotType | null,
        locationName?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        updatedAt: string,
        customer?:  {
          __typename: "Customer",
          id: string,
          userID: string,
          universityID: string,
          studentID?: string | null,
          cartID: string,
          cbordPaymentToken?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          readGroups?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          cart?:  {
            __typename: "Cart",
            id: string,
            owner?: string | null,
            content?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
          } | null,
        } | null,
        history?:  {
          __typename: "ModelOrderStateConnection",
          items?:  Array< {
            __typename: "OrderState",
            id: string,
            owner?: string | null,
            orderID: string,
            status?: OrderStatus | null,
            createdAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orderItems?:  {
          __typename: "ModelOrderItemConnection",
          items?:  Array< {
            __typename: "OrderItem",
            id: string,
            owner?: string | null,
            orderID: string,
            foodID: string,
            price?: number | null,
            amount: number,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venue?:  {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    university?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateDelivererMutationVariables = {
  input?: UpdateDelivererInput,
  condition?: ModelDelivererConditionInput | null,
};

export type UpdateDelivererMutation = {
  updateDeliverer?:  {
    __typename: "Deliverer",
    id: string,
    userID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    active?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    orders?:  {
      __typename: "ModelOrderConnection",
      items?:  Array< {
        __typename: "Order",
        id: string,
        owner?: string | null,
        customerID: string,
        venueID: string,
        eventID?: string | null,
        delivererID?: string | null,
        price?: number | null,
        totalPrice?: number | null,
        status?: OrderStatus | null,
        notes?: string | null,
        createdAt?: string | null,
        timeSlot?: string | null,
        timeSlotID?: string | null,
        paymentMethod?: string | null,
        chargeID?: string | null,
        serviceOption?: TimeSlotType | null,
        locationName?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        updatedAt: string,
        customer?:  {
          __typename: "Customer",
          id: string,
          userID: string,
          universityID: string,
          studentID?: string | null,
          cartID: string,
          cbordPaymentToken?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          readGroups?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          cart?:  {
            __typename: "Cart",
            id: string,
            owner?: string | null,
            content?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
          } | null,
        } | null,
        history?:  {
          __typename: "ModelOrderStateConnection",
          items?:  Array< {
            __typename: "OrderState",
            id: string,
            owner?: string | null,
            orderID: string,
            status?: OrderStatus | null,
            createdAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orderItems?:  {
          __typename: "ModelOrderItemConnection",
          items?:  Array< {
            __typename: "OrderItem",
            id: string,
            owner?: string | null,
            orderID: string,
            foodID: string,
            price?: number | null,
            amount: number,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venue?:  {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    university?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteDelivererMutationVariables = {
  input?: DeleteDelivererInput,
  condition?: ModelDelivererConditionInput | null,
};

export type DeleteDelivererMutation = {
  deleteDeliverer?:  {
    __typename: "Deliverer",
    id: string,
    userID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    active?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    orders?:  {
      __typename: "ModelOrderConnection",
      items?:  Array< {
        __typename: "Order",
        id: string,
        owner?: string | null,
        customerID: string,
        venueID: string,
        eventID?: string | null,
        delivererID?: string | null,
        price?: number | null,
        totalPrice?: number | null,
        status?: OrderStatus | null,
        notes?: string | null,
        createdAt?: string | null,
        timeSlot?: string | null,
        timeSlotID?: string | null,
        paymentMethod?: string | null,
        chargeID?: string | null,
        serviceOption?: TimeSlotType | null,
        locationName?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        updatedAt: string,
        customer?:  {
          __typename: "Customer",
          id: string,
          userID: string,
          universityID: string,
          studentID?: string | null,
          cartID: string,
          cbordPaymentToken?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          readGroups?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          cart?:  {
            __typename: "Cart",
            id: string,
            owner?: string | null,
            content?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
          } | null,
        } | null,
        history?:  {
          __typename: "ModelOrderStateConnection",
          items?:  Array< {
            __typename: "OrderState",
            id: string,
            owner?: string | null,
            orderID: string,
            status?: OrderStatus | null,
            createdAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orderItems?:  {
          __typename: "ModelOrderItemConnection",
          items?:  Array< {
            __typename: "OrderItem",
            id: string,
            owner?: string | null,
            orderID: string,
            foodID: string,
            price?: number | null,
            amount: number,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venue?:  {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    university?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type CreateDirectorMutationVariables = {
  input?: CreateDirectorInput,
  condition?: ModelDirectorConditionInput | null,
};

export type CreateDirectorMutation = {
  createDirector?:  {
    __typename: "Director",
    id: string,
    userID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    university?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateDirectorMutationVariables = {
  input?: UpdateDirectorInput,
  condition?: ModelDirectorConditionInput | null,
};

export type UpdateDirectorMutation = {
  updateDirector?:  {
    __typename: "Director",
    id: string,
    userID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    university?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteDirectorMutationVariables = {
  input?: DeleteDirectorInput,
  condition?: ModelDirectorConditionInput | null,
};

export type DeleteDirectorMutation = {
  deleteDirector?:  {
    __typename: "Director",
    id: string,
    userID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    university?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type CreateProviderMutationVariables = {
  input?: CreateProviderInput,
  condition?: ModelProviderConditionInput | null,
};

export type CreateProviderMutation = {
  createProvider?:  {
    __typename: "Provider",
    id: string,
    userID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    schoolMajor?: string | null,
    schoolYear?: string | null,
    schoolOrganizations?: Array< string | null > | null,
    links?: Array< string | null > | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    university?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    businesses?:  {
      __typename: "ModelBusinessConnection",
      items?:  Array< {
        __typename: "Business",
        id: string,
        universityID: string,
        providerID: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        locationOptionOnSite?: boolean | null,
        locationOptionVisit?: boolean | null,
        shortLocation?: string | null,
        telephone?: string | null,
        email?: string | null,
        links?: Array< string | null > | null,
        status?: BusinessStatus | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        categories?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        provider?:  {
          __typename: "Provider",
          id: string,
          userID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          schoolMajor?: string | null,
          schoolYear?: string | null,
          schoolOrganizations?: Array< string | null > | null,
          links?: Array< string | null > | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type UpdateProviderMutationVariables = {
  input?: UpdateProviderInput,
  condition?: ModelProviderConditionInput | null,
};

export type UpdateProviderMutation = {
  updateProvider?:  {
    __typename: "Provider",
    id: string,
    userID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    schoolMajor?: string | null,
    schoolYear?: string | null,
    schoolOrganizations?: Array< string | null > | null,
    links?: Array< string | null > | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    university?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    businesses?:  {
      __typename: "ModelBusinessConnection",
      items?:  Array< {
        __typename: "Business",
        id: string,
        universityID: string,
        providerID: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        locationOptionOnSite?: boolean | null,
        locationOptionVisit?: boolean | null,
        shortLocation?: string | null,
        telephone?: string | null,
        email?: string | null,
        links?: Array< string | null > | null,
        status?: BusinessStatus | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        categories?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        provider?:  {
          __typename: "Provider",
          id: string,
          userID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          schoolMajor?: string | null,
          schoolYear?: string | null,
          schoolOrganizations?: Array< string | null > | null,
          links?: Array< string | null > | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type DeleteProviderMutationVariables = {
  input?: DeleteProviderInput,
  condition?: ModelProviderConditionInput | null,
};

export type DeleteProviderMutation = {
  deleteProvider?:  {
    __typename: "Provider",
    id: string,
    userID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    schoolMajor?: string | null,
    schoolYear?: string | null,
    schoolOrganizations?: Array< string | null > | null,
    links?: Array< string | null > | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    university?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    businesses?:  {
      __typename: "ModelBusinessConnection",
      items?:  Array< {
        __typename: "Business",
        id: string,
        universityID: string,
        providerID: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        locationOptionOnSite?: boolean | null,
        locationOptionVisit?: boolean | null,
        shortLocation?: string | null,
        telephone?: string | null,
        email?: string | null,
        links?: Array< string | null > | null,
        status?: BusinessStatus | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        categories?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        provider?:  {
          __typename: "Provider",
          id: string,
          userID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          schoolMajor?: string | null,
          schoolYear?: string | null,
          schoolOrganizations?: Array< string | null > | null,
          links?: Array< string | null > | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type CreateVendorMutationVariables = {
  input?: CreateVendorInput,
  condition?: ModelVendorConditionInput | null,
};

export type CreateVendorMutation = {
  createVendor?:  {
    __typename: "Vendor",
    id: string,
    userID: string,
    venueID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    parentUniversity?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    parentVenue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateVendorMutationVariables = {
  input?: UpdateVendorInput,
  condition?: ModelVendorConditionInput | null,
};

export type UpdateVendorMutation = {
  updateVendor?:  {
    __typename: "Vendor",
    id: string,
    userID: string,
    venueID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    parentUniversity?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    parentVenue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteVendorMutationVariables = {
  input?: DeleteVendorInput,
  condition?: ModelVendorConditionInput | null,
};

export type DeleteVendorMutation = {
  deleteVendor?:  {
    __typename: "Vendor",
    id: string,
    userID: string,
    venueID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    parentUniversity?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    parentVenue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type CreateStaffMutationVariables = {
  input?: CreateStaffInput,
  condition?: ModelStaffConditionInput | null,
};

export type CreateStaffMutation = {
  createStaff?:  {
    __typename: "Staff",
    id: string,
    userID: string,
    venueID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    parentUniversity?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    parentVenue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateStaffMutationVariables = {
  input?: UpdateStaffInput,
  condition?: ModelStaffConditionInput | null,
};

export type UpdateStaffMutation = {
  updateStaff?:  {
    __typename: "Staff",
    id: string,
    userID: string,
    venueID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    parentUniversity?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    parentVenue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteStaffMutationVariables = {
  input?: DeleteStaffInput,
  condition?: ModelStaffConditionInput | null,
};

export type DeleteStaffMutation = {
  deleteStaff?:  {
    __typename: "Staff",
    id: string,
    userID: string,
    venueID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    parentUniversity?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    parentVenue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type CreateCartMutationVariables = {
  input?: CreateCartInput,
  condition?: ModelCartConditionInput | null,
};

export type CreateCartMutation = {
  createCart?:  {
    __typename: "Cart",
    id: string,
    owner?: string | null,
    content?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
  } | null,
};

export type UpdateCartMutationVariables = {
  input?: UpdateCartInput,
  condition?: ModelCartConditionInput | null,
};

export type UpdateCartMutation = {
  updateCart?:  {
    __typename: "Cart",
    id: string,
    owner?: string | null,
    content?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
  } | null,
};

export type DeleteCartMutationVariables = {
  input?: DeleteCartInput,
  condition?: ModelCartConditionInput | null,
};

export type DeleteCartMutation = {
  deleteCart?:  {
    __typename: "Cart",
    id: string,
    owner?: string | null,
    content?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
  } | null,
};

export type CreateReservationMutationVariables = {
  input?: CreateReservationInput,
  condition?: ModelReservationConditionInput | null,
};

export type CreateReservationMutation = {
  createReservation?:  {
    __typename: "Reservation",
    id: string,
    owner?: string | null,
    customerID: string,
    venueID: string,
    eventID: string,
    status?: ReservationStatus | null,
    createdAt?: string | null,
    timeSlot?: string | null,
    timeSlotID?: string | null,
    serviceOption?: TimeSlotType | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null,
    venue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateReservationMutationVariables = {
  input?: UpdateReservationInput,
  condition?: ModelReservationConditionInput | null,
};

export type UpdateReservationMutation = {
  updateReservation?:  {
    __typename: "Reservation",
    id: string,
    owner?: string | null,
    customerID: string,
    venueID: string,
    eventID: string,
    status?: ReservationStatus | null,
    createdAt?: string | null,
    timeSlot?: string | null,
    timeSlotID?: string | null,
    serviceOption?: TimeSlotType | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null,
    venue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteReservationMutationVariables = {
  input?: DeleteReservationInput,
  condition?: ModelReservationConditionInput | null,
};

export type DeleteReservationMutation = {
  deleteReservation?:  {
    __typename: "Reservation",
    id: string,
    owner?: string | null,
    customerID: string,
    venueID: string,
    eventID: string,
    status?: ReservationStatus | null,
    createdAt?: string | null,
    timeSlot?: string | null,
    timeSlotID?: string | null,
    serviceOption?: TimeSlotType | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null,
    venue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type CreateOrderStateMutationVariables = {
  input?: CreateOrderStateInput,
  condition?: ModelOrderStateConditionInput | null,
};

export type CreateOrderStateMutation = {
  createOrderState?:  {
    __typename: "OrderState",
    id: string,
    owner?: string | null,
    orderID: string,
    status?: OrderStatus | null,
    createdAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
  } | null,
};

export type UpdateOrderStateMutationVariables = {
  input?: UpdateOrderStateInput,
  condition?: ModelOrderStateConditionInput | null,
};

export type UpdateOrderStateMutation = {
  updateOrderState?:  {
    __typename: "OrderState",
    id: string,
    owner?: string | null,
    orderID: string,
    status?: OrderStatus | null,
    createdAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
  } | null,
};

export type DeleteOrderStateMutationVariables = {
  input?: DeleteOrderStateInput,
  condition?: ModelOrderStateConditionInput | null,
};

export type DeleteOrderStateMutation = {
  deleteOrderState?:  {
    __typename: "OrderState",
    id: string,
    owner?: string | null,
    orderID: string,
    status?: OrderStatus | null,
    createdAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
  } | null,
};

export type CreateOrderMutationVariables = {
  input?: CreateOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type CreateOrderMutation = {
  createOrder?:  {
    __typename: "Order",
    id: string,
    owner?: string | null,
    customerID: string,
    venueID: string,
    eventID?: string | null,
    delivererID?: string | null,
    price?: number | null,
    totalPrice?: number | null,
    status?: OrderStatus | null,
    notes?: string | null,
    createdAt?: string | null,
    timeSlot?: string | null,
    timeSlotID?: string | null,
    paymentMethod?: string | null,
    chargeID?: string | null,
    serviceOption?: TimeSlotType | null,
    locationName?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null,
    history?:  {
      __typename: "ModelOrderStateConnection",
      items?:  Array< {
        __typename: "OrderState",
        id: string,
        owner?: string | null,
        orderID: string,
        status?: OrderStatus | null,
        createdAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    orderItems?:  {
      __typename: "ModelOrderItemConnection",
      items?:  Array< {
        __typename: "OrderItem",
        id: string,
        owner?: string | null,
        orderID: string,
        foodID: string,
        price?: number | null,
        amount: number,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        food?:  {
          __typename: "Food",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          active?: boolean | null,
          calories?: number | null,
          foodType?: FoodType | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    venue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateOrderMutationVariables = {
  input?: UpdateOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type UpdateOrderMutation = {
  updateOrder?:  {
    __typename: "Order",
    id: string,
    owner?: string | null,
    customerID: string,
    venueID: string,
    eventID?: string | null,
    delivererID?: string | null,
    price?: number | null,
    totalPrice?: number | null,
    status?: OrderStatus | null,
    notes?: string | null,
    createdAt?: string | null,
    timeSlot?: string | null,
    timeSlotID?: string | null,
    paymentMethod?: string | null,
    chargeID?: string | null,
    serviceOption?: TimeSlotType | null,
    locationName?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null,
    history?:  {
      __typename: "ModelOrderStateConnection",
      items?:  Array< {
        __typename: "OrderState",
        id: string,
        owner?: string | null,
        orderID: string,
        status?: OrderStatus | null,
        createdAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    orderItems?:  {
      __typename: "ModelOrderItemConnection",
      items?:  Array< {
        __typename: "OrderItem",
        id: string,
        owner?: string | null,
        orderID: string,
        foodID: string,
        price?: number | null,
        amount: number,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        food?:  {
          __typename: "Food",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          active?: boolean | null,
          calories?: number | null,
          foodType?: FoodType | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    venue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteOrderMutationVariables = {
  input?: DeleteOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type DeleteOrderMutation = {
  deleteOrder?:  {
    __typename: "Order",
    id: string,
    owner?: string | null,
    customerID: string,
    venueID: string,
    eventID?: string | null,
    delivererID?: string | null,
    price?: number | null,
    totalPrice?: number | null,
    status?: OrderStatus | null,
    notes?: string | null,
    createdAt?: string | null,
    timeSlot?: string | null,
    timeSlotID?: string | null,
    paymentMethod?: string | null,
    chargeID?: string | null,
    serviceOption?: TimeSlotType | null,
    locationName?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null,
    history?:  {
      __typename: "ModelOrderStateConnection",
      items?:  Array< {
        __typename: "OrderState",
        id: string,
        owner?: string | null,
        orderID: string,
        status?: OrderStatus | null,
        createdAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    orderItems?:  {
      __typename: "ModelOrderItemConnection",
      items?:  Array< {
        __typename: "OrderItem",
        id: string,
        owner?: string | null,
        orderID: string,
        foodID: string,
        price?: number | null,
        amount: number,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        food?:  {
          __typename: "Food",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          active?: boolean | null,
          calories?: number | null,
          foodType?: FoodType | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    venue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type CreateOrderItemMutationVariables = {
  input?: CreateOrderItemInput,
  condition?: ModelOrderItemConditionInput | null,
};

export type CreateOrderItemMutation = {
  createOrderItem?:  {
    __typename: "OrderItem",
    id: string,
    owner?: string | null,
    orderID: string,
    foodID: string,
    price?: number | null,
    amount: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    food?:  {
      __typename: "Food",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      price?: number | null,
      active?: boolean | null,
      calories?: number | null,
      foodType?: FoodType | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      menus?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateOrderItemMutationVariables = {
  input?: UpdateOrderItemInput,
  condition?: ModelOrderItemConditionInput | null,
};

export type UpdateOrderItemMutation = {
  updateOrderItem?:  {
    __typename: "OrderItem",
    id: string,
    owner?: string | null,
    orderID: string,
    foodID: string,
    price?: number | null,
    amount: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    food?:  {
      __typename: "Food",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      price?: number | null,
      active?: boolean | null,
      calories?: number | null,
      foodType?: FoodType | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      menus?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteOrderItemMutationVariables = {
  input?: DeleteOrderItemInput,
  condition?: ModelOrderItemConditionInput | null,
};

export type DeleteOrderItemMutation = {
  deleteOrderItem?:  {
    __typename: "OrderItem",
    id: string,
    owner?: string | null,
    orderID: string,
    foodID: string,
    price?: number | null,
    amount: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    food?:  {
      __typename: "Food",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      price?: number | null,
      active?: boolean | null,
      calories?: number | null,
      foodType?: FoodType | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      menus?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type CreateUniversityMutationVariables = {
  input?: CreateUniversityInput,
  condition?: ModelUniversityConditionInput | null,
};

export type CreateUniversityMutation = {
  createUniversity?:  {
    __typename: "University",
    id: string,
    owner?: string | null,
    name?: string | null,
    description?: string | null,
    domain?: string | null,
    cbordUniversity?: string | null,
    cbordEnabled?: boolean | null,
    utcOffset?: number | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    businesses?:  {
      __typename: "ModelBusinessConnection",
      items?:  Array< {
        __typename: "Business",
        id: string,
        universityID: string,
        providerID: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        locationOptionOnSite?: boolean | null,
        locationOptionVisit?: boolean | null,
        shortLocation?: string | null,
        telephone?: string | null,
        email?: string | null,
        links?: Array< string | null > | null,
        status?: BusinessStatus | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        categories?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        provider?:  {
          __typename: "Provider",
          id: string,
          userID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          schoolMajor?: string | null,
          schoolYear?: string | null,
          schoolOrganizations?: Array< string | null > | null,
          links?: Array< string | null > | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    venues?:  {
      __typename: "ModelVenueConnection",
      items?:  Array< {
        __typename: "Venue",
        id: string,
        universityID: string,
        owner?: string | null,
        name?: string | null,
        businessName?: string | null,
        description?: string | null,
        status?: VendorStatus | null,
        type?: EventType | null,
        categoty?: VenueCategory | null,
        buffetPrice?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        payInApp?: boolean | null,
        payInPerson?: boolean | null,
        payWithCard?: boolean | null,
        payWithCash?: boolean | null,
        payWithStudentID?: boolean | null,
        payWithCurrency?: boolean | null,
        currency?: string | null,
        dineInAvailable?: boolean | null,
        reservationAvailable?: boolean | null,
        pickUpAvailable?: boolean | null,
        deliveryAvailable?: boolean | null,
        timeSlotsDineInCapacity?: number | null,
        timeSlotsPickUpCapacity?: number | null,
        timeSlotsDeliveryCapacity?: number | null,
        timeslotsIgnore?: boolean | null,
        timeslotsInterval?: number | null,
        timeSlotsStatus?: TimeSlotsStatus | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        vendors?:  {
          __typename: "ModelVendorConnection",
          items?:  Array< {
            __typename: "Vendor",
            id: string,
            userID: string,
            venueID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        reports?:  {
          __typename: "ModelReportConnection",
          items?:  Array< {
            __typename: "Report",
            id: string,
            owner?: string | null,
            venueID: string,
            reportUri?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type UpdateUniversityMutationVariables = {
  input?: UpdateUniversityInput,
  condition?: ModelUniversityConditionInput | null,
};

export type UpdateUniversityMutation = {
  updateUniversity?:  {
    __typename: "University",
    id: string,
    owner?: string | null,
    name?: string | null,
    description?: string | null,
    domain?: string | null,
    cbordUniversity?: string | null,
    cbordEnabled?: boolean | null,
    utcOffset?: number | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    businesses?:  {
      __typename: "ModelBusinessConnection",
      items?:  Array< {
        __typename: "Business",
        id: string,
        universityID: string,
        providerID: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        locationOptionOnSite?: boolean | null,
        locationOptionVisit?: boolean | null,
        shortLocation?: string | null,
        telephone?: string | null,
        email?: string | null,
        links?: Array< string | null > | null,
        status?: BusinessStatus | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        categories?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        provider?:  {
          __typename: "Provider",
          id: string,
          userID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          schoolMajor?: string | null,
          schoolYear?: string | null,
          schoolOrganizations?: Array< string | null > | null,
          links?: Array< string | null > | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    venues?:  {
      __typename: "ModelVenueConnection",
      items?:  Array< {
        __typename: "Venue",
        id: string,
        universityID: string,
        owner?: string | null,
        name?: string | null,
        businessName?: string | null,
        description?: string | null,
        status?: VendorStatus | null,
        type?: EventType | null,
        categoty?: VenueCategory | null,
        buffetPrice?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        payInApp?: boolean | null,
        payInPerson?: boolean | null,
        payWithCard?: boolean | null,
        payWithCash?: boolean | null,
        payWithStudentID?: boolean | null,
        payWithCurrency?: boolean | null,
        currency?: string | null,
        dineInAvailable?: boolean | null,
        reservationAvailable?: boolean | null,
        pickUpAvailable?: boolean | null,
        deliveryAvailable?: boolean | null,
        timeSlotsDineInCapacity?: number | null,
        timeSlotsPickUpCapacity?: number | null,
        timeSlotsDeliveryCapacity?: number | null,
        timeslotsIgnore?: boolean | null,
        timeslotsInterval?: number | null,
        timeSlotsStatus?: TimeSlotsStatus | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        vendors?:  {
          __typename: "ModelVendorConnection",
          items?:  Array< {
            __typename: "Vendor",
            id: string,
            userID: string,
            venueID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        reports?:  {
          __typename: "ModelReportConnection",
          items?:  Array< {
            __typename: "Report",
            id: string,
            owner?: string | null,
            venueID: string,
            reportUri?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type DeleteUniversityMutationVariables = {
  input?: DeleteUniversityInput,
  condition?: ModelUniversityConditionInput | null,
};

export type DeleteUniversityMutation = {
  deleteUniversity?:  {
    __typename: "University",
    id: string,
    owner?: string | null,
    name?: string | null,
    description?: string | null,
    domain?: string | null,
    cbordUniversity?: string | null,
    cbordEnabled?: boolean | null,
    utcOffset?: number | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    businesses?:  {
      __typename: "ModelBusinessConnection",
      items?:  Array< {
        __typename: "Business",
        id: string,
        universityID: string,
        providerID: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        locationOptionOnSite?: boolean | null,
        locationOptionVisit?: boolean | null,
        shortLocation?: string | null,
        telephone?: string | null,
        email?: string | null,
        links?: Array< string | null > | null,
        status?: BusinessStatus | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        categories?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        provider?:  {
          __typename: "Provider",
          id: string,
          userID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          schoolMajor?: string | null,
          schoolYear?: string | null,
          schoolOrganizations?: Array< string | null > | null,
          links?: Array< string | null > | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    venues?:  {
      __typename: "ModelVenueConnection",
      items?:  Array< {
        __typename: "Venue",
        id: string,
        universityID: string,
        owner?: string | null,
        name?: string | null,
        businessName?: string | null,
        description?: string | null,
        status?: VendorStatus | null,
        type?: EventType | null,
        categoty?: VenueCategory | null,
        buffetPrice?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        payInApp?: boolean | null,
        payInPerson?: boolean | null,
        payWithCard?: boolean | null,
        payWithCash?: boolean | null,
        payWithStudentID?: boolean | null,
        payWithCurrency?: boolean | null,
        currency?: string | null,
        dineInAvailable?: boolean | null,
        reservationAvailable?: boolean | null,
        pickUpAvailable?: boolean | null,
        deliveryAvailable?: boolean | null,
        timeSlotsDineInCapacity?: number | null,
        timeSlotsPickUpCapacity?: number | null,
        timeSlotsDeliveryCapacity?: number | null,
        timeslotsIgnore?: boolean | null,
        timeslotsInterval?: number | null,
        timeSlotsStatus?: TimeSlotsStatus | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        vendors?:  {
          __typename: "ModelVendorConnection",
          items?:  Array< {
            __typename: "Vendor",
            id: string,
            userID: string,
            venueID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        reports?:  {
          __typename: "ModelReportConnection",
          items?:  Array< {
            __typename: "Report",
            id: string,
            owner?: string | null,
            venueID: string,
            reportUri?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type CreateBusinessMutationVariables = {
  input?: CreateBusinessInput,
  condition?: ModelBusinessConditionInput | null,
};

export type CreateBusinessMutation = {
  createBusiness?:  {
    __typename: "Business",
    id: string,
    universityID: string,
    providerID: string,
    owner?: string | null,
    name?: string | null,
    description?: string | null,
    locationOptionOnSite?: boolean | null,
    locationOptionVisit?: boolean | null,
    shortLocation?: string | null,
    telephone?: string | null,
    email?: string | null,
    links?: Array< string | null > | null,
    status?: BusinessStatus | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    categories?: Array< string | null > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    provider?:  {
      __typename: "Provider",
      id: string,
      userID: string,
      universityID: string,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      schoolMajor?: string | null,
      schoolYear?: string | null,
      schoolOrganizations?: Array< string | null > | null,
      links?: Array< string | null > | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: AccountStatus | null,
      invitationalEmail?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    university?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateBusinessMutationVariables = {
  input?: UpdateBusinessInput,
  condition?: ModelBusinessConditionInput | null,
};

export type UpdateBusinessMutation = {
  updateBusiness?:  {
    __typename: "Business",
    id: string,
    universityID: string,
    providerID: string,
    owner?: string | null,
    name?: string | null,
    description?: string | null,
    locationOptionOnSite?: boolean | null,
    locationOptionVisit?: boolean | null,
    shortLocation?: string | null,
    telephone?: string | null,
    email?: string | null,
    links?: Array< string | null > | null,
    status?: BusinessStatus | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    categories?: Array< string | null > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    provider?:  {
      __typename: "Provider",
      id: string,
      userID: string,
      universityID: string,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      schoolMajor?: string | null,
      schoolYear?: string | null,
      schoolOrganizations?: Array< string | null > | null,
      links?: Array< string | null > | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: AccountStatus | null,
      invitationalEmail?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    university?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteBusinessMutationVariables = {
  input?: DeleteBusinessInput,
  condition?: ModelBusinessConditionInput | null,
};

export type DeleteBusinessMutation = {
  deleteBusiness?:  {
    __typename: "Business",
    id: string,
    universityID: string,
    providerID: string,
    owner?: string | null,
    name?: string | null,
    description?: string | null,
    locationOptionOnSite?: boolean | null,
    locationOptionVisit?: boolean | null,
    shortLocation?: string | null,
    telephone?: string | null,
    email?: string | null,
    links?: Array< string | null > | null,
    status?: BusinessStatus | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    categories?: Array< string | null > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    provider?:  {
      __typename: "Provider",
      id: string,
      userID: string,
      universityID: string,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      schoolMajor?: string | null,
      schoolYear?: string | null,
      schoolOrganizations?: Array< string | null > | null,
      links?: Array< string | null > | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: AccountStatus | null,
      invitationalEmail?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    university?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type CreateVenueMutationVariables = {
  input?: CreateVenueInput,
  condition?: ModelVenueConditionInput | null,
};

export type CreateVenueMutation = {
  createVenue?:  {
    __typename: "Venue",
    id: string,
    universityID: string,
    owner?: string | null,
    name?: string | null,
    businessName?: string | null,
    description?: string | null,
    status?: VendorStatus | null,
    type?: EventType | null,
    categoty?: VenueCategory | null,
    buffetPrice?: number | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    payInApp?: boolean | null,
    payInPerson?: boolean | null,
    payWithCard?: boolean | null,
    payWithCash?: boolean | null,
    payWithStudentID?: boolean | null,
    payWithCurrency?: boolean | null,
    currency?: string | null,
    dineInAvailable?: boolean | null,
    reservationAvailable?: boolean | null,
    pickUpAvailable?: boolean | null,
    deliveryAvailable?: boolean | null,
    timeSlotsDineInCapacity?: number | null,
    timeSlotsPickUpCapacity?: number | null,
    timeSlotsDeliveryCapacity?: number | null,
    timeslotsIgnore?: boolean | null,
    timeslotsInterval?: number | null,
    timeSlotsStatus?: TimeSlotsStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    vendors?:  {
      __typename: "ModelVendorConnection",
      items?:  Array< {
        __typename: "Vendor",
        id: string,
        userID: string,
        venueID: string,
        universityID: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: AccountStatus | null,
        invitationalEmail?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        parentUniversity?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        parentVenue?:  {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items?:  Array< {
        __typename: "Event",
        id: string,
        owner?: string | null,
        venueID: string,
        name?: string | null,
        description?: string | null,
        active?: boolean | null,
        recurrenceFrequency?: RecurrenceFrequency | null,
        recurrenceInterval?: number | null,
        recurrenceCount?: number | null,
        recurrentDays?: Array< Weekday | null > | null,
        startDate?: string | null,
        endDate?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        duration?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        exceptions?:  {
          __typename: "ModelEventExceptionConnection",
          items?:  Array< {
            __typename: "EventException",
            id: string,
            owner?: string | null,
            eventID: string,
            name?: string | null,
            description?: string | null,
            type?: EventExceptionType | null,
            date?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        menus?:  {
          __typename: "ModelEventMenuConnection",
          items?:  Array< {
            __typename: "EventMenu",
            id: string,
            owner?: string | null,
            eventID: string,
            menuID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        timeSlots?:  {
          __typename: "ModelTimeSlotConnection",
          items?:  Array< {
            __typename: "TimeSlot",
            id: string,
            owner?: string | null,
            venueID?: string | null,
            eventID?: string | null,
            type?: TimeSlotType | null,
            capacity?: number | null,
            position?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    reports?:  {
      __typename: "ModelReportConnection",
      items?:  Array< {
        __typename: "Report",
        id: string,
        owner?: string | null,
        venueID: string,
        reportUri?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type UpdateVenueMutationVariables = {
  input?: UpdateVenueInput,
  condition?: ModelVenueConditionInput | null,
};

export type UpdateVenueMutation = {
  updateVenue?:  {
    __typename: "Venue",
    id: string,
    universityID: string,
    owner?: string | null,
    name?: string | null,
    businessName?: string | null,
    description?: string | null,
    status?: VendorStatus | null,
    type?: EventType | null,
    categoty?: VenueCategory | null,
    buffetPrice?: number | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    payInApp?: boolean | null,
    payInPerson?: boolean | null,
    payWithCard?: boolean | null,
    payWithCash?: boolean | null,
    payWithStudentID?: boolean | null,
    payWithCurrency?: boolean | null,
    currency?: string | null,
    dineInAvailable?: boolean | null,
    reservationAvailable?: boolean | null,
    pickUpAvailable?: boolean | null,
    deliveryAvailable?: boolean | null,
    timeSlotsDineInCapacity?: number | null,
    timeSlotsPickUpCapacity?: number | null,
    timeSlotsDeliveryCapacity?: number | null,
    timeslotsIgnore?: boolean | null,
    timeslotsInterval?: number | null,
    timeSlotsStatus?: TimeSlotsStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    vendors?:  {
      __typename: "ModelVendorConnection",
      items?:  Array< {
        __typename: "Vendor",
        id: string,
        userID: string,
        venueID: string,
        universityID: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: AccountStatus | null,
        invitationalEmail?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        parentUniversity?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        parentVenue?:  {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items?:  Array< {
        __typename: "Event",
        id: string,
        owner?: string | null,
        venueID: string,
        name?: string | null,
        description?: string | null,
        active?: boolean | null,
        recurrenceFrequency?: RecurrenceFrequency | null,
        recurrenceInterval?: number | null,
        recurrenceCount?: number | null,
        recurrentDays?: Array< Weekday | null > | null,
        startDate?: string | null,
        endDate?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        duration?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        exceptions?:  {
          __typename: "ModelEventExceptionConnection",
          items?:  Array< {
            __typename: "EventException",
            id: string,
            owner?: string | null,
            eventID: string,
            name?: string | null,
            description?: string | null,
            type?: EventExceptionType | null,
            date?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        menus?:  {
          __typename: "ModelEventMenuConnection",
          items?:  Array< {
            __typename: "EventMenu",
            id: string,
            owner?: string | null,
            eventID: string,
            menuID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        timeSlots?:  {
          __typename: "ModelTimeSlotConnection",
          items?:  Array< {
            __typename: "TimeSlot",
            id: string,
            owner?: string | null,
            venueID?: string | null,
            eventID?: string | null,
            type?: TimeSlotType | null,
            capacity?: number | null,
            position?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    reports?:  {
      __typename: "ModelReportConnection",
      items?:  Array< {
        __typename: "Report",
        id: string,
        owner?: string | null,
        venueID: string,
        reportUri?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type DeleteVenueMutationVariables = {
  input?: DeleteVenueInput,
  condition?: ModelVenueConditionInput | null,
};

export type DeleteVenueMutation = {
  deleteVenue?:  {
    __typename: "Venue",
    id: string,
    universityID: string,
    owner?: string | null,
    name?: string | null,
    businessName?: string | null,
    description?: string | null,
    status?: VendorStatus | null,
    type?: EventType | null,
    categoty?: VenueCategory | null,
    buffetPrice?: number | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    payInApp?: boolean | null,
    payInPerson?: boolean | null,
    payWithCard?: boolean | null,
    payWithCash?: boolean | null,
    payWithStudentID?: boolean | null,
    payWithCurrency?: boolean | null,
    currency?: string | null,
    dineInAvailable?: boolean | null,
    reservationAvailable?: boolean | null,
    pickUpAvailable?: boolean | null,
    deliveryAvailable?: boolean | null,
    timeSlotsDineInCapacity?: number | null,
    timeSlotsPickUpCapacity?: number | null,
    timeSlotsDeliveryCapacity?: number | null,
    timeslotsIgnore?: boolean | null,
    timeslotsInterval?: number | null,
    timeSlotsStatus?: TimeSlotsStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    vendors?:  {
      __typename: "ModelVendorConnection",
      items?:  Array< {
        __typename: "Vendor",
        id: string,
        userID: string,
        venueID: string,
        universityID: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: AccountStatus | null,
        invitationalEmail?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        parentUniversity?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        parentVenue?:  {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items?:  Array< {
        __typename: "Event",
        id: string,
        owner?: string | null,
        venueID: string,
        name?: string | null,
        description?: string | null,
        active?: boolean | null,
        recurrenceFrequency?: RecurrenceFrequency | null,
        recurrenceInterval?: number | null,
        recurrenceCount?: number | null,
        recurrentDays?: Array< Weekday | null > | null,
        startDate?: string | null,
        endDate?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        duration?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        exceptions?:  {
          __typename: "ModelEventExceptionConnection",
          items?:  Array< {
            __typename: "EventException",
            id: string,
            owner?: string | null,
            eventID: string,
            name?: string | null,
            description?: string | null,
            type?: EventExceptionType | null,
            date?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        menus?:  {
          __typename: "ModelEventMenuConnection",
          items?:  Array< {
            __typename: "EventMenu",
            id: string,
            owner?: string | null,
            eventID: string,
            menuID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        timeSlots?:  {
          __typename: "ModelTimeSlotConnection",
          items?:  Array< {
            __typename: "TimeSlot",
            id: string,
            owner?: string | null,
            venueID?: string | null,
            eventID?: string | null,
            type?: TimeSlotType | null,
            capacity?: number | null,
            position?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    reports?:  {
      __typename: "ModelReportConnection",
      items?:  Array< {
        __typename: "Report",
        id: string,
        owner?: string | null,
        venueID: string,
        reportUri?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type CreateEventMutationVariables = {
  input?: CreateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type CreateEventMutation = {
  createEvent?:  {
    __typename: "Event",
    id: string,
    owner?: string | null,
    venueID: string,
    name?: string | null,
    description?: string | null,
    active?: boolean | null,
    recurrenceFrequency?: RecurrenceFrequency | null,
    recurrenceInterval?: number | null,
    recurrenceCount?: number | null,
    recurrentDays?: Array< Weekday | null > | null,
    startDate?: string | null,
    endDate?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    duration?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    exceptions?:  {
      __typename: "ModelEventExceptionConnection",
      items?:  Array< {
        __typename: "EventException",
        id: string,
        owner?: string | null,
        eventID: string,
        name?: string | null,
        description?: string | null,
        type?: EventExceptionType | null,
        date?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        menus?:  {
          __typename: "ModelEventMenuConnection",
          items?:  Array< {
            __typename: "EventMenu",
            id: string,
            owner?: string | null,
            eventID: string,
            menuID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    menus?:  {
      __typename: "ModelEventMenuConnection",
      items?:  Array< {
        __typename: "EventMenu",
        id: string,
        owner?: string | null,
        eventID: string,
        menuID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        event:  {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    timeSlots?:  {
      __typename: "ModelTimeSlotConnection",
      items?:  Array< {
        __typename: "TimeSlot",
        id: string,
        owner?: string | null,
        venueID?: string | null,
        eventID?: string | null,
        type?: TimeSlotType | null,
        capacity?: number | null,
        position?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type UpdateEventMutationVariables = {
  input?: UpdateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type UpdateEventMutation = {
  updateEvent?:  {
    __typename: "Event",
    id: string,
    owner?: string | null,
    venueID: string,
    name?: string | null,
    description?: string | null,
    active?: boolean | null,
    recurrenceFrequency?: RecurrenceFrequency | null,
    recurrenceInterval?: number | null,
    recurrenceCount?: number | null,
    recurrentDays?: Array< Weekday | null > | null,
    startDate?: string | null,
    endDate?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    duration?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    exceptions?:  {
      __typename: "ModelEventExceptionConnection",
      items?:  Array< {
        __typename: "EventException",
        id: string,
        owner?: string | null,
        eventID: string,
        name?: string | null,
        description?: string | null,
        type?: EventExceptionType | null,
        date?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        menus?:  {
          __typename: "ModelEventMenuConnection",
          items?:  Array< {
            __typename: "EventMenu",
            id: string,
            owner?: string | null,
            eventID: string,
            menuID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    menus?:  {
      __typename: "ModelEventMenuConnection",
      items?:  Array< {
        __typename: "EventMenu",
        id: string,
        owner?: string | null,
        eventID: string,
        menuID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        event:  {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    timeSlots?:  {
      __typename: "ModelTimeSlotConnection",
      items?:  Array< {
        __typename: "TimeSlot",
        id: string,
        owner?: string | null,
        venueID?: string | null,
        eventID?: string | null,
        type?: TimeSlotType | null,
        capacity?: number | null,
        position?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type DeleteEventMutationVariables = {
  input?: DeleteEventInput,
  condition?: ModelEventConditionInput | null,
};

export type DeleteEventMutation = {
  deleteEvent?:  {
    __typename: "Event",
    id: string,
    owner?: string | null,
    venueID: string,
    name?: string | null,
    description?: string | null,
    active?: boolean | null,
    recurrenceFrequency?: RecurrenceFrequency | null,
    recurrenceInterval?: number | null,
    recurrenceCount?: number | null,
    recurrentDays?: Array< Weekday | null > | null,
    startDate?: string | null,
    endDate?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    duration?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    exceptions?:  {
      __typename: "ModelEventExceptionConnection",
      items?:  Array< {
        __typename: "EventException",
        id: string,
        owner?: string | null,
        eventID: string,
        name?: string | null,
        description?: string | null,
        type?: EventExceptionType | null,
        date?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        menus?:  {
          __typename: "ModelEventMenuConnection",
          items?:  Array< {
            __typename: "EventMenu",
            id: string,
            owner?: string | null,
            eventID: string,
            menuID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    menus?:  {
      __typename: "ModelEventMenuConnection",
      items?:  Array< {
        __typename: "EventMenu",
        id: string,
        owner?: string | null,
        eventID: string,
        menuID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        event:  {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    timeSlots?:  {
      __typename: "ModelTimeSlotConnection",
      items?:  Array< {
        __typename: "TimeSlot",
        id: string,
        owner?: string | null,
        venueID?: string | null,
        eventID?: string | null,
        type?: TimeSlotType | null,
        capacity?: number | null,
        position?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type CreateEventExceptionMutationVariables = {
  input?: CreateEventExceptionInput,
  condition?: ModelEventExceptionConditionInput | null,
};

export type CreateEventExceptionMutation = {
  createEventException?:  {
    __typename: "EventException",
    id: string,
    owner?: string | null,
    eventID: string,
    name?: string | null,
    description?: string | null,
    type?: EventExceptionType | null,
    date?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    menus?:  {
      __typename: "ModelEventMenuConnection",
      items?:  Array< {
        __typename: "EventMenu",
        id: string,
        owner?: string | null,
        eventID: string,
        menuID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        event:  {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type UpdateEventExceptionMutationVariables = {
  input?: UpdateEventExceptionInput,
  condition?: ModelEventExceptionConditionInput | null,
};

export type UpdateEventExceptionMutation = {
  updateEventException?:  {
    __typename: "EventException",
    id: string,
    owner?: string | null,
    eventID: string,
    name?: string | null,
    description?: string | null,
    type?: EventExceptionType | null,
    date?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    menus?:  {
      __typename: "ModelEventMenuConnection",
      items?:  Array< {
        __typename: "EventMenu",
        id: string,
        owner?: string | null,
        eventID: string,
        menuID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        event:  {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type DeleteEventExceptionMutationVariables = {
  input?: DeleteEventExceptionInput,
  condition?: ModelEventExceptionConditionInput | null,
};

export type DeleteEventExceptionMutation = {
  deleteEventException?:  {
    __typename: "EventException",
    id: string,
    owner?: string | null,
    eventID: string,
    name?: string | null,
    description?: string | null,
    type?: EventExceptionType | null,
    date?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    menus?:  {
      __typename: "ModelEventMenuConnection",
      items?:  Array< {
        __typename: "EventMenu",
        id: string,
        owner?: string | null,
        eventID: string,
        menuID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        event:  {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type CreateEventMenuMutationVariables = {
  input?: CreateEventMenuInput,
  condition?: ModelEventMenuConditionInput | null,
};

export type CreateEventMenuMutation = {
  createEventMenu?:  {
    __typename: "EventMenu",
    id: string,
    owner?: string | null,
    eventID: string,
    menuID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    event:  {
      __typename: "Event",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      active?: boolean | null,
      recurrenceFrequency?: RecurrenceFrequency | null,
      recurrenceInterval?: number | null,
      recurrenceCount?: number | null,
      recurrentDays?: Array< Weekday | null > | null,
      startDate?: string | null,
      endDate?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      duration?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      exceptions?:  {
        __typename: "ModelEventExceptionConnection",
        items?:  Array< {
          __typename: "EventException",
          id: string,
          owner?: string | null,
          eventID: string,
          name?: string | null,
          description?: string | null,
          type?: EventExceptionType | null,
          date?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      menus?:  {
        __typename: "ModelEventMenuConnection",
        items?:  Array< {
          __typename: "EventMenu",
          id: string,
          owner?: string | null,
          eventID: string,
          menuID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          event:  {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeSlots?:  {
        __typename: "ModelTimeSlotConnection",
        items?:  Array< {
          __typename: "TimeSlot",
          id: string,
          owner?: string | null,
          venueID?: string | null,
          eventID?: string | null,
          type?: TimeSlotType | null,
          capacity?: number | null,
          position?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
    menu:  {
      __typename: "Menu",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      active?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventMenuConnection",
        items?:  Array< {
          __typename: "EventMenu",
          id: string,
          owner?: string | null,
          eventID: string,
          menuID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          event:  {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      foods?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
  } | null,
};

export type UpdateEventMenuMutationVariables = {
  input?: UpdateEventMenuInput,
  condition?: ModelEventMenuConditionInput | null,
};

export type UpdateEventMenuMutation = {
  updateEventMenu?:  {
    __typename: "EventMenu",
    id: string,
    owner?: string | null,
    eventID: string,
    menuID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    event:  {
      __typename: "Event",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      active?: boolean | null,
      recurrenceFrequency?: RecurrenceFrequency | null,
      recurrenceInterval?: number | null,
      recurrenceCount?: number | null,
      recurrentDays?: Array< Weekday | null > | null,
      startDate?: string | null,
      endDate?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      duration?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      exceptions?:  {
        __typename: "ModelEventExceptionConnection",
        items?:  Array< {
          __typename: "EventException",
          id: string,
          owner?: string | null,
          eventID: string,
          name?: string | null,
          description?: string | null,
          type?: EventExceptionType | null,
          date?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      menus?:  {
        __typename: "ModelEventMenuConnection",
        items?:  Array< {
          __typename: "EventMenu",
          id: string,
          owner?: string | null,
          eventID: string,
          menuID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          event:  {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeSlots?:  {
        __typename: "ModelTimeSlotConnection",
        items?:  Array< {
          __typename: "TimeSlot",
          id: string,
          owner?: string | null,
          venueID?: string | null,
          eventID?: string | null,
          type?: TimeSlotType | null,
          capacity?: number | null,
          position?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
    menu:  {
      __typename: "Menu",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      active?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventMenuConnection",
        items?:  Array< {
          __typename: "EventMenu",
          id: string,
          owner?: string | null,
          eventID: string,
          menuID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          event:  {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      foods?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
  } | null,
};

export type DeleteEventMenuMutationVariables = {
  input?: DeleteEventMenuInput,
  condition?: ModelEventMenuConditionInput | null,
};

export type DeleteEventMenuMutation = {
  deleteEventMenu?:  {
    __typename: "EventMenu",
    id: string,
    owner?: string | null,
    eventID: string,
    menuID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    event:  {
      __typename: "Event",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      active?: boolean | null,
      recurrenceFrequency?: RecurrenceFrequency | null,
      recurrenceInterval?: number | null,
      recurrenceCount?: number | null,
      recurrentDays?: Array< Weekday | null > | null,
      startDate?: string | null,
      endDate?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      duration?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      exceptions?:  {
        __typename: "ModelEventExceptionConnection",
        items?:  Array< {
          __typename: "EventException",
          id: string,
          owner?: string | null,
          eventID: string,
          name?: string | null,
          description?: string | null,
          type?: EventExceptionType | null,
          date?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      menus?:  {
        __typename: "ModelEventMenuConnection",
        items?:  Array< {
          __typename: "EventMenu",
          id: string,
          owner?: string | null,
          eventID: string,
          menuID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          event:  {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeSlots?:  {
        __typename: "ModelTimeSlotConnection",
        items?:  Array< {
          __typename: "TimeSlot",
          id: string,
          owner?: string | null,
          venueID?: string | null,
          eventID?: string | null,
          type?: TimeSlotType | null,
          capacity?: number | null,
          position?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
    menu:  {
      __typename: "Menu",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      active?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventMenuConnection",
        items?:  Array< {
          __typename: "EventMenu",
          id: string,
          owner?: string | null,
          eventID: string,
          menuID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          event:  {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      foods?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
  } | null,
};

export type CreateMenuMutationVariables = {
  input?: CreateMenuInput,
  condition?: ModelMenuConditionInput | null,
};

export type CreateMenuMutation = {
  createMenu?:  {
    __typename: "Menu",
    id: string,
    owner?: string | null,
    venueID: string,
    name?: string | null,
    description?: string | null,
    active?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    events?:  {
      __typename: "ModelEventMenuConnection",
      items?:  Array< {
        __typename: "EventMenu",
        id: string,
        owner?: string | null,
        eventID: string,
        menuID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        event:  {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    foods?:  {
      __typename: "ModelMenuFoodConnection",
      items?:  Array< {
        __typename: "MenuFood",
        id: string,
        owner?: string | null,
        menuID: string,
        foodID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        food:  {
          __typename: "Food",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          active?: boolean | null,
          calories?: number | null,
          foodType?: FoodType | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type UpdateMenuMutationVariables = {
  input?: UpdateMenuInput,
  condition?: ModelMenuConditionInput | null,
};

export type UpdateMenuMutation = {
  updateMenu?:  {
    __typename: "Menu",
    id: string,
    owner?: string | null,
    venueID: string,
    name?: string | null,
    description?: string | null,
    active?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    events?:  {
      __typename: "ModelEventMenuConnection",
      items?:  Array< {
        __typename: "EventMenu",
        id: string,
        owner?: string | null,
        eventID: string,
        menuID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        event:  {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    foods?:  {
      __typename: "ModelMenuFoodConnection",
      items?:  Array< {
        __typename: "MenuFood",
        id: string,
        owner?: string | null,
        menuID: string,
        foodID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        food:  {
          __typename: "Food",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          active?: boolean | null,
          calories?: number | null,
          foodType?: FoodType | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type DeleteMenuMutationVariables = {
  input?: DeleteMenuInput,
  condition?: ModelMenuConditionInput | null,
};

export type DeleteMenuMutation = {
  deleteMenu?:  {
    __typename: "Menu",
    id: string,
    owner?: string | null,
    venueID: string,
    name?: string | null,
    description?: string | null,
    active?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    events?:  {
      __typename: "ModelEventMenuConnection",
      items?:  Array< {
        __typename: "EventMenu",
        id: string,
        owner?: string | null,
        eventID: string,
        menuID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        event:  {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    foods?:  {
      __typename: "ModelMenuFoodConnection",
      items?:  Array< {
        __typename: "MenuFood",
        id: string,
        owner?: string | null,
        menuID: string,
        foodID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        food:  {
          __typename: "Food",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          active?: boolean | null,
          calories?: number | null,
          foodType?: FoodType | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type CreateMenuFoodMutationVariables = {
  input?: CreateMenuFoodInput,
  condition?: ModelMenuFoodConditionInput | null,
};

export type CreateMenuFoodMutation = {
  createMenuFood?:  {
    __typename: "MenuFood",
    id: string,
    owner?: string | null,
    menuID: string,
    foodID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    menu:  {
      __typename: "Menu",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      active?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventMenuConnection",
        items?:  Array< {
          __typename: "EventMenu",
          id: string,
          owner?: string | null,
          eventID: string,
          menuID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          event:  {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      foods?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
    food:  {
      __typename: "Food",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      price?: number | null,
      active?: boolean | null,
      calories?: number | null,
      foodType?: FoodType | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      menus?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
  } | null,
};

export type UpdateMenuFoodMutationVariables = {
  input?: UpdateMenuFoodInput,
  condition?: ModelMenuFoodConditionInput | null,
};

export type UpdateMenuFoodMutation = {
  updateMenuFood?:  {
    __typename: "MenuFood",
    id: string,
    owner?: string | null,
    menuID: string,
    foodID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    menu:  {
      __typename: "Menu",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      active?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventMenuConnection",
        items?:  Array< {
          __typename: "EventMenu",
          id: string,
          owner?: string | null,
          eventID: string,
          menuID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          event:  {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      foods?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
    food:  {
      __typename: "Food",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      price?: number | null,
      active?: boolean | null,
      calories?: number | null,
      foodType?: FoodType | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      menus?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
  } | null,
};

export type DeleteMenuFoodMutationVariables = {
  input?: DeleteMenuFoodInput,
  condition?: ModelMenuFoodConditionInput | null,
};

export type DeleteMenuFoodMutation = {
  deleteMenuFood?:  {
    __typename: "MenuFood",
    id: string,
    owner?: string | null,
    menuID: string,
    foodID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    menu:  {
      __typename: "Menu",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      active?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventMenuConnection",
        items?:  Array< {
          __typename: "EventMenu",
          id: string,
          owner?: string | null,
          eventID: string,
          menuID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          event:  {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      foods?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
    food:  {
      __typename: "Food",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      price?: number | null,
      active?: boolean | null,
      calories?: number | null,
      foodType?: FoodType | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      menus?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
  } | null,
};

export type CreateFoodMutationVariables = {
  input?: CreateFoodInput,
  condition?: ModelFoodConditionInput | null,
};

export type CreateFoodMutation = {
  createFood?:  {
    __typename: "Food",
    id: string,
    owner?: string | null,
    venueID: string,
    name?: string | null,
    description?: string | null,
    price?: number | null,
    active?: boolean | null,
    calories?: number | null,
    foodType?: FoodType | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    menus?:  {
      __typename: "ModelMenuFoodConnection",
      items?:  Array< {
        __typename: "MenuFood",
        id: string,
        owner?: string | null,
        menuID: string,
        foodID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        food:  {
          __typename: "Food",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          active?: boolean | null,
          calories?: number | null,
          foodType?: FoodType | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type UpdateFoodMutationVariables = {
  input?: UpdateFoodInput,
  condition?: ModelFoodConditionInput | null,
};

export type UpdateFoodMutation = {
  updateFood?:  {
    __typename: "Food",
    id: string,
    owner?: string | null,
    venueID: string,
    name?: string | null,
    description?: string | null,
    price?: number | null,
    active?: boolean | null,
    calories?: number | null,
    foodType?: FoodType | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    menus?:  {
      __typename: "ModelMenuFoodConnection",
      items?:  Array< {
        __typename: "MenuFood",
        id: string,
        owner?: string | null,
        menuID: string,
        foodID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        food:  {
          __typename: "Food",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          active?: boolean | null,
          calories?: number | null,
          foodType?: FoodType | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type DeleteFoodMutationVariables = {
  input?: DeleteFoodInput,
  condition?: ModelFoodConditionInput | null,
};

export type DeleteFoodMutation = {
  deleteFood?:  {
    __typename: "Food",
    id: string,
    owner?: string | null,
    venueID: string,
    name?: string | null,
    description?: string | null,
    price?: number | null,
    active?: boolean | null,
    calories?: number | null,
    foodType?: FoodType | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    menus?:  {
      __typename: "ModelMenuFoodConnection",
      items?:  Array< {
        __typename: "MenuFood",
        id: string,
        owner?: string | null,
        menuID: string,
        foodID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        food:  {
          __typename: "Food",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          active?: boolean | null,
          calories?: number | null,
          foodType?: FoodType | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type CreateTimeSlotMutationVariables = {
  input?: CreateTimeSlotInput,
  condition?: ModelTimeSlotConditionInput | null,
};

export type CreateTimeSlotMutation = {
  createTimeSlot?:  {
    __typename: "TimeSlot",
    id: string,
    owner?: string | null,
    venueID?: string | null,
    eventID?: string | null,
    type?: TimeSlotType | null,
    capacity?: number | null,
    position?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTimeSlotMutationVariables = {
  input?: UpdateTimeSlotInput,
  condition?: ModelTimeSlotConditionInput | null,
};

export type UpdateTimeSlotMutation = {
  updateTimeSlot?:  {
    __typename: "TimeSlot",
    id: string,
    owner?: string | null,
    venueID?: string | null,
    eventID?: string | null,
    type?: TimeSlotType | null,
    capacity?: number | null,
    position?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTimeSlotMutationVariables = {
  input?: DeleteTimeSlotInput,
  condition?: ModelTimeSlotConditionInput | null,
};

export type DeleteTimeSlotMutation = {
  deleteTimeSlot?:  {
    __typename: "TimeSlot",
    id: string,
    owner?: string | null,
    venueID?: string | null,
    eventID?: string | null,
    type?: TimeSlotType | null,
    capacity?: number | null,
    position?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateReportMutationVariables = {
  input?: CreateReportInput,
  condition?: ModelReportConditionInput | null,
};

export type CreateReportMutation = {
  createReport?:  {
    __typename: "Report",
    id: string,
    owner?: string | null,
    venueID: string,
    reportUri?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateReportMutationVariables = {
  input?: UpdateReportInput,
  condition?: ModelReportConditionInput | null,
};

export type UpdateReportMutation = {
  updateReport?:  {
    __typename: "Report",
    id: string,
    owner?: string | null,
    venueID: string,
    reportUri?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteReportMutationVariables = {
  input?: DeleteReportInput,
  condition?: ModelReportConditionInput | null,
};

export type DeleteReportMutation = {
  deleteReport?:  {
    __typename: "Report",
    id: string,
    owner?: string | null,
    venueID: string,
    reportUri?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAvailabilityMutationVariables = {
  input?: CreateAvailabilityInput,
  condition?: ModelAvailabilityConditionInput | null,
};

export type CreateAvailabilityMutation = {
  createAvailability?:  {
    __typename: "Availability",
    id: string,
    owner?: string | null,
    businessID: string,
    name: string,
    description?: string | null,
    active: boolean,
    recurrentDays: Array< Weekday | null >,
    startDate: string,
    endDate?: string | null,
    startTime: string,
    duration: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    exceptions?:  {
      __typename: "ModelAvailabilityExceptionConnection",
      items?:  Array< {
        __typename: "AvailabilityException",
        id: string,
        owner?: string | null,
        availabilityID: string,
        name?: string | null,
        description?: string | null,
        type?: EventExceptionType | null,
        date: string,
        startTime: string,
        duration: number,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type UpdateAvailabilityMutationVariables = {
  input?: UpdateAvailabilityInput,
  condition?: ModelAvailabilityConditionInput | null,
};

export type UpdateAvailabilityMutation = {
  updateAvailability?:  {
    __typename: "Availability",
    id: string,
    owner?: string | null,
    businessID: string,
    name: string,
    description?: string | null,
    active: boolean,
    recurrentDays: Array< Weekday | null >,
    startDate: string,
    endDate?: string | null,
    startTime: string,
    duration: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    exceptions?:  {
      __typename: "ModelAvailabilityExceptionConnection",
      items?:  Array< {
        __typename: "AvailabilityException",
        id: string,
        owner?: string | null,
        availabilityID: string,
        name?: string | null,
        description?: string | null,
        type?: EventExceptionType | null,
        date: string,
        startTime: string,
        duration: number,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type DeleteAvailabilityMutationVariables = {
  input?: DeleteAvailabilityInput,
  condition?: ModelAvailabilityConditionInput | null,
};

export type DeleteAvailabilityMutation = {
  deleteAvailability?:  {
    __typename: "Availability",
    id: string,
    owner?: string | null,
    businessID: string,
    name: string,
    description?: string | null,
    active: boolean,
    recurrentDays: Array< Weekday | null >,
    startDate: string,
    endDate?: string | null,
    startTime: string,
    duration: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    exceptions?:  {
      __typename: "ModelAvailabilityExceptionConnection",
      items?:  Array< {
        __typename: "AvailabilityException",
        id: string,
        owner?: string | null,
        availabilityID: string,
        name?: string | null,
        description?: string | null,
        type?: EventExceptionType | null,
        date: string,
        startTime: string,
        duration: number,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type CreateAvailabilityExceptionMutationVariables = {
  input?: CreateAvailabilityExceptionInput,
  condition?: ModelAvailabilityExceptionConditionInput | null,
};

export type CreateAvailabilityExceptionMutation = {
  createAvailabilityException?:  {
    __typename: "AvailabilityException",
    id: string,
    owner?: string | null,
    availabilityID: string,
    name?: string | null,
    description?: string | null,
    type?: EventExceptionType | null,
    date: string,
    startTime: string,
    duration: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAvailabilityExceptionMutationVariables = {
  input?: UpdateAvailabilityExceptionInput,
  condition?: ModelAvailabilityExceptionConditionInput | null,
};

export type UpdateAvailabilityExceptionMutation = {
  updateAvailabilityException?:  {
    __typename: "AvailabilityException",
    id: string,
    owner?: string | null,
    availabilityID: string,
    name?: string | null,
    description?: string | null,
    type?: EventExceptionType | null,
    date: string,
    startTime: string,
    duration: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAvailabilityExceptionMutationVariables = {
  input?: DeleteAvailabilityExceptionInput,
  condition?: ModelAvailabilityExceptionConditionInput | null,
};

export type DeleteAvailabilityExceptionMutation = {
  deleteAvailabilityException?:  {
    __typename: "AvailabilityException",
    id: string,
    owner?: string | null,
    availabilityID: string,
    name?: string | null,
    description?: string | null,
    type?: EventExceptionType | null,
    date: string,
    startTime: string,
    duration: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateServiceMutationVariables = {
  input?: CreateServiceInput,
  condition?: ModelServiceConditionInput | null,
};

export type CreateServiceMutation = {
  createService?:  {
    __typename: "Service",
    id: string,
    owner?: string | null,
    businessID: string,
    name: string,
    description: string,
    duration: number,
    price: number,
    active: boolean,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    business?:  {
      __typename: "Business",
      id: string,
      universityID: string,
      providerID: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      locationOptionOnSite?: boolean | null,
      locationOptionVisit?: boolean | null,
      shortLocation?: string | null,
      telephone?: string | null,
      email?: string | null,
      links?: Array< string | null > | null,
      status?: BusinessStatus | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      categories?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      provider?:  {
        __typename: "Provider",
        id: string,
        userID: string,
        universityID: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        schoolMajor?: string | null,
        schoolYear?: string | null,
        schoolOrganizations?: Array< string | null > | null,
        links?: Array< string | null > | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: AccountStatus | null,
        invitationalEmail?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateServiceMutationVariables = {
  input?: UpdateServiceInput,
  condition?: ModelServiceConditionInput | null,
};

export type UpdateServiceMutation = {
  updateService?:  {
    __typename: "Service",
    id: string,
    owner?: string | null,
    businessID: string,
    name: string,
    description: string,
    duration: number,
    price: number,
    active: boolean,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    business?:  {
      __typename: "Business",
      id: string,
      universityID: string,
      providerID: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      locationOptionOnSite?: boolean | null,
      locationOptionVisit?: boolean | null,
      shortLocation?: string | null,
      telephone?: string | null,
      email?: string | null,
      links?: Array< string | null > | null,
      status?: BusinessStatus | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      categories?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      provider?:  {
        __typename: "Provider",
        id: string,
        userID: string,
        universityID: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        schoolMajor?: string | null,
        schoolYear?: string | null,
        schoolOrganizations?: Array< string | null > | null,
        links?: Array< string | null > | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: AccountStatus | null,
        invitationalEmail?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteServiceMutationVariables = {
  input?: DeleteServiceInput,
  condition?: ModelServiceConditionInput | null,
};

export type DeleteServiceMutation = {
  deleteService?:  {
    __typename: "Service",
    id: string,
    owner?: string | null,
    businessID: string,
    name: string,
    description: string,
    duration: number,
    price: number,
    active: boolean,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    business?:  {
      __typename: "Business",
      id: string,
      universityID: string,
      providerID: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      locationOptionOnSite?: boolean | null,
      locationOptionVisit?: boolean | null,
      shortLocation?: string | null,
      telephone?: string | null,
      email?: string | null,
      links?: Array< string | null > | null,
      status?: BusinessStatus | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      categories?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      provider?:  {
        __typename: "Provider",
        id: string,
        userID: string,
        universityID: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        schoolMajor?: string | null,
        schoolYear?: string | null,
        schoolOrganizations?: Array< string | null > | null,
        links?: Array< string | null > | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: AccountStatus | null,
        invitationalEmail?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type CreateCategoryMutationVariables = {
  input?: CreateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type CreateCategoryMutation = {
  createCategory?:  {
    __typename: "Category",
    id: string,
    owner?: string | null,
    title: string,
    description: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCategoryMutationVariables = {
  input?: UpdateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type UpdateCategoryMutation = {
  updateCategory?:  {
    __typename: "Category",
    id: string,
    owner?: string | null,
    title: string,
    description: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCategoryMutationVariables = {
  input?: DeleteCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type DeleteCategoryMutation = {
  deleteCategory?:  {
    __typename: "Category",
    id: string,
    owner?: string | null,
    title: string,
    description: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBookingMutationVariables = {
  input?: CreateBookingInput,
  condition?: ModelBookingConditionInput | null,
};

export type CreateBookingMutation = {
  createBooking?:  {
    __typename: "Booking",
    id: string,
    owner?: string | null,
    businessID: string,
    customerID: string,
    serviceID: string,
    price?: number | null,
    status?: BookingStatus | null,
    createdAt?: string | null,
    chargeID?: string | null,
    note?: string | null,
    locationName?: string | null,
    date: string,
    time: string,
    duration: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null,
    business?:  {
      __typename: "Business",
      id: string,
      universityID: string,
      providerID: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      locationOptionOnSite?: boolean | null,
      locationOptionVisit?: boolean | null,
      shortLocation?: string | null,
      telephone?: string | null,
      email?: string | null,
      links?: Array< string | null > | null,
      status?: BusinessStatus | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      categories?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      provider?:  {
        __typename: "Provider",
        id: string,
        userID: string,
        universityID: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        schoolMajor?: string | null,
        schoolYear?: string | null,
        schoolOrganizations?: Array< string | null > | null,
        links?: Array< string | null > | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: AccountStatus | null,
        invitationalEmail?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null,
    service?:  {
      __typename: "Service",
      id: string,
      owner?: string | null,
      businessID: string,
      name: string,
      description: string,
      duration: number,
      price: number,
      active: boolean,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        universityID: string,
        providerID: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        locationOptionOnSite?: boolean | null,
        locationOptionVisit?: boolean | null,
        shortLocation?: string | null,
        telephone?: string | null,
        email?: string | null,
        links?: Array< string | null > | null,
        status?: BusinessStatus | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        categories?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        provider?:  {
          __typename: "Provider",
          id: string,
          userID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          schoolMajor?: string | null,
          schoolYear?: string | null,
          schoolOrganizations?: Array< string | null > | null,
          links?: Array< string | null > | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateBookingMutationVariables = {
  input?: UpdateBookingInput,
  condition?: ModelBookingConditionInput | null,
};

export type UpdateBookingMutation = {
  updateBooking?:  {
    __typename: "Booking",
    id: string,
    owner?: string | null,
    businessID: string,
    customerID: string,
    serviceID: string,
    price?: number | null,
    status?: BookingStatus | null,
    createdAt?: string | null,
    chargeID?: string | null,
    note?: string | null,
    locationName?: string | null,
    date: string,
    time: string,
    duration: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null,
    business?:  {
      __typename: "Business",
      id: string,
      universityID: string,
      providerID: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      locationOptionOnSite?: boolean | null,
      locationOptionVisit?: boolean | null,
      shortLocation?: string | null,
      telephone?: string | null,
      email?: string | null,
      links?: Array< string | null > | null,
      status?: BusinessStatus | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      categories?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      provider?:  {
        __typename: "Provider",
        id: string,
        userID: string,
        universityID: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        schoolMajor?: string | null,
        schoolYear?: string | null,
        schoolOrganizations?: Array< string | null > | null,
        links?: Array< string | null > | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: AccountStatus | null,
        invitationalEmail?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null,
    service?:  {
      __typename: "Service",
      id: string,
      owner?: string | null,
      businessID: string,
      name: string,
      description: string,
      duration: number,
      price: number,
      active: boolean,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        universityID: string,
        providerID: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        locationOptionOnSite?: boolean | null,
        locationOptionVisit?: boolean | null,
        shortLocation?: string | null,
        telephone?: string | null,
        email?: string | null,
        links?: Array< string | null > | null,
        status?: BusinessStatus | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        categories?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        provider?:  {
          __typename: "Provider",
          id: string,
          userID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          schoolMajor?: string | null,
          schoolYear?: string | null,
          schoolOrganizations?: Array< string | null > | null,
          links?: Array< string | null > | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteBookingMutationVariables = {
  input?: DeleteBookingInput,
  condition?: ModelBookingConditionInput | null,
};

export type DeleteBookingMutation = {
  deleteBooking?:  {
    __typename: "Booking",
    id: string,
    owner?: string | null,
    businessID: string,
    customerID: string,
    serviceID: string,
    price?: number | null,
    status?: BookingStatus | null,
    createdAt?: string | null,
    chargeID?: string | null,
    note?: string | null,
    locationName?: string | null,
    date: string,
    time: string,
    duration: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null,
    business?:  {
      __typename: "Business",
      id: string,
      universityID: string,
      providerID: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      locationOptionOnSite?: boolean | null,
      locationOptionVisit?: boolean | null,
      shortLocation?: string | null,
      telephone?: string | null,
      email?: string | null,
      links?: Array< string | null > | null,
      status?: BusinessStatus | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      categories?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      provider?:  {
        __typename: "Provider",
        id: string,
        userID: string,
        universityID: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        schoolMajor?: string | null,
        schoolYear?: string | null,
        schoolOrganizations?: Array< string | null > | null,
        links?: Array< string | null > | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: AccountStatus | null,
        invitationalEmail?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null,
    service?:  {
      __typename: "Service",
      id: string,
      owner?: string | null,
      businessID: string,
      name: string,
      description: string,
      duration: number,
      price: number,
      active: boolean,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        universityID: string,
        providerID: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        locationOptionOnSite?: boolean | null,
        locationOptionVisit?: boolean | null,
        shortLocation?: string | null,
        telephone?: string | null,
        email?: string | null,
        links?: Array< string | null > | null,
        status?: BusinessStatus | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        categories?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        provider?:  {
          __typename: "Provider",
          id: string,
          userID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          schoolMajor?: string | null,
          schoolYear?: string | null,
          schoolOrganizations?: Array< string | null > | null,
          links?: Array< string | null > | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type GetCustomerQueryVariables = {
  id?: string,
};

export type GetCustomerQuery = {
  getCustomer?:  {
    __typename: "Customer",
    id: string,
    userID: string,
    universityID: string,
    studentID?: string | null,
    cartID: string,
    cbordPaymentToken?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    readGroups?: Array< string | null > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    cart?:  {
      __typename: "Cart",
      id: string,
      owner?: string | null,
      content?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
    } | null,
  } | null,
};

export type ListCustomersQueryVariables = {
  filter?: ModelCustomerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCustomersQuery = {
  listCustomers?:  {
    __typename: "ModelCustomerConnection",
    items?:  Array< {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CustomerByUserQueryVariables = {
  userID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCustomerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomerByUserQuery = {
  customerByUser?:  {
    __typename: "ModelCustomerConnection",
    items?:  Array< {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CustomerByUniversityQueryVariables = {
  universityID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCustomerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomerByUniversityQuery = {
  customerByUniversity?:  {
    __typename: "ModelCustomerConnection",
    items?:  Array< {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CustomerByStudentIdQueryVariables = {
  studentID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCustomerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomerByStudentIdQuery = {
  customerByStudentID?:  {
    __typename: "ModelCustomerConnection",
    items?:  Array< {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCustomersQueryVariables = {
  filter?: ModelCustomerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCustomersQuery = {
  syncCustomers?:  {
    __typename: "ModelCustomerConnection",
    items?:  Array< {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDelivererQueryVariables = {
  id?: string,
};

export type GetDelivererQuery = {
  getDeliverer?:  {
    __typename: "Deliverer",
    id: string,
    userID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    active?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    orders?:  {
      __typename: "ModelOrderConnection",
      items?:  Array< {
        __typename: "Order",
        id: string,
        owner?: string | null,
        customerID: string,
        venueID: string,
        eventID?: string | null,
        delivererID?: string | null,
        price?: number | null,
        totalPrice?: number | null,
        status?: OrderStatus | null,
        notes?: string | null,
        createdAt?: string | null,
        timeSlot?: string | null,
        timeSlotID?: string | null,
        paymentMethod?: string | null,
        chargeID?: string | null,
        serviceOption?: TimeSlotType | null,
        locationName?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        updatedAt: string,
        customer?:  {
          __typename: "Customer",
          id: string,
          userID: string,
          universityID: string,
          studentID?: string | null,
          cartID: string,
          cbordPaymentToken?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          readGroups?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          cart?:  {
            __typename: "Cart",
            id: string,
            owner?: string | null,
            content?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
          } | null,
        } | null,
        history?:  {
          __typename: "ModelOrderStateConnection",
          items?:  Array< {
            __typename: "OrderState",
            id: string,
            owner?: string | null,
            orderID: string,
            status?: OrderStatus | null,
            createdAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orderItems?:  {
          __typename: "ModelOrderItemConnection",
          items?:  Array< {
            __typename: "OrderItem",
            id: string,
            owner?: string | null,
            orderID: string,
            foodID: string,
            price?: number | null,
            amount: number,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venue?:  {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    university?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type ListDeliverersQueryVariables = {
  filter?: ModelDelivererFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDeliverersQuery = {
  listDeliverers?:  {
    __typename: "ModelDelivererConnection",
    items?:  Array< {
      __typename: "Deliverer",
      id: string,
      userID: string,
      universityID: string,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: AccountStatus | null,
      invitationalEmail?: string | null,
      active?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      orders?:  {
        __typename: "ModelOrderConnection",
        items?:  Array< {
          __typename: "Order",
          id: string,
          owner?: string | null,
          customerID: string,
          venueID: string,
          eventID?: string | null,
          delivererID?: string | null,
          price?: number | null,
          totalPrice?: number | null,
          status?: OrderStatus | null,
          notes?: string | null,
          createdAt?: string | null,
          timeSlot?: string | null,
          timeSlotID?: string | null,
          paymentMethod?: string | null,
          chargeID?: string | null,
          serviceOption?: TimeSlotType | null,
          locationName?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          updatedAt: string,
          customer?:  {
            __typename: "Customer",
            id: string,
            userID: string,
            universityID: string,
            studentID?: string | null,
            cartID: string,
            cbordPaymentToken?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            image_url?: string | null,
            readGroups?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          history?:  {
            __typename: "ModelOrderStateConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          orderItems?:  {
            __typename: "ModelOrderItemConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type DelivererByUserQueryVariables = {
  userID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDelivererFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DelivererByUserQuery = {
  delivererByUser?:  {
    __typename: "ModelDelivererConnection",
    items?:  Array< {
      __typename: "Deliverer",
      id: string,
      userID: string,
      universityID: string,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: AccountStatus | null,
      invitationalEmail?: string | null,
      active?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      orders?:  {
        __typename: "ModelOrderConnection",
        items?:  Array< {
          __typename: "Order",
          id: string,
          owner?: string | null,
          customerID: string,
          venueID: string,
          eventID?: string | null,
          delivererID?: string | null,
          price?: number | null,
          totalPrice?: number | null,
          status?: OrderStatus | null,
          notes?: string | null,
          createdAt?: string | null,
          timeSlot?: string | null,
          timeSlotID?: string | null,
          paymentMethod?: string | null,
          chargeID?: string | null,
          serviceOption?: TimeSlotType | null,
          locationName?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          updatedAt: string,
          customer?:  {
            __typename: "Customer",
            id: string,
            userID: string,
            universityID: string,
            studentID?: string | null,
            cartID: string,
            cbordPaymentToken?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            image_url?: string | null,
            readGroups?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          history?:  {
            __typename: "ModelOrderStateConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          orderItems?:  {
            __typename: "ModelOrderItemConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type DeliverersByUniversityQueryVariables = {
  universityID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDelivererFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DeliverersByUniversityQuery = {
  deliverersByUniversity?:  {
    __typename: "ModelDelivererConnection",
    items?:  Array< {
      __typename: "Deliverer",
      id: string,
      userID: string,
      universityID: string,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: AccountStatus | null,
      invitationalEmail?: string | null,
      active?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      orders?:  {
        __typename: "ModelOrderConnection",
        items?:  Array< {
          __typename: "Order",
          id: string,
          owner?: string | null,
          customerID: string,
          venueID: string,
          eventID?: string | null,
          delivererID?: string | null,
          price?: number | null,
          totalPrice?: number | null,
          status?: OrderStatus | null,
          notes?: string | null,
          createdAt?: string | null,
          timeSlot?: string | null,
          timeSlotID?: string | null,
          paymentMethod?: string | null,
          chargeID?: string | null,
          serviceOption?: TimeSlotType | null,
          locationName?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          updatedAt: string,
          customer?:  {
            __typename: "Customer",
            id: string,
            userID: string,
            universityID: string,
            studentID?: string | null,
            cartID: string,
            cbordPaymentToken?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            image_url?: string | null,
            readGroups?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          history?:  {
            __typename: "ModelOrderStateConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          orderItems?:  {
            __typename: "ModelOrderItemConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDeliverersQueryVariables = {
  filter?: ModelDelivererFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDeliverersQuery = {
  syncDeliverers?:  {
    __typename: "ModelDelivererConnection",
    items?:  Array< {
      __typename: "Deliverer",
      id: string,
      userID: string,
      universityID: string,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: AccountStatus | null,
      invitationalEmail?: string | null,
      active?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      orders?:  {
        __typename: "ModelOrderConnection",
        items?:  Array< {
          __typename: "Order",
          id: string,
          owner?: string | null,
          customerID: string,
          venueID: string,
          eventID?: string | null,
          delivererID?: string | null,
          price?: number | null,
          totalPrice?: number | null,
          status?: OrderStatus | null,
          notes?: string | null,
          createdAt?: string | null,
          timeSlot?: string | null,
          timeSlotID?: string | null,
          paymentMethod?: string | null,
          chargeID?: string | null,
          serviceOption?: TimeSlotType | null,
          locationName?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          updatedAt: string,
          customer?:  {
            __typename: "Customer",
            id: string,
            userID: string,
            universityID: string,
            studentID?: string | null,
            cartID: string,
            cbordPaymentToken?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            image_url?: string | null,
            readGroups?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          history?:  {
            __typename: "ModelOrderStateConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          orderItems?:  {
            __typename: "ModelOrderItemConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDirectorQueryVariables = {
  id?: string,
};

export type GetDirectorQuery = {
  getDirector?:  {
    __typename: "Director",
    id: string,
    userID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    university?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type ListDirectorsQueryVariables = {
  filter?: ModelDirectorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDirectorsQuery = {
  listDirectors?:  {
    __typename: "ModelDirectorConnection",
    items?:  Array< {
      __typename: "Director",
      id: string,
      userID: string,
      universityID: string,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: AccountStatus | null,
      invitationalEmail?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type DirectorsByUserQueryVariables = {
  userID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDirectorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DirectorsByUserQuery = {
  directorsByUser?:  {
    __typename: "ModelDirectorConnection",
    items?:  Array< {
      __typename: "Director",
      id: string,
      userID: string,
      universityID: string,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: AccountStatus | null,
      invitationalEmail?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type DirectorsByUniversityQueryVariables = {
  universityID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDirectorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DirectorsByUniversityQuery = {
  directorsByUniversity?:  {
    __typename: "ModelDirectorConnection",
    items?:  Array< {
      __typename: "Director",
      id: string,
      userID: string,
      universityID: string,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: AccountStatus | null,
      invitationalEmail?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDirectorsQueryVariables = {
  filter?: ModelDirectorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDirectorsQuery = {
  syncDirectors?:  {
    __typename: "ModelDirectorConnection",
    items?:  Array< {
      __typename: "Director",
      id: string,
      userID: string,
      universityID: string,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: AccountStatus | null,
      invitationalEmail?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetProviderQueryVariables = {
  id?: string,
};

export type GetProviderQuery = {
  getProvider?:  {
    __typename: "Provider",
    id: string,
    userID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    schoolMajor?: string | null,
    schoolYear?: string | null,
    schoolOrganizations?: Array< string | null > | null,
    links?: Array< string | null > | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    university?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    businesses?:  {
      __typename: "ModelBusinessConnection",
      items?:  Array< {
        __typename: "Business",
        id: string,
        universityID: string,
        providerID: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        locationOptionOnSite?: boolean | null,
        locationOptionVisit?: boolean | null,
        shortLocation?: string | null,
        telephone?: string | null,
        email?: string | null,
        links?: Array< string | null > | null,
        status?: BusinessStatus | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        categories?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        provider?:  {
          __typename: "Provider",
          id: string,
          userID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          schoolMajor?: string | null,
          schoolYear?: string | null,
          schoolOrganizations?: Array< string | null > | null,
          links?: Array< string | null > | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type ListProvidersQueryVariables = {
  filter?: ModelProviderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProvidersQuery = {
  listProviders?:  {
    __typename: "ModelProviderConnection",
    items?:  Array< {
      __typename: "Provider",
      id: string,
      userID: string,
      universityID: string,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      schoolMajor?: string | null,
      schoolYear?: string | null,
      schoolOrganizations?: Array< string | null > | null,
      links?: Array< string | null > | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: AccountStatus | null,
      invitationalEmail?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ProvidersByUserQueryVariables = {
  userID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProviderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProvidersByUserQuery = {
  providersByUser?:  {
    __typename: "ModelProviderConnection",
    items?:  Array< {
      __typename: "Provider",
      id: string,
      userID: string,
      universityID: string,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      schoolMajor?: string | null,
      schoolYear?: string | null,
      schoolOrganizations?: Array< string | null > | null,
      links?: Array< string | null > | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: AccountStatus | null,
      invitationalEmail?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ProvidersByUniversityQueryVariables = {
  universityID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProviderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProvidersByUniversityQuery = {
  providersByUniversity?:  {
    __typename: "ModelProviderConnection",
    items?:  Array< {
      __typename: "Provider",
      id: string,
      userID: string,
      universityID: string,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      schoolMajor?: string | null,
      schoolYear?: string | null,
      schoolOrganizations?: Array< string | null > | null,
      links?: Array< string | null > | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: AccountStatus | null,
      invitationalEmail?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncProvidersQueryVariables = {
  filter?: ModelProviderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncProvidersQuery = {
  syncProviders?:  {
    __typename: "ModelProviderConnection",
    items?:  Array< {
      __typename: "Provider",
      id: string,
      userID: string,
      universityID: string,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      schoolMajor?: string | null,
      schoolYear?: string | null,
      schoolOrganizations?: Array< string | null > | null,
      links?: Array< string | null > | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: AccountStatus | null,
      invitationalEmail?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetVendorQueryVariables = {
  id?: string,
};

export type GetVendorQuery = {
  getVendor?:  {
    __typename: "Vendor",
    id: string,
    userID: string,
    venueID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    parentUniversity?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    parentVenue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type ListVendorsQueryVariables = {
  filter?: ModelVendorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVendorsQuery = {
  listVendors?:  {
    __typename: "ModelVendorConnection",
    items?:  Array< {
      __typename: "Vendor",
      id: string,
      userID: string,
      venueID: string,
      universityID: string,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: AccountStatus | null,
      invitationalEmail?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      parentUniversity?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      parentVenue?:  {
        __typename: "Venue",
        id: string,
        universityID: string,
        owner?: string | null,
        name?: string | null,
        businessName?: string | null,
        description?: string | null,
        status?: VendorStatus | null,
        type?: EventType | null,
        categoty?: VenueCategory | null,
        buffetPrice?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        payInApp?: boolean | null,
        payInPerson?: boolean | null,
        payWithCard?: boolean | null,
        payWithCash?: boolean | null,
        payWithStudentID?: boolean | null,
        payWithCurrency?: boolean | null,
        currency?: string | null,
        dineInAvailable?: boolean | null,
        reservationAvailable?: boolean | null,
        pickUpAvailable?: boolean | null,
        deliveryAvailable?: boolean | null,
        timeSlotsDineInCapacity?: number | null,
        timeSlotsPickUpCapacity?: number | null,
        timeSlotsDeliveryCapacity?: number | null,
        timeslotsIgnore?: boolean | null,
        timeslotsInterval?: number | null,
        timeSlotsStatus?: TimeSlotsStatus | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        vendors?:  {
          __typename: "ModelVendorConnection",
          items?:  Array< {
            __typename: "Vendor",
            id: string,
            userID: string,
            venueID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        reports?:  {
          __typename: "ModelReportConnection",
          items?:  Array< {
            __typename: "Report",
            id: string,
            owner?: string | null,
            venueID: string,
            reportUri?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type VendorsByUserQueryVariables = {
  userID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVendorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VendorsByUserQuery = {
  vendorsByUser?:  {
    __typename: "ModelVendorConnection",
    items?:  Array< {
      __typename: "Vendor",
      id: string,
      userID: string,
      venueID: string,
      universityID: string,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: AccountStatus | null,
      invitationalEmail?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      parentUniversity?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      parentVenue?:  {
        __typename: "Venue",
        id: string,
        universityID: string,
        owner?: string | null,
        name?: string | null,
        businessName?: string | null,
        description?: string | null,
        status?: VendorStatus | null,
        type?: EventType | null,
        categoty?: VenueCategory | null,
        buffetPrice?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        payInApp?: boolean | null,
        payInPerson?: boolean | null,
        payWithCard?: boolean | null,
        payWithCash?: boolean | null,
        payWithStudentID?: boolean | null,
        payWithCurrency?: boolean | null,
        currency?: string | null,
        dineInAvailable?: boolean | null,
        reservationAvailable?: boolean | null,
        pickUpAvailable?: boolean | null,
        deliveryAvailable?: boolean | null,
        timeSlotsDineInCapacity?: number | null,
        timeSlotsPickUpCapacity?: number | null,
        timeSlotsDeliveryCapacity?: number | null,
        timeslotsIgnore?: boolean | null,
        timeslotsInterval?: number | null,
        timeSlotsStatus?: TimeSlotsStatus | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        vendors?:  {
          __typename: "ModelVendorConnection",
          items?:  Array< {
            __typename: "Vendor",
            id: string,
            userID: string,
            venueID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        reports?:  {
          __typename: "ModelReportConnection",
          items?:  Array< {
            __typename: "Report",
            id: string,
            owner?: string | null,
            venueID: string,
            reportUri?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type VendorsByVenueQueryVariables = {
  venueID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVendorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VendorsByVenueQuery = {
  vendorsByVenue?:  {
    __typename: "ModelVendorConnection",
    items?:  Array< {
      __typename: "Vendor",
      id: string,
      userID: string,
      venueID: string,
      universityID: string,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: AccountStatus | null,
      invitationalEmail?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      parentUniversity?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      parentVenue?:  {
        __typename: "Venue",
        id: string,
        universityID: string,
        owner?: string | null,
        name?: string | null,
        businessName?: string | null,
        description?: string | null,
        status?: VendorStatus | null,
        type?: EventType | null,
        categoty?: VenueCategory | null,
        buffetPrice?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        payInApp?: boolean | null,
        payInPerson?: boolean | null,
        payWithCard?: boolean | null,
        payWithCash?: boolean | null,
        payWithStudentID?: boolean | null,
        payWithCurrency?: boolean | null,
        currency?: string | null,
        dineInAvailable?: boolean | null,
        reservationAvailable?: boolean | null,
        pickUpAvailable?: boolean | null,
        deliveryAvailable?: boolean | null,
        timeSlotsDineInCapacity?: number | null,
        timeSlotsPickUpCapacity?: number | null,
        timeSlotsDeliveryCapacity?: number | null,
        timeslotsIgnore?: boolean | null,
        timeslotsInterval?: number | null,
        timeSlotsStatus?: TimeSlotsStatus | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        vendors?:  {
          __typename: "ModelVendorConnection",
          items?:  Array< {
            __typename: "Vendor",
            id: string,
            userID: string,
            venueID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        reports?:  {
          __typename: "ModelReportConnection",
          items?:  Array< {
            __typename: "Report",
            id: string,
            owner?: string | null,
            venueID: string,
            reportUri?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type VendorsByUniversityQueryVariables = {
  universityID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVendorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VendorsByUniversityQuery = {
  vendorsByUniversity?:  {
    __typename: "ModelVendorConnection",
    items?:  Array< {
      __typename: "Vendor",
      id: string,
      userID: string,
      venueID: string,
      universityID: string,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: AccountStatus | null,
      invitationalEmail?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      parentUniversity?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      parentVenue?:  {
        __typename: "Venue",
        id: string,
        universityID: string,
        owner?: string | null,
        name?: string | null,
        businessName?: string | null,
        description?: string | null,
        status?: VendorStatus | null,
        type?: EventType | null,
        categoty?: VenueCategory | null,
        buffetPrice?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        payInApp?: boolean | null,
        payInPerson?: boolean | null,
        payWithCard?: boolean | null,
        payWithCash?: boolean | null,
        payWithStudentID?: boolean | null,
        payWithCurrency?: boolean | null,
        currency?: string | null,
        dineInAvailable?: boolean | null,
        reservationAvailable?: boolean | null,
        pickUpAvailable?: boolean | null,
        deliveryAvailable?: boolean | null,
        timeSlotsDineInCapacity?: number | null,
        timeSlotsPickUpCapacity?: number | null,
        timeSlotsDeliveryCapacity?: number | null,
        timeslotsIgnore?: boolean | null,
        timeslotsInterval?: number | null,
        timeSlotsStatus?: TimeSlotsStatus | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        vendors?:  {
          __typename: "ModelVendorConnection",
          items?:  Array< {
            __typename: "Vendor",
            id: string,
            userID: string,
            venueID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        reports?:  {
          __typename: "ModelReportConnection",
          items?:  Array< {
            __typename: "Report",
            id: string,
            owner?: string | null,
            venueID: string,
            reportUri?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncVendorsQueryVariables = {
  filter?: ModelVendorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncVendorsQuery = {
  syncVendors?:  {
    __typename: "ModelVendorConnection",
    items?:  Array< {
      __typename: "Vendor",
      id: string,
      userID: string,
      venueID: string,
      universityID: string,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: AccountStatus | null,
      invitationalEmail?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      parentUniversity?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      parentVenue?:  {
        __typename: "Venue",
        id: string,
        universityID: string,
        owner?: string | null,
        name?: string | null,
        businessName?: string | null,
        description?: string | null,
        status?: VendorStatus | null,
        type?: EventType | null,
        categoty?: VenueCategory | null,
        buffetPrice?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        payInApp?: boolean | null,
        payInPerson?: boolean | null,
        payWithCard?: boolean | null,
        payWithCash?: boolean | null,
        payWithStudentID?: boolean | null,
        payWithCurrency?: boolean | null,
        currency?: string | null,
        dineInAvailable?: boolean | null,
        reservationAvailable?: boolean | null,
        pickUpAvailable?: boolean | null,
        deliveryAvailable?: boolean | null,
        timeSlotsDineInCapacity?: number | null,
        timeSlotsPickUpCapacity?: number | null,
        timeSlotsDeliveryCapacity?: number | null,
        timeslotsIgnore?: boolean | null,
        timeslotsInterval?: number | null,
        timeSlotsStatus?: TimeSlotsStatus | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        vendors?:  {
          __typename: "ModelVendorConnection",
          items?:  Array< {
            __typename: "Vendor",
            id: string,
            userID: string,
            venueID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        reports?:  {
          __typename: "ModelReportConnection",
          items?:  Array< {
            __typename: "Report",
            id: string,
            owner?: string | null,
            venueID: string,
            reportUri?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetStaffQueryVariables = {
  id?: string,
};

export type GetStaffQuery = {
  getStaff?:  {
    __typename: "Staff",
    id: string,
    userID: string,
    venueID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    parentUniversity?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    parentVenue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type ListStaffsQueryVariables = {
  filter?: ModelStaffFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStaffsQuery = {
  listStaffs?:  {
    __typename: "ModelStaffConnection",
    items?:  Array< {
      __typename: "Staff",
      id: string,
      userID: string,
      venueID: string,
      universityID: string,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: AccountStatus | null,
      invitationalEmail?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      parentUniversity?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      parentVenue?:  {
        __typename: "Venue",
        id: string,
        universityID: string,
        owner?: string | null,
        name?: string | null,
        businessName?: string | null,
        description?: string | null,
        status?: VendorStatus | null,
        type?: EventType | null,
        categoty?: VenueCategory | null,
        buffetPrice?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        payInApp?: boolean | null,
        payInPerson?: boolean | null,
        payWithCard?: boolean | null,
        payWithCash?: boolean | null,
        payWithStudentID?: boolean | null,
        payWithCurrency?: boolean | null,
        currency?: string | null,
        dineInAvailable?: boolean | null,
        reservationAvailable?: boolean | null,
        pickUpAvailable?: boolean | null,
        deliveryAvailable?: boolean | null,
        timeSlotsDineInCapacity?: number | null,
        timeSlotsPickUpCapacity?: number | null,
        timeSlotsDeliveryCapacity?: number | null,
        timeslotsIgnore?: boolean | null,
        timeslotsInterval?: number | null,
        timeSlotsStatus?: TimeSlotsStatus | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        vendors?:  {
          __typename: "ModelVendorConnection",
          items?:  Array< {
            __typename: "Vendor",
            id: string,
            userID: string,
            venueID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        reports?:  {
          __typename: "ModelReportConnection",
          items?:  Array< {
            __typename: "Report",
            id: string,
            owner?: string | null,
            venueID: string,
            reportUri?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type StaffByUserQueryVariables = {
  userID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStaffFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type StaffByUserQuery = {
  staffByUser?:  {
    __typename: "ModelStaffConnection",
    items?:  Array< {
      __typename: "Staff",
      id: string,
      userID: string,
      venueID: string,
      universityID: string,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: AccountStatus | null,
      invitationalEmail?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      parentUniversity?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      parentVenue?:  {
        __typename: "Venue",
        id: string,
        universityID: string,
        owner?: string | null,
        name?: string | null,
        businessName?: string | null,
        description?: string | null,
        status?: VendorStatus | null,
        type?: EventType | null,
        categoty?: VenueCategory | null,
        buffetPrice?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        payInApp?: boolean | null,
        payInPerson?: boolean | null,
        payWithCard?: boolean | null,
        payWithCash?: boolean | null,
        payWithStudentID?: boolean | null,
        payWithCurrency?: boolean | null,
        currency?: string | null,
        dineInAvailable?: boolean | null,
        reservationAvailable?: boolean | null,
        pickUpAvailable?: boolean | null,
        deliveryAvailable?: boolean | null,
        timeSlotsDineInCapacity?: number | null,
        timeSlotsPickUpCapacity?: number | null,
        timeSlotsDeliveryCapacity?: number | null,
        timeslotsIgnore?: boolean | null,
        timeslotsInterval?: number | null,
        timeSlotsStatus?: TimeSlotsStatus | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        vendors?:  {
          __typename: "ModelVendorConnection",
          items?:  Array< {
            __typename: "Vendor",
            id: string,
            userID: string,
            venueID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        reports?:  {
          __typename: "ModelReportConnection",
          items?:  Array< {
            __typename: "Report",
            id: string,
            owner?: string | null,
            venueID: string,
            reportUri?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type StaffByVenueQueryVariables = {
  venueID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStaffFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type StaffByVenueQuery = {
  staffByVenue?:  {
    __typename: "ModelStaffConnection",
    items?:  Array< {
      __typename: "Staff",
      id: string,
      userID: string,
      venueID: string,
      universityID: string,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: AccountStatus | null,
      invitationalEmail?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      parentUniversity?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      parentVenue?:  {
        __typename: "Venue",
        id: string,
        universityID: string,
        owner?: string | null,
        name?: string | null,
        businessName?: string | null,
        description?: string | null,
        status?: VendorStatus | null,
        type?: EventType | null,
        categoty?: VenueCategory | null,
        buffetPrice?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        payInApp?: boolean | null,
        payInPerson?: boolean | null,
        payWithCard?: boolean | null,
        payWithCash?: boolean | null,
        payWithStudentID?: boolean | null,
        payWithCurrency?: boolean | null,
        currency?: string | null,
        dineInAvailable?: boolean | null,
        reservationAvailable?: boolean | null,
        pickUpAvailable?: boolean | null,
        deliveryAvailable?: boolean | null,
        timeSlotsDineInCapacity?: number | null,
        timeSlotsPickUpCapacity?: number | null,
        timeSlotsDeliveryCapacity?: number | null,
        timeslotsIgnore?: boolean | null,
        timeslotsInterval?: number | null,
        timeSlotsStatus?: TimeSlotsStatus | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        vendors?:  {
          __typename: "ModelVendorConnection",
          items?:  Array< {
            __typename: "Vendor",
            id: string,
            userID: string,
            venueID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        reports?:  {
          __typename: "ModelReportConnection",
          items?:  Array< {
            __typename: "Report",
            id: string,
            owner?: string | null,
            venueID: string,
            reportUri?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type StaffByUniversityQueryVariables = {
  universityID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStaffFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type StaffByUniversityQuery = {
  staffByUniversity?:  {
    __typename: "ModelStaffConnection",
    items?:  Array< {
      __typename: "Staff",
      id: string,
      userID: string,
      venueID: string,
      universityID: string,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: AccountStatus | null,
      invitationalEmail?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      parentUniversity?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      parentVenue?:  {
        __typename: "Venue",
        id: string,
        universityID: string,
        owner?: string | null,
        name?: string | null,
        businessName?: string | null,
        description?: string | null,
        status?: VendorStatus | null,
        type?: EventType | null,
        categoty?: VenueCategory | null,
        buffetPrice?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        payInApp?: boolean | null,
        payInPerson?: boolean | null,
        payWithCard?: boolean | null,
        payWithCash?: boolean | null,
        payWithStudentID?: boolean | null,
        payWithCurrency?: boolean | null,
        currency?: string | null,
        dineInAvailable?: boolean | null,
        reservationAvailable?: boolean | null,
        pickUpAvailable?: boolean | null,
        deliveryAvailable?: boolean | null,
        timeSlotsDineInCapacity?: number | null,
        timeSlotsPickUpCapacity?: number | null,
        timeSlotsDeliveryCapacity?: number | null,
        timeslotsIgnore?: boolean | null,
        timeslotsInterval?: number | null,
        timeSlotsStatus?: TimeSlotsStatus | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        vendors?:  {
          __typename: "ModelVendorConnection",
          items?:  Array< {
            __typename: "Vendor",
            id: string,
            userID: string,
            venueID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        reports?:  {
          __typename: "ModelReportConnection",
          items?:  Array< {
            __typename: "Report",
            id: string,
            owner?: string | null,
            venueID: string,
            reportUri?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncStaffQueryVariables = {
  filter?: ModelStaffFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncStaffQuery = {
  syncStaff?:  {
    __typename: "ModelStaffConnection",
    items?:  Array< {
      __typename: "Staff",
      id: string,
      userID: string,
      venueID: string,
      universityID: string,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: AccountStatus | null,
      invitationalEmail?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      parentUniversity?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      parentVenue?:  {
        __typename: "Venue",
        id: string,
        universityID: string,
        owner?: string | null,
        name?: string | null,
        businessName?: string | null,
        description?: string | null,
        status?: VendorStatus | null,
        type?: EventType | null,
        categoty?: VenueCategory | null,
        buffetPrice?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        payInApp?: boolean | null,
        payInPerson?: boolean | null,
        payWithCard?: boolean | null,
        payWithCash?: boolean | null,
        payWithStudentID?: boolean | null,
        payWithCurrency?: boolean | null,
        currency?: string | null,
        dineInAvailable?: boolean | null,
        reservationAvailable?: boolean | null,
        pickUpAvailable?: boolean | null,
        deliveryAvailable?: boolean | null,
        timeSlotsDineInCapacity?: number | null,
        timeSlotsPickUpCapacity?: number | null,
        timeSlotsDeliveryCapacity?: number | null,
        timeslotsIgnore?: boolean | null,
        timeslotsInterval?: number | null,
        timeSlotsStatus?: TimeSlotsStatus | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        vendors?:  {
          __typename: "ModelVendorConnection",
          items?:  Array< {
            __typename: "Vendor",
            id: string,
            userID: string,
            venueID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        reports?:  {
          __typename: "ModelReportConnection",
          items?:  Array< {
            __typename: "Report",
            id: string,
            owner?: string | null,
            venueID: string,
            reportUri?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCartQueryVariables = {
  id?: string,
};

export type GetCartQuery = {
  getCart?:  {
    __typename: "Cart",
    id: string,
    owner?: string | null,
    content?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
  } | null,
};

export type ListCartsQueryVariables = {
  filter?: ModelCartFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCartsQuery = {
  listCarts?:  {
    __typename: "ModelCartConnection",
    items?:  Array< {
      __typename: "Cart",
      id: string,
      owner?: string | null,
      content?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCartsQueryVariables = {
  filter?: ModelCartFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCartsQuery = {
  syncCarts?:  {
    __typename: "ModelCartConnection",
    items?:  Array< {
      __typename: "Cart",
      id: string,
      owner?: string | null,
      content?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetReservationQueryVariables = {
  id?: string,
};

export type GetReservationQuery = {
  getReservation?:  {
    __typename: "Reservation",
    id: string,
    owner?: string | null,
    customerID: string,
    venueID: string,
    eventID: string,
    status?: ReservationStatus | null,
    createdAt?: string | null,
    timeSlot?: string | null,
    timeSlotID?: string | null,
    serviceOption?: TimeSlotType | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null,
    venue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type ListReservationsQueryVariables = {
  filter?: ModelReservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReservationsQuery = {
  listReservations?:  {
    __typename: "ModelReservationConnection",
    items?:  Array< {
      __typename: "Reservation",
      id: string,
      owner?: string | null,
      customerID: string,
      venueID: string,
      eventID: string,
      status?: ReservationStatus | null,
      createdAt?: string | null,
      timeSlot?: string | null,
      timeSlotID?: string | null,
      serviceOption?: TimeSlotType | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      updatedAt: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        userID: string,
        universityID: string,
        studentID?: string | null,
        cartID: string,
        cbordPaymentToken?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        readGroups?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        cart?:  {
          __typename: "Cart",
          id: string,
          owner?: string | null,
          content?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
        } | null,
      } | null,
      venue?:  {
        __typename: "Venue",
        id: string,
        universityID: string,
        owner?: string | null,
        name?: string | null,
        businessName?: string | null,
        description?: string | null,
        status?: VendorStatus | null,
        type?: EventType | null,
        categoty?: VenueCategory | null,
        buffetPrice?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        payInApp?: boolean | null,
        payInPerson?: boolean | null,
        payWithCard?: boolean | null,
        payWithCash?: boolean | null,
        payWithStudentID?: boolean | null,
        payWithCurrency?: boolean | null,
        currency?: string | null,
        dineInAvailable?: boolean | null,
        reservationAvailable?: boolean | null,
        pickUpAvailable?: boolean | null,
        deliveryAvailable?: boolean | null,
        timeSlotsDineInCapacity?: number | null,
        timeSlotsPickUpCapacity?: number | null,
        timeSlotsDeliveryCapacity?: number | null,
        timeslotsIgnore?: boolean | null,
        timeslotsInterval?: number | null,
        timeSlotsStatus?: TimeSlotsStatus | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        vendors?:  {
          __typename: "ModelVendorConnection",
          items?:  Array< {
            __typename: "Vendor",
            id: string,
            userID: string,
            venueID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        reports?:  {
          __typename: "ModelReportConnection",
          items?:  Array< {
            __typename: "Report",
            id: string,
            owner?: string | null,
            venueID: string,
            reportUri?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ReservationsByVenueQueryVariables = {
  venueID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReservationsByVenueQuery = {
  reservationsByVenue?:  {
    __typename: "ModelReservationConnection",
    items?:  Array< {
      __typename: "Reservation",
      id: string,
      owner?: string | null,
      customerID: string,
      venueID: string,
      eventID: string,
      status?: ReservationStatus | null,
      createdAt?: string | null,
      timeSlot?: string | null,
      timeSlotID?: string | null,
      serviceOption?: TimeSlotType | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      updatedAt: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        userID: string,
        universityID: string,
        studentID?: string | null,
        cartID: string,
        cbordPaymentToken?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        readGroups?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        cart?:  {
          __typename: "Cart",
          id: string,
          owner?: string | null,
          content?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
        } | null,
      } | null,
      venue?:  {
        __typename: "Venue",
        id: string,
        universityID: string,
        owner?: string | null,
        name?: string | null,
        businessName?: string | null,
        description?: string | null,
        status?: VendorStatus | null,
        type?: EventType | null,
        categoty?: VenueCategory | null,
        buffetPrice?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        payInApp?: boolean | null,
        payInPerson?: boolean | null,
        payWithCard?: boolean | null,
        payWithCash?: boolean | null,
        payWithStudentID?: boolean | null,
        payWithCurrency?: boolean | null,
        currency?: string | null,
        dineInAvailable?: boolean | null,
        reservationAvailable?: boolean | null,
        pickUpAvailable?: boolean | null,
        deliveryAvailable?: boolean | null,
        timeSlotsDineInCapacity?: number | null,
        timeSlotsPickUpCapacity?: number | null,
        timeSlotsDeliveryCapacity?: number | null,
        timeslotsIgnore?: boolean | null,
        timeslotsInterval?: number | null,
        timeSlotsStatus?: TimeSlotsStatus | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        vendors?:  {
          __typename: "ModelVendorConnection",
          items?:  Array< {
            __typename: "Vendor",
            id: string,
            userID: string,
            venueID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        reports?:  {
          __typename: "ModelReportConnection",
          items?:  Array< {
            __typename: "Report",
            id: string,
            owner?: string | null,
            venueID: string,
            reportUri?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ReservationsByCustomerQueryVariables = {
  customerID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReservationsByCustomerQuery = {
  reservationsByCustomer?:  {
    __typename: "ModelReservationConnection",
    items?:  Array< {
      __typename: "Reservation",
      id: string,
      owner?: string | null,
      customerID: string,
      venueID: string,
      eventID: string,
      status?: ReservationStatus | null,
      createdAt?: string | null,
      timeSlot?: string | null,
      timeSlotID?: string | null,
      serviceOption?: TimeSlotType | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      updatedAt: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        userID: string,
        universityID: string,
        studentID?: string | null,
        cartID: string,
        cbordPaymentToken?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        readGroups?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        cart?:  {
          __typename: "Cart",
          id: string,
          owner?: string | null,
          content?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
        } | null,
      } | null,
      venue?:  {
        __typename: "Venue",
        id: string,
        universityID: string,
        owner?: string | null,
        name?: string | null,
        businessName?: string | null,
        description?: string | null,
        status?: VendorStatus | null,
        type?: EventType | null,
        categoty?: VenueCategory | null,
        buffetPrice?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        payInApp?: boolean | null,
        payInPerson?: boolean | null,
        payWithCard?: boolean | null,
        payWithCash?: boolean | null,
        payWithStudentID?: boolean | null,
        payWithCurrency?: boolean | null,
        currency?: string | null,
        dineInAvailable?: boolean | null,
        reservationAvailable?: boolean | null,
        pickUpAvailable?: boolean | null,
        deliveryAvailable?: boolean | null,
        timeSlotsDineInCapacity?: number | null,
        timeSlotsPickUpCapacity?: number | null,
        timeSlotsDeliveryCapacity?: number | null,
        timeslotsIgnore?: boolean | null,
        timeslotsInterval?: number | null,
        timeSlotsStatus?: TimeSlotsStatus | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        vendors?:  {
          __typename: "ModelVendorConnection",
          items?:  Array< {
            __typename: "Vendor",
            id: string,
            userID: string,
            venueID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        reports?:  {
          __typename: "ModelReportConnection",
          items?:  Array< {
            __typename: "Report",
            id: string,
            owner?: string | null,
            venueID: string,
            reportUri?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ReservationsByEventQueryVariables = {
  eventID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReservationsByEventQuery = {
  reservationsByEvent?:  {
    __typename: "ModelReservationConnection",
    items?:  Array< {
      __typename: "Reservation",
      id: string,
      owner?: string | null,
      customerID: string,
      venueID: string,
      eventID: string,
      status?: ReservationStatus | null,
      createdAt?: string | null,
      timeSlot?: string | null,
      timeSlotID?: string | null,
      serviceOption?: TimeSlotType | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      updatedAt: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        userID: string,
        universityID: string,
        studentID?: string | null,
        cartID: string,
        cbordPaymentToken?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        readGroups?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        cart?:  {
          __typename: "Cart",
          id: string,
          owner?: string | null,
          content?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
        } | null,
      } | null,
      venue?:  {
        __typename: "Venue",
        id: string,
        universityID: string,
        owner?: string | null,
        name?: string | null,
        businessName?: string | null,
        description?: string | null,
        status?: VendorStatus | null,
        type?: EventType | null,
        categoty?: VenueCategory | null,
        buffetPrice?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        payInApp?: boolean | null,
        payInPerson?: boolean | null,
        payWithCard?: boolean | null,
        payWithCash?: boolean | null,
        payWithStudentID?: boolean | null,
        payWithCurrency?: boolean | null,
        currency?: string | null,
        dineInAvailable?: boolean | null,
        reservationAvailable?: boolean | null,
        pickUpAvailable?: boolean | null,
        deliveryAvailable?: boolean | null,
        timeSlotsDineInCapacity?: number | null,
        timeSlotsPickUpCapacity?: number | null,
        timeSlotsDeliveryCapacity?: number | null,
        timeslotsIgnore?: boolean | null,
        timeslotsInterval?: number | null,
        timeSlotsStatus?: TimeSlotsStatus | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        vendors?:  {
          __typename: "ModelVendorConnection",
          items?:  Array< {
            __typename: "Vendor",
            id: string,
            userID: string,
            venueID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        reports?:  {
          __typename: "ModelReportConnection",
          items?:  Array< {
            __typename: "Report",
            id: string,
            owner?: string | null,
            venueID: string,
            reportUri?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncReservationsQueryVariables = {
  filter?: ModelReservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncReservationsQuery = {
  syncReservations?:  {
    __typename: "ModelReservationConnection",
    items?:  Array< {
      __typename: "Reservation",
      id: string,
      owner?: string | null,
      customerID: string,
      venueID: string,
      eventID: string,
      status?: ReservationStatus | null,
      createdAt?: string | null,
      timeSlot?: string | null,
      timeSlotID?: string | null,
      serviceOption?: TimeSlotType | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      updatedAt: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        userID: string,
        universityID: string,
        studentID?: string | null,
        cartID: string,
        cbordPaymentToken?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        readGroups?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        cart?:  {
          __typename: "Cart",
          id: string,
          owner?: string | null,
          content?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
        } | null,
      } | null,
      venue?:  {
        __typename: "Venue",
        id: string,
        universityID: string,
        owner?: string | null,
        name?: string | null,
        businessName?: string | null,
        description?: string | null,
        status?: VendorStatus | null,
        type?: EventType | null,
        categoty?: VenueCategory | null,
        buffetPrice?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        payInApp?: boolean | null,
        payInPerson?: boolean | null,
        payWithCard?: boolean | null,
        payWithCash?: boolean | null,
        payWithStudentID?: boolean | null,
        payWithCurrency?: boolean | null,
        currency?: string | null,
        dineInAvailable?: boolean | null,
        reservationAvailable?: boolean | null,
        pickUpAvailable?: boolean | null,
        deliveryAvailable?: boolean | null,
        timeSlotsDineInCapacity?: number | null,
        timeSlotsPickUpCapacity?: number | null,
        timeSlotsDeliveryCapacity?: number | null,
        timeslotsIgnore?: boolean | null,
        timeslotsInterval?: number | null,
        timeSlotsStatus?: TimeSlotsStatus | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        vendors?:  {
          __typename: "ModelVendorConnection",
          items?:  Array< {
            __typename: "Vendor",
            id: string,
            userID: string,
            venueID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        reports?:  {
          __typename: "ModelReportConnection",
          items?:  Array< {
            __typename: "Report",
            id: string,
            owner?: string | null,
            venueID: string,
            reportUri?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetOrderStateQueryVariables = {
  id?: string,
};

export type GetOrderStateQuery = {
  getOrderState?:  {
    __typename: "OrderState",
    id: string,
    owner?: string | null,
    orderID: string,
    status?: OrderStatus | null,
    createdAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
  } | null,
};

export type ListOrderStatesQueryVariables = {
  filter?: ModelOrderStateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderStatesQuery = {
  listOrderStates?:  {
    __typename: "ModelOrderStateConnection",
    items?:  Array< {
      __typename: "OrderState",
      id: string,
      owner?: string | null,
      orderID: string,
      status?: OrderStatus | null,
      createdAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type StatesByOrderQueryVariables = {
  orderID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderStateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type StatesByOrderQuery = {
  statesByOrder?:  {
    __typename: "ModelOrderStateConnection",
    items?:  Array< {
      __typename: "OrderState",
      id: string,
      owner?: string | null,
      orderID: string,
      status?: OrderStatus | null,
      createdAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncOrderStatesQueryVariables = {
  filter?: ModelOrderStateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncOrderStatesQuery = {
  syncOrderStates?:  {
    __typename: "ModelOrderStateConnection",
    items?:  Array< {
      __typename: "OrderState",
      id: string,
      owner?: string | null,
      orderID: string,
      status?: OrderStatus | null,
      createdAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetOrderQueryVariables = {
  id?: string,
};

export type GetOrderQuery = {
  getOrder?:  {
    __typename: "Order",
    id: string,
    owner?: string | null,
    customerID: string,
    venueID: string,
    eventID?: string | null,
    delivererID?: string | null,
    price?: number | null,
    totalPrice?: number | null,
    status?: OrderStatus | null,
    notes?: string | null,
    createdAt?: string | null,
    timeSlot?: string | null,
    timeSlotID?: string | null,
    paymentMethod?: string | null,
    chargeID?: string | null,
    serviceOption?: TimeSlotType | null,
    locationName?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null,
    history?:  {
      __typename: "ModelOrderStateConnection",
      items?:  Array< {
        __typename: "OrderState",
        id: string,
        owner?: string | null,
        orderID: string,
        status?: OrderStatus | null,
        createdAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    orderItems?:  {
      __typename: "ModelOrderItemConnection",
      items?:  Array< {
        __typename: "OrderItem",
        id: string,
        owner?: string | null,
        orderID: string,
        foodID: string,
        price?: number | null,
        amount: number,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        food?:  {
          __typename: "Food",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          active?: boolean | null,
          calories?: number | null,
          foodType?: FoodType | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    venue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type ListOrdersQueryVariables = {
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrdersQuery = {
  listOrders?:  {
    __typename: "ModelOrderConnection",
    items?:  Array< {
      __typename: "Order",
      id: string,
      owner?: string | null,
      customerID: string,
      venueID: string,
      eventID?: string | null,
      delivererID?: string | null,
      price?: number | null,
      totalPrice?: number | null,
      status?: OrderStatus | null,
      notes?: string | null,
      createdAt?: string | null,
      timeSlot?: string | null,
      timeSlotID?: string | null,
      paymentMethod?: string | null,
      chargeID?: string | null,
      serviceOption?: TimeSlotType | null,
      locationName?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      updatedAt: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        userID: string,
        universityID: string,
        studentID?: string | null,
        cartID: string,
        cbordPaymentToken?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        readGroups?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        cart?:  {
          __typename: "Cart",
          id: string,
          owner?: string | null,
          content?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
        } | null,
      } | null,
      history?:  {
        __typename: "ModelOrderStateConnection",
        items?:  Array< {
          __typename: "OrderState",
          id: string,
          owner?: string | null,
          orderID: string,
          status?: OrderStatus | null,
          createdAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orderItems?:  {
        __typename: "ModelOrderItemConnection",
        items?:  Array< {
          __typename: "OrderItem",
          id: string,
          owner?: string | null,
          orderID: string,
          foodID: string,
          price?: number | null,
          amount: number,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          food?:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venue?:  {
        __typename: "Venue",
        id: string,
        universityID: string,
        owner?: string | null,
        name?: string | null,
        businessName?: string | null,
        description?: string | null,
        status?: VendorStatus | null,
        type?: EventType | null,
        categoty?: VenueCategory | null,
        buffetPrice?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        payInApp?: boolean | null,
        payInPerson?: boolean | null,
        payWithCard?: boolean | null,
        payWithCash?: boolean | null,
        payWithStudentID?: boolean | null,
        payWithCurrency?: boolean | null,
        currency?: string | null,
        dineInAvailable?: boolean | null,
        reservationAvailable?: boolean | null,
        pickUpAvailable?: boolean | null,
        deliveryAvailable?: boolean | null,
        timeSlotsDineInCapacity?: number | null,
        timeSlotsPickUpCapacity?: number | null,
        timeSlotsDeliveryCapacity?: number | null,
        timeslotsIgnore?: boolean | null,
        timeslotsInterval?: number | null,
        timeSlotsStatus?: TimeSlotsStatus | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        vendors?:  {
          __typename: "ModelVendorConnection",
          items?:  Array< {
            __typename: "Vendor",
            id: string,
            userID: string,
            venueID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        reports?:  {
          __typename: "ModelReportConnection",
          items?:  Array< {
            __typename: "Report",
            id: string,
            owner?: string | null,
            venueID: string,
            reportUri?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OrdersByVenueQueryVariables = {
  venueID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrdersByVenueQuery = {
  ordersByVenue?:  {
    __typename: "ModelOrderConnection",
    items?:  Array< {
      __typename: "Order",
      id: string,
      owner?: string | null,
      customerID: string,
      venueID: string,
      eventID?: string | null,
      delivererID?: string | null,
      price?: number | null,
      totalPrice?: number | null,
      status?: OrderStatus | null,
      notes?: string | null,
      createdAt?: string | null,
      timeSlot?: string | null,
      timeSlotID?: string | null,
      paymentMethod?: string | null,
      chargeID?: string | null,
      serviceOption?: TimeSlotType | null,
      locationName?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      updatedAt: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        userID: string,
        universityID: string,
        studentID?: string | null,
        cartID: string,
        cbordPaymentToken?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        readGroups?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        cart?:  {
          __typename: "Cart",
          id: string,
          owner?: string | null,
          content?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
        } | null,
      } | null,
      history?:  {
        __typename: "ModelOrderStateConnection",
        items?:  Array< {
          __typename: "OrderState",
          id: string,
          owner?: string | null,
          orderID: string,
          status?: OrderStatus | null,
          createdAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orderItems?:  {
        __typename: "ModelOrderItemConnection",
        items?:  Array< {
          __typename: "OrderItem",
          id: string,
          owner?: string | null,
          orderID: string,
          foodID: string,
          price?: number | null,
          amount: number,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          food?:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venue?:  {
        __typename: "Venue",
        id: string,
        universityID: string,
        owner?: string | null,
        name?: string | null,
        businessName?: string | null,
        description?: string | null,
        status?: VendorStatus | null,
        type?: EventType | null,
        categoty?: VenueCategory | null,
        buffetPrice?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        payInApp?: boolean | null,
        payInPerson?: boolean | null,
        payWithCard?: boolean | null,
        payWithCash?: boolean | null,
        payWithStudentID?: boolean | null,
        payWithCurrency?: boolean | null,
        currency?: string | null,
        dineInAvailable?: boolean | null,
        reservationAvailable?: boolean | null,
        pickUpAvailable?: boolean | null,
        deliveryAvailable?: boolean | null,
        timeSlotsDineInCapacity?: number | null,
        timeSlotsPickUpCapacity?: number | null,
        timeSlotsDeliveryCapacity?: number | null,
        timeslotsIgnore?: boolean | null,
        timeslotsInterval?: number | null,
        timeSlotsStatus?: TimeSlotsStatus | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        vendors?:  {
          __typename: "ModelVendorConnection",
          items?:  Array< {
            __typename: "Vendor",
            id: string,
            userID: string,
            venueID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        reports?:  {
          __typename: "ModelReportConnection",
          items?:  Array< {
            __typename: "Report",
            id: string,
            owner?: string | null,
            venueID: string,
            reportUri?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OrdersByCustomerQueryVariables = {
  customerID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrdersByCustomerQuery = {
  ordersByCustomer?:  {
    __typename: "ModelOrderConnection",
    items?:  Array< {
      __typename: "Order",
      id: string,
      owner?: string | null,
      customerID: string,
      venueID: string,
      eventID?: string | null,
      delivererID?: string | null,
      price?: number | null,
      totalPrice?: number | null,
      status?: OrderStatus | null,
      notes?: string | null,
      createdAt?: string | null,
      timeSlot?: string | null,
      timeSlotID?: string | null,
      paymentMethod?: string | null,
      chargeID?: string | null,
      serviceOption?: TimeSlotType | null,
      locationName?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      updatedAt: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        userID: string,
        universityID: string,
        studentID?: string | null,
        cartID: string,
        cbordPaymentToken?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        readGroups?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        cart?:  {
          __typename: "Cart",
          id: string,
          owner?: string | null,
          content?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
        } | null,
      } | null,
      history?:  {
        __typename: "ModelOrderStateConnection",
        items?:  Array< {
          __typename: "OrderState",
          id: string,
          owner?: string | null,
          orderID: string,
          status?: OrderStatus | null,
          createdAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orderItems?:  {
        __typename: "ModelOrderItemConnection",
        items?:  Array< {
          __typename: "OrderItem",
          id: string,
          owner?: string | null,
          orderID: string,
          foodID: string,
          price?: number | null,
          amount: number,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          food?:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venue?:  {
        __typename: "Venue",
        id: string,
        universityID: string,
        owner?: string | null,
        name?: string | null,
        businessName?: string | null,
        description?: string | null,
        status?: VendorStatus | null,
        type?: EventType | null,
        categoty?: VenueCategory | null,
        buffetPrice?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        payInApp?: boolean | null,
        payInPerson?: boolean | null,
        payWithCard?: boolean | null,
        payWithCash?: boolean | null,
        payWithStudentID?: boolean | null,
        payWithCurrency?: boolean | null,
        currency?: string | null,
        dineInAvailable?: boolean | null,
        reservationAvailable?: boolean | null,
        pickUpAvailable?: boolean | null,
        deliveryAvailable?: boolean | null,
        timeSlotsDineInCapacity?: number | null,
        timeSlotsPickUpCapacity?: number | null,
        timeSlotsDeliveryCapacity?: number | null,
        timeslotsIgnore?: boolean | null,
        timeslotsInterval?: number | null,
        timeSlotsStatus?: TimeSlotsStatus | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        vendors?:  {
          __typename: "ModelVendorConnection",
          items?:  Array< {
            __typename: "Vendor",
            id: string,
            userID: string,
            venueID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        reports?:  {
          __typename: "ModelReportConnection",
          items?:  Array< {
            __typename: "Report",
            id: string,
            owner?: string | null,
            venueID: string,
            reportUri?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OrdersByDelivererQueryVariables = {
  delivererID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrdersByDelivererQuery = {
  ordersByDeliverer?:  {
    __typename: "ModelOrderConnection",
    items?:  Array< {
      __typename: "Order",
      id: string,
      owner?: string | null,
      customerID: string,
      venueID: string,
      eventID?: string | null,
      delivererID?: string | null,
      price?: number | null,
      totalPrice?: number | null,
      status?: OrderStatus | null,
      notes?: string | null,
      createdAt?: string | null,
      timeSlot?: string | null,
      timeSlotID?: string | null,
      paymentMethod?: string | null,
      chargeID?: string | null,
      serviceOption?: TimeSlotType | null,
      locationName?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      updatedAt: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        userID: string,
        universityID: string,
        studentID?: string | null,
        cartID: string,
        cbordPaymentToken?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        readGroups?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        cart?:  {
          __typename: "Cart",
          id: string,
          owner?: string | null,
          content?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
        } | null,
      } | null,
      history?:  {
        __typename: "ModelOrderStateConnection",
        items?:  Array< {
          __typename: "OrderState",
          id: string,
          owner?: string | null,
          orderID: string,
          status?: OrderStatus | null,
          createdAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orderItems?:  {
        __typename: "ModelOrderItemConnection",
        items?:  Array< {
          __typename: "OrderItem",
          id: string,
          owner?: string | null,
          orderID: string,
          foodID: string,
          price?: number | null,
          amount: number,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          food?:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venue?:  {
        __typename: "Venue",
        id: string,
        universityID: string,
        owner?: string | null,
        name?: string | null,
        businessName?: string | null,
        description?: string | null,
        status?: VendorStatus | null,
        type?: EventType | null,
        categoty?: VenueCategory | null,
        buffetPrice?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        payInApp?: boolean | null,
        payInPerson?: boolean | null,
        payWithCard?: boolean | null,
        payWithCash?: boolean | null,
        payWithStudentID?: boolean | null,
        payWithCurrency?: boolean | null,
        currency?: string | null,
        dineInAvailable?: boolean | null,
        reservationAvailable?: boolean | null,
        pickUpAvailable?: boolean | null,
        deliveryAvailable?: boolean | null,
        timeSlotsDineInCapacity?: number | null,
        timeSlotsPickUpCapacity?: number | null,
        timeSlotsDeliveryCapacity?: number | null,
        timeslotsIgnore?: boolean | null,
        timeslotsInterval?: number | null,
        timeSlotsStatus?: TimeSlotsStatus | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        vendors?:  {
          __typename: "ModelVendorConnection",
          items?:  Array< {
            __typename: "Vendor",
            id: string,
            userID: string,
            venueID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        reports?:  {
          __typename: "ModelReportConnection",
          items?:  Array< {
            __typename: "Report",
            id: string,
            owner?: string | null,
            venueID: string,
            reportUri?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OrdersByEventQueryVariables = {
  eventID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrdersByEventQuery = {
  ordersByEvent?:  {
    __typename: "ModelOrderConnection",
    items?:  Array< {
      __typename: "Order",
      id: string,
      owner?: string | null,
      customerID: string,
      venueID: string,
      eventID?: string | null,
      delivererID?: string | null,
      price?: number | null,
      totalPrice?: number | null,
      status?: OrderStatus | null,
      notes?: string | null,
      createdAt?: string | null,
      timeSlot?: string | null,
      timeSlotID?: string | null,
      paymentMethod?: string | null,
      chargeID?: string | null,
      serviceOption?: TimeSlotType | null,
      locationName?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      updatedAt: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        userID: string,
        universityID: string,
        studentID?: string | null,
        cartID: string,
        cbordPaymentToken?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        readGroups?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        cart?:  {
          __typename: "Cart",
          id: string,
          owner?: string | null,
          content?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
        } | null,
      } | null,
      history?:  {
        __typename: "ModelOrderStateConnection",
        items?:  Array< {
          __typename: "OrderState",
          id: string,
          owner?: string | null,
          orderID: string,
          status?: OrderStatus | null,
          createdAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orderItems?:  {
        __typename: "ModelOrderItemConnection",
        items?:  Array< {
          __typename: "OrderItem",
          id: string,
          owner?: string | null,
          orderID: string,
          foodID: string,
          price?: number | null,
          amount: number,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          food?:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venue?:  {
        __typename: "Venue",
        id: string,
        universityID: string,
        owner?: string | null,
        name?: string | null,
        businessName?: string | null,
        description?: string | null,
        status?: VendorStatus | null,
        type?: EventType | null,
        categoty?: VenueCategory | null,
        buffetPrice?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        payInApp?: boolean | null,
        payInPerson?: boolean | null,
        payWithCard?: boolean | null,
        payWithCash?: boolean | null,
        payWithStudentID?: boolean | null,
        payWithCurrency?: boolean | null,
        currency?: string | null,
        dineInAvailable?: boolean | null,
        reservationAvailable?: boolean | null,
        pickUpAvailable?: boolean | null,
        deliveryAvailable?: boolean | null,
        timeSlotsDineInCapacity?: number | null,
        timeSlotsPickUpCapacity?: number | null,
        timeSlotsDeliveryCapacity?: number | null,
        timeslotsIgnore?: boolean | null,
        timeslotsInterval?: number | null,
        timeSlotsStatus?: TimeSlotsStatus | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        vendors?:  {
          __typename: "ModelVendorConnection",
          items?:  Array< {
            __typename: "Vendor",
            id: string,
            userID: string,
            venueID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        reports?:  {
          __typename: "ModelReportConnection",
          items?:  Array< {
            __typename: "Report",
            id: string,
            owner?: string | null,
            venueID: string,
            reportUri?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncOrdersQueryVariables = {
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncOrdersQuery = {
  syncOrders?:  {
    __typename: "ModelOrderConnection",
    items?:  Array< {
      __typename: "Order",
      id: string,
      owner?: string | null,
      customerID: string,
      venueID: string,
      eventID?: string | null,
      delivererID?: string | null,
      price?: number | null,
      totalPrice?: number | null,
      status?: OrderStatus | null,
      notes?: string | null,
      createdAt?: string | null,
      timeSlot?: string | null,
      timeSlotID?: string | null,
      paymentMethod?: string | null,
      chargeID?: string | null,
      serviceOption?: TimeSlotType | null,
      locationName?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      updatedAt: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        userID: string,
        universityID: string,
        studentID?: string | null,
        cartID: string,
        cbordPaymentToken?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        readGroups?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        cart?:  {
          __typename: "Cart",
          id: string,
          owner?: string | null,
          content?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
        } | null,
      } | null,
      history?:  {
        __typename: "ModelOrderStateConnection",
        items?:  Array< {
          __typename: "OrderState",
          id: string,
          owner?: string | null,
          orderID: string,
          status?: OrderStatus | null,
          createdAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orderItems?:  {
        __typename: "ModelOrderItemConnection",
        items?:  Array< {
          __typename: "OrderItem",
          id: string,
          owner?: string | null,
          orderID: string,
          foodID: string,
          price?: number | null,
          amount: number,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          food?:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venue?:  {
        __typename: "Venue",
        id: string,
        universityID: string,
        owner?: string | null,
        name?: string | null,
        businessName?: string | null,
        description?: string | null,
        status?: VendorStatus | null,
        type?: EventType | null,
        categoty?: VenueCategory | null,
        buffetPrice?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        payInApp?: boolean | null,
        payInPerson?: boolean | null,
        payWithCard?: boolean | null,
        payWithCash?: boolean | null,
        payWithStudentID?: boolean | null,
        payWithCurrency?: boolean | null,
        currency?: string | null,
        dineInAvailable?: boolean | null,
        reservationAvailable?: boolean | null,
        pickUpAvailable?: boolean | null,
        deliveryAvailable?: boolean | null,
        timeSlotsDineInCapacity?: number | null,
        timeSlotsPickUpCapacity?: number | null,
        timeSlotsDeliveryCapacity?: number | null,
        timeslotsIgnore?: boolean | null,
        timeslotsInterval?: number | null,
        timeSlotsStatus?: TimeSlotsStatus | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        vendors?:  {
          __typename: "ModelVendorConnection",
          items?:  Array< {
            __typename: "Vendor",
            id: string,
            userID: string,
            venueID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        reports?:  {
          __typename: "ModelReportConnection",
          items?:  Array< {
            __typename: "Report",
            id: string,
            owner?: string | null,
            venueID: string,
            reportUri?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetOrderItemQueryVariables = {
  id?: string,
};

export type GetOrderItemQuery = {
  getOrderItem?:  {
    __typename: "OrderItem",
    id: string,
    owner?: string | null,
    orderID: string,
    foodID: string,
    price?: number | null,
    amount: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    food?:  {
      __typename: "Food",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      price?: number | null,
      active?: boolean | null,
      calories?: number | null,
      foodType?: FoodType | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      menus?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type ListOrderItemsQueryVariables = {
  filter?: ModelOrderItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderItemsQuery = {
  listOrderItems?:  {
    __typename: "ModelOrderItemConnection",
    items?:  Array< {
      __typename: "OrderItem",
      id: string,
      owner?: string | null,
      orderID: string,
      foodID: string,
      price?: number | null,
      amount: number,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      food?:  {
        __typename: "Food",
        id: string,
        owner?: string | null,
        venueID: string,
        name?: string | null,
        description?: string | null,
        price?: number | null,
        active?: boolean | null,
        calories?: number | null,
        foodType?: FoodType | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        menus?:  {
          __typename: "ModelMenuFoodConnection",
          items?:  Array< {
            __typename: "MenuFood",
            id: string,
            owner?: string | null,
            menuID: string,
            foodID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OrderItemsByOrderQueryVariables = {
  orderID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrderItemsByOrderQuery = {
  orderItemsByOrder?:  {
    __typename: "ModelOrderItemConnection",
    items?:  Array< {
      __typename: "OrderItem",
      id: string,
      owner?: string | null,
      orderID: string,
      foodID: string,
      price?: number | null,
      amount: number,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      food?:  {
        __typename: "Food",
        id: string,
        owner?: string | null,
        venueID: string,
        name?: string | null,
        description?: string | null,
        price?: number | null,
        active?: boolean | null,
        calories?: number | null,
        foodType?: FoodType | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        menus?:  {
          __typename: "ModelMenuFoodConnection",
          items?:  Array< {
            __typename: "MenuFood",
            id: string,
            owner?: string | null,
            menuID: string,
            foodID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncOrderItemsQueryVariables = {
  filter?: ModelOrderItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncOrderItemsQuery = {
  syncOrderItems?:  {
    __typename: "ModelOrderItemConnection",
    items?:  Array< {
      __typename: "OrderItem",
      id: string,
      owner?: string | null,
      orderID: string,
      foodID: string,
      price?: number | null,
      amount: number,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      food?:  {
        __typename: "Food",
        id: string,
        owner?: string | null,
        venueID: string,
        name?: string | null,
        description?: string | null,
        price?: number | null,
        active?: boolean | null,
        calories?: number | null,
        foodType?: FoodType | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        menus?:  {
          __typename: "ModelMenuFoodConnection",
          items?:  Array< {
            __typename: "MenuFood",
            id: string,
            owner?: string | null,
            menuID: string,
            foodID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUniversityQueryVariables = {
  id?: string,
};

export type GetUniversityQuery = {
  getUniversity?:  {
    __typename: "University",
    id: string,
    owner?: string | null,
    name?: string | null,
    description?: string | null,
    domain?: string | null,
    cbordUniversity?: string | null,
    cbordEnabled?: boolean | null,
    utcOffset?: number | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    businesses?:  {
      __typename: "ModelBusinessConnection",
      items?:  Array< {
        __typename: "Business",
        id: string,
        universityID: string,
        providerID: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        locationOptionOnSite?: boolean | null,
        locationOptionVisit?: boolean | null,
        shortLocation?: string | null,
        telephone?: string | null,
        email?: string | null,
        links?: Array< string | null > | null,
        status?: BusinessStatus | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        categories?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        provider?:  {
          __typename: "Provider",
          id: string,
          userID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          schoolMajor?: string | null,
          schoolYear?: string | null,
          schoolOrganizations?: Array< string | null > | null,
          links?: Array< string | null > | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    venues?:  {
      __typename: "ModelVenueConnection",
      items?:  Array< {
        __typename: "Venue",
        id: string,
        universityID: string,
        owner?: string | null,
        name?: string | null,
        businessName?: string | null,
        description?: string | null,
        status?: VendorStatus | null,
        type?: EventType | null,
        categoty?: VenueCategory | null,
        buffetPrice?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        payInApp?: boolean | null,
        payInPerson?: boolean | null,
        payWithCard?: boolean | null,
        payWithCash?: boolean | null,
        payWithStudentID?: boolean | null,
        payWithCurrency?: boolean | null,
        currency?: string | null,
        dineInAvailable?: boolean | null,
        reservationAvailable?: boolean | null,
        pickUpAvailable?: boolean | null,
        deliveryAvailable?: boolean | null,
        timeSlotsDineInCapacity?: number | null,
        timeSlotsPickUpCapacity?: number | null,
        timeSlotsDeliveryCapacity?: number | null,
        timeslotsIgnore?: boolean | null,
        timeslotsInterval?: number | null,
        timeSlotsStatus?: TimeSlotsStatus | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        vendors?:  {
          __typename: "ModelVendorConnection",
          items?:  Array< {
            __typename: "Vendor",
            id: string,
            userID: string,
            venueID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        reports?:  {
          __typename: "ModelReportConnection",
          items?:  Array< {
            __typename: "Report",
            id: string,
            owner?: string | null,
            venueID: string,
            reportUri?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type ListUniversitysQueryVariables = {
  filter?: ModelUniversityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUniversitysQuery = {
  listUniversitys?:  {
    __typename: "ModelUniversityConnection",
    items?:  Array< {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUniversitiesQueryVariables = {
  filter?: ModelUniversityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUniversitiesQuery = {
  syncUniversities?:  {
    __typename: "ModelUniversityConnection",
    items?:  Array< {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetBusinessQueryVariables = {
  id?: string,
};

export type GetBusinessQuery = {
  getBusiness?:  {
    __typename: "Business",
    id: string,
    universityID: string,
    providerID: string,
    owner?: string | null,
    name?: string | null,
    description?: string | null,
    locationOptionOnSite?: boolean | null,
    locationOptionVisit?: boolean | null,
    shortLocation?: string | null,
    telephone?: string | null,
    email?: string | null,
    links?: Array< string | null > | null,
    status?: BusinessStatus | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    categories?: Array< string | null > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    provider?:  {
      __typename: "Provider",
      id: string,
      userID: string,
      universityID: string,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      schoolMajor?: string | null,
      schoolYear?: string | null,
      schoolOrganizations?: Array< string | null > | null,
      links?: Array< string | null > | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: AccountStatus | null,
      invitationalEmail?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    university?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type ListBusinesssQueryVariables = {
  filter?: ModelBusinessFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBusinesssQuery = {
  listBusinesss?:  {
    __typename: "ModelBusinessConnection",
    items?:  Array< {
      __typename: "Business",
      id: string,
      universityID: string,
      providerID: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      locationOptionOnSite?: boolean | null,
      locationOptionVisit?: boolean | null,
      shortLocation?: string | null,
      telephone?: string | null,
      email?: string | null,
      links?: Array< string | null > | null,
      status?: BusinessStatus | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      categories?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      provider?:  {
        __typename: "Provider",
        id: string,
        userID: string,
        universityID: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        schoolMajor?: string | null,
        schoolYear?: string | null,
        schoolOrganizations?: Array< string | null > | null,
        links?: Array< string | null > | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: AccountStatus | null,
        invitationalEmail?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type BusinessesByUniversityQueryVariables = {
  universityID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBusinessFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BusinessesByUniversityQuery = {
  businessesByUniversity?:  {
    __typename: "ModelBusinessConnection",
    items?:  Array< {
      __typename: "Business",
      id: string,
      universityID: string,
      providerID: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      locationOptionOnSite?: boolean | null,
      locationOptionVisit?: boolean | null,
      shortLocation?: string | null,
      telephone?: string | null,
      email?: string | null,
      links?: Array< string | null > | null,
      status?: BusinessStatus | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      categories?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      provider?:  {
        __typename: "Provider",
        id: string,
        userID: string,
        universityID: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        schoolMajor?: string | null,
        schoolYear?: string | null,
        schoolOrganizations?: Array< string | null > | null,
        links?: Array< string | null > | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: AccountStatus | null,
        invitationalEmail?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type BusinessesByProviderQueryVariables = {
  providerID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBusinessFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BusinessesByProviderQuery = {
  businessesByProvider?:  {
    __typename: "ModelBusinessConnection",
    items?:  Array< {
      __typename: "Business",
      id: string,
      universityID: string,
      providerID: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      locationOptionOnSite?: boolean | null,
      locationOptionVisit?: boolean | null,
      shortLocation?: string | null,
      telephone?: string | null,
      email?: string | null,
      links?: Array< string | null > | null,
      status?: BusinessStatus | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      categories?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      provider?:  {
        __typename: "Provider",
        id: string,
        userID: string,
        universityID: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        schoolMajor?: string | null,
        schoolYear?: string | null,
        schoolOrganizations?: Array< string | null > | null,
        links?: Array< string | null > | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: AccountStatus | null,
        invitationalEmail?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncBusinessesQueryVariables = {
  filter?: ModelBusinessFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncBusinessesQuery = {
  syncBusinesses?:  {
    __typename: "ModelBusinessConnection",
    items?:  Array< {
      __typename: "Business",
      id: string,
      universityID: string,
      providerID: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      locationOptionOnSite?: boolean | null,
      locationOptionVisit?: boolean | null,
      shortLocation?: string | null,
      telephone?: string | null,
      email?: string | null,
      links?: Array< string | null > | null,
      status?: BusinessStatus | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      categories?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      provider?:  {
        __typename: "Provider",
        id: string,
        userID: string,
        universityID: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        schoolMajor?: string | null,
        schoolYear?: string | null,
        schoolOrganizations?: Array< string | null > | null,
        links?: Array< string | null > | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: AccountStatus | null,
        invitationalEmail?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetVenueQueryVariables = {
  id?: string,
};

export type GetVenueQuery = {
  getVenue?:  {
    __typename: "Venue",
    id: string,
    universityID: string,
    owner?: string | null,
    name?: string | null,
    businessName?: string | null,
    description?: string | null,
    status?: VendorStatus | null,
    type?: EventType | null,
    categoty?: VenueCategory | null,
    buffetPrice?: number | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    payInApp?: boolean | null,
    payInPerson?: boolean | null,
    payWithCard?: boolean | null,
    payWithCash?: boolean | null,
    payWithStudentID?: boolean | null,
    payWithCurrency?: boolean | null,
    currency?: string | null,
    dineInAvailable?: boolean | null,
    reservationAvailable?: boolean | null,
    pickUpAvailable?: boolean | null,
    deliveryAvailable?: boolean | null,
    timeSlotsDineInCapacity?: number | null,
    timeSlotsPickUpCapacity?: number | null,
    timeSlotsDeliveryCapacity?: number | null,
    timeslotsIgnore?: boolean | null,
    timeslotsInterval?: number | null,
    timeSlotsStatus?: TimeSlotsStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    vendors?:  {
      __typename: "ModelVendorConnection",
      items?:  Array< {
        __typename: "Vendor",
        id: string,
        userID: string,
        venueID: string,
        universityID: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: AccountStatus | null,
        invitationalEmail?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        parentUniversity?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        parentVenue?:  {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items?:  Array< {
        __typename: "Event",
        id: string,
        owner?: string | null,
        venueID: string,
        name?: string | null,
        description?: string | null,
        active?: boolean | null,
        recurrenceFrequency?: RecurrenceFrequency | null,
        recurrenceInterval?: number | null,
        recurrenceCount?: number | null,
        recurrentDays?: Array< Weekday | null > | null,
        startDate?: string | null,
        endDate?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        duration?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        exceptions?:  {
          __typename: "ModelEventExceptionConnection",
          items?:  Array< {
            __typename: "EventException",
            id: string,
            owner?: string | null,
            eventID: string,
            name?: string | null,
            description?: string | null,
            type?: EventExceptionType | null,
            date?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        menus?:  {
          __typename: "ModelEventMenuConnection",
          items?:  Array< {
            __typename: "EventMenu",
            id: string,
            owner?: string | null,
            eventID: string,
            menuID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        timeSlots?:  {
          __typename: "ModelTimeSlotConnection",
          items?:  Array< {
            __typename: "TimeSlot",
            id: string,
            owner?: string | null,
            venueID?: string | null,
            eventID?: string | null,
            type?: TimeSlotType | null,
            capacity?: number | null,
            position?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    reports?:  {
      __typename: "ModelReportConnection",
      items?:  Array< {
        __typename: "Report",
        id: string,
        owner?: string | null,
        venueID: string,
        reportUri?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type ListVenuesQueryVariables = {
  filter?: ModelVenueFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVenuesQuery = {
  listVenues?:  {
    __typename: "ModelVenueConnection",
    items?:  Array< {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type VenuesByUniversityQueryVariables = {
  universityID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVenueFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VenuesByUniversityQuery = {
  venuesByUniversity?:  {
    __typename: "ModelVenueConnection",
    items?:  Array< {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncVenuesQueryVariables = {
  filter?: ModelVenueFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncVenuesQuery = {
  syncVenues?:  {
    __typename: "ModelVenueConnection",
    items?:  Array< {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetEventQueryVariables = {
  id?: string,
};

export type GetEventQuery = {
  getEvent?:  {
    __typename: "Event",
    id: string,
    owner?: string | null,
    venueID: string,
    name?: string | null,
    description?: string | null,
    active?: boolean | null,
    recurrenceFrequency?: RecurrenceFrequency | null,
    recurrenceInterval?: number | null,
    recurrenceCount?: number | null,
    recurrentDays?: Array< Weekday | null > | null,
    startDate?: string | null,
    endDate?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    duration?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    exceptions?:  {
      __typename: "ModelEventExceptionConnection",
      items?:  Array< {
        __typename: "EventException",
        id: string,
        owner?: string | null,
        eventID: string,
        name?: string | null,
        description?: string | null,
        type?: EventExceptionType | null,
        date?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        menus?:  {
          __typename: "ModelEventMenuConnection",
          items?:  Array< {
            __typename: "EventMenu",
            id: string,
            owner?: string | null,
            eventID: string,
            menuID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    menus?:  {
      __typename: "ModelEventMenuConnection",
      items?:  Array< {
        __typename: "EventMenu",
        id: string,
        owner?: string | null,
        eventID: string,
        menuID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        event:  {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    timeSlots?:  {
      __typename: "ModelTimeSlotConnection",
      items?:  Array< {
        __typename: "TimeSlot",
        id: string,
        owner?: string | null,
        venueID?: string | null,
        eventID?: string | null,
        type?: TimeSlotType | null,
        capacity?: number | null,
        position?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type ListEventsQueryVariables = {
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventsQuery = {
  listEvents?:  {
    __typename: "ModelEventConnection",
    items?:  Array< {
      __typename: "Event",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      active?: boolean | null,
      recurrenceFrequency?: RecurrenceFrequency | null,
      recurrenceInterval?: number | null,
      recurrenceCount?: number | null,
      recurrentDays?: Array< Weekday | null > | null,
      startDate?: string | null,
      endDate?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      duration?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      exceptions?:  {
        __typename: "ModelEventExceptionConnection",
        items?:  Array< {
          __typename: "EventException",
          id: string,
          owner?: string | null,
          eventID: string,
          name?: string | null,
          description?: string | null,
          type?: EventExceptionType | null,
          date?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      menus?:  {
        __typename: "ModelEventMenuConnection",
        items?:  Array< {
          __typename: "EventMenu",
          id: string,
          owner?: string | null,
          eventID: string,
          menuID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          event:  {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeSlots?:  {
        __typename: "ModelTimeSlotConnection",
        items?:  Array< {
          __typename: "TimeSlot",
          id: string,
          owner?: string | null,
          venueID?: string | null,
          eventID?: string | null,
          type?: TimeSlotType | null,
          capacity?: number | null,
          position?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type EventsByVenueQueryVariables = {
  venueID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventsByVenueQuery = {
  eventsByVenue?:  {
    __typename: "ModelEventConnection",
    items?:  Array< {
      __typename: "Event",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      active?: boolean | null,
      recurrenceFrequency?: RecurrenceFrequency | null,
      recurrenceInterval?: number | null,
      recurrenceCount?: number | null,
      recurrentDays?: Array< Weekday | null > | null,
      startDate?: string | null,
      endDate?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      duration?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      exceptions?:  {
        __typename: "ModelEventExceptionConnection",
        items?:  Array< {
          __typename: "EventException",
          id: string,
          owner?: string | null,
          eventID: string,
          name?: string | null,
          description?: string | null,
          type?: EventExceptionType | null,
          date?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      menus?:  {
        __typename: "ModelEventMenuConnection",
        items?:  Array< {
          __typename: "EventMenu",
          id: string,
          owner?: string | null,
          eventID: string,
          menuID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          event:  {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeSlots?:  {
        __typename: "ModelTimeSlotConnection",
        items?:  Array< {
          __typename: "TimeSlot",
          id: string,
          owner?: string | null,
          venueID?: string | null,
          eventID?: string | null,
          type?: TimeSlotType | null,
          capacity?: number | null,
          position?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncEventsQueryVariables = {
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncEventsQuery = {
  syncEvents?:  {
    __typename: "ModelEventConnection",
    items?:  Array< {
      __typename: "Event",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      active?: boolean | null,
      recurrenceFrequency?: RecurrenceFrequency | null,
      recurrenceInterval?: number | null,
      recurrenceCount?: number | null,
      recurrentDays?: Array< Weekday | null > | null,
      startDate?: string | null,
      endDate?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      duration?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      exceptions?:  {
        __typename: "ModelEventExceptionConnection",
        items?:  Array< {
          __typename: "EventException",
          id: string,
          owner?: string | null,
          eventID: string,
          name?: string | null,
          description?: string | null,
          type?: EventExceptionType | null,
          date?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      menus?:  {
        __typename: "ModelEventMenuConnection",
        items?:  Array< {
          __typename: "EventMenu",
          id: string,
          owner?: string | null,
          eventID: string,
          menuID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          event:  {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeSlots?:  {
        __typename: "ModelTimeSlotConnection",
        items?:  Array< {
          __typename: "TimeSlot",
          id: string,
          owner?: string | null,
          venueID?: string | null,
          eventID?: string | null,
          type?: TimeSlotType | null,
          capacity?: number | null,
          position?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetEventExceptionQueryVariables = {
  id?: string,
};

export type GetEventExceptionQuery = {
  getEventException?:  {
    __typename: "EventException",
    id: string,
    owner?: string | null,
    eventID: string,
    name?: string | null,
    description?: string | null,
    type?: EventExceptionType | null,
    date?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    menus?:  {
      __typename: "ModelEventMenuConnection",
      items?:  Array< {
        __typename: "EventMenu",
        id: string,
        owner?: string | null,
        eventID: string,
        menuID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        event:  {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type ListEventExceptionsQueryVariables = {
  filter?: ModelEventExceptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventExceptionsQuery = {
  listEventExceptions?:  {
    __typename: "ModelEventExceptionConnection",
    items?:  Array< {
      __typename: "EventException",
      id: string,
      owner?: string | null,
      eventID: string,
      name?: string | null,
      description?: string | null,
      type?: EventExceptionType | null,
      date?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      menus?:  {
        __typename: "ModelEventMenuConnection",
        items?:  Array< {
          __typename: "EventMenu",
          id: string,
          owner?: string | null,
          eventID: string,
          menuID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          event:  {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ExceptionsByEventQueryVariables = {
  eventID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventExceptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ExceptionsByEventQuery = {
  exceptionsByEvent?:  {
    __typename: "ModelEventExceptionConnection",
    items?:  Array< {
      __typename: "EventException",
      id: string,
      owner?: string | null,
      eventID: string,
      name?: string | null,
      description?: string | null,
      type?: EventExceptionType | null,
      date?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      menus?:  {
        __typename: "ModelEventMenuConnection",
        items?:  Array< {
          __typename: "EventMenu",
          id: string,
          owner?: string | null,
          eventID: string,
          menuID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          event:  {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncEventExceptionsQueryVariables = {
  filter?: ModelEventExceptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncEventExceptionsQuery = {
  syncEventExceptions?:  {
    __typename: "ModelEventExceptionConnection",
    items?:  Array< {
      __typename: "EventException",
      id: string,
      owner?: string | null,
      eventID: string,
      name?: string | null,
      description?: string | null,
      type?: EventExceptionType | null,
      date?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      menus?:  {
        __typename: "ModelEventMenuConnection",
        items?:  Array< {
          __typename: "EventMenu",
          id: string,
          owner?: string | null,
          eventID: string,
          menuID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          event:  {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetEventMenuQueryVariables = {
  id?: string,
};

export type GetEventMenuQuery = {
  getEventMenu?:  {
    __typename: "EventMenu",
    id: string,
    owner?: string | null,
    eventID: string,
    menuID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    event:  {
      __typename: "Event",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      active?: boolean | null,
      recurrenceFrequency?: RecurrenceFrequency | null,
      recurrenceInterval?: number | null,
      recurrenceCount?: number | null,
      recurrentDays?: Array< Weekday | null > | null,
      startDate?: string | null,
      endDate?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      duration?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      exceptions?:  {
        __typename: "ModelEventExceptionConnection",
        items?:  Array< {
          __typename: "EventException",
          id: string,
          owner?: string | null,
          eventID: string,
          name?: string | null,
          description?: string | null,
          type?: EventExceptionType | null,
          date?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      menus?:  {
        __typename: "ModelEventMenuConnection",
        items?:  Array< {
          __typename: "EventMenu",
          id: string,
          owner?: string | null,
          eventID: string,
          menuID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          event:  {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeSlots?:  {
        __typename: "ModelTimeSlotConnection",
        items?:  Array< {
          __typename: "TimeSlot",
          id: string,
          owner?: string | null,
          venueID?: string | null,
          eventID?: string | null,
          type?: TimeSlotType | null,
          capacity?: number | null,
          position?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
    menu:  {
      __typename: "Menu",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      active?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventMenuConnection",
        items?:  Array< {
          __typename: "EventMenu",
          id: string,
          owner?: string | null,
          eventID: string,
          menuID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          event:  {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      foods?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
  } | null,
};

export type ListEventMenusQueryVariables = {
  filter?: ModelEventMenuFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventMenusQuery = {
  listEventMenus?:  {
    __typename: "ModelEventMenuConnection",
    items?:  Array< {
      __typename: "EventMenu",
      id: string,
      owner?: string | null,
      eventID: string,
      menuID: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      event:  {
        __typename: "Event",
        id: string,
        owner?: string | null,
        venueID: string,
        name?: string | null,
        description?: string | null,
        active?: boolean | null,
        recurrenceFrequency?: RecurrenceFrequency | null,
        recurrenceInterval?: number | null,
        recurrenceCount?: number | null,
        recurrentDays?: Array< Weekday | null > | null,
        startDate?: string | null,
        endDate?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        duration?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        exceptions?:  {
          __typename: "ModelEventExceptionConnection",
          items?:  Array< {
            __typename: "EventException",
            id: string,
            owner?: string | null,
            eventID: string,
            name?: string | null,
            description?: string | null,
            type?: EventExceptionType | null,
            date?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        menus?:  {
          __typename: "ModelEventMenuConnection",
          items?:  Array< {
            __typename: "EventMenu",
            id: string,
            owner?: string | null,
            eventID: string,
            menuID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        timeSlots?:  {
          __typename: "ModelTimeSlotConnection",
          items?:  Array< {
            __typename: "TimeSlot",
            id: string,
            owner?: string | null,
            venueID?: string | null,
            eventID?: string | null,
            type?: TimeSlotType | null,
            capacity?: number | null,
            position?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      },
      menu:  {
        __typename: "Menu",
        id: string,
        owner?: string | null,
        venueID: string,
        name?: string | null,
        description?: string | null,
        active?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        events?:  {
          __typename: "ModelEventMenuConnection",
          items?:  Array< {
            __typename: "EventMenu",
            id: string,
            owner?: string | null,
            eventID: string,
            menuID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        foods?:  {
          __typename: "ModelMenuFoodConnection",
          items?:  Array< {
            __typename: "MenuFood",
            id: string,
            owner?: string | null,
            menuID: string,
            foodID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type EventMenuLinksByEventQueryVariables = {
  eventID?: string | null,
  menuID?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventMenuFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventMenuLinksByEventQuery = {
  eventMenuLinksByEvent?:  {
    __typename: "ModelEventMenuConnection",
    items?:  Array< {
      __typename: "EventMenu",
      id: string,
      owner?: string | null,
      eventID: string,
      menuID: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      event:  {
        __typename: "Event",
        id: string,
        owner?: string | null,
        venueID: string,
        name?: string | null,
        description?: string | null,
        active?: boolean | null,
        recurrenceFrequency?: RecurrenceFrequency | null,
        recurrenceInterval?: number | null,
        recurrenceCount?: number | null,
        recurrentDays?: Array< Weekday | null > | null,
        startDate?: string | null,
        endDate?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        duration?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        exceptions?:  {
          __typename: "ModelEventExceptionConnection",
          items?:  Array< {
            __typename: "EventException",
            id: string,
            owner?: string | null,
            eventID: string,
            name?: string | null,
            description?: string | null,
            type?: EventExceptionType | null,
            date?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        menus?:  {
          __typename: "ModelEventMenuConnection",
          items?:  Array< {
            __typename: "EventMenu",
            id: string,
            owner?: string | null,
            eventID: string,
            menuID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        timeSlots?:  {
          __typename: "ModelTimeSlotConnection",
          items?:  Array< {
            __typename: "TimeSlot",
            id: string,
            owner?: string | null,
            venueID?: string | null,
            eventID?: string | null,
            type?: TimeSlotType | null,
            capacity?: number | null,
            position?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      },
      menu:  {
        __typename: "Menu",
        id: string,
        owner?: string | null,
        venueID: string,
        name?: string | null,
        description?: string | null,
        active?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        events?:  {
          __typename: "ModelEventMenuConnection",
          items?:  Array< {
            __typename: "EventMenu",
            id: string,
            owner?: string | null,
            eventID: string,
            menuID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        foods?:  {
          __typename: "ModelMenuFoodConnection",
          items?:  Array< {
            __typename: "MenuFood",
            id: string,
            owner?: string | null,
            menuID: string,
            foodID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type EventMenuLinksByMenuQueryVariables = {
  menuID?: string | null,
  eventID?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventMenuFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventMenuLinksByMenuQuery = {
  eventMenuLinksByMenu?:  {
    __typename: "ModelEventMenuConnection",
    items?:  Array< {
      __typename: "EventMenu",
      id: string,
      owner?: string | null,
      eventID: string,
      menuID: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      event:  {
        __typename: "Event",
        id: string,
        owner?: string | null,
        venueID: string,
        name?: string | null,
        description?: string | null,
        active?: boolean | null,
        recurrenceFrequency?: RecurrenceFrequency | null,
        recurrenceInterval?: number | null,
        recurrenceCount?: number | null,
        recurrentDays?: Array< Weekday | null > | null,
        startDate?: string | null,
        endDate?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        duration?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        exceptions?:  {
          __typename: "ModelEventExceptionConnection",
          items?:  Array< {
            __typename: "EventException",
            id: string,
            owner?: string | null,
            eventID: string,
            name?: string | null,
            description?: string | null,
            type?: EventExceptionType | null,
            date?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        menus?:  {
          __typename: "ModelEventMenuConnection",
          items?:  Array< {
            __typename: "EventMenu",
            id: string,
            owner?: string | null,
            eventID: string,
            menuID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        timeSlots?:  {
          __typename: "ModelTimeSlotConnection",
          items?:  Array< {
            __typename: "TimeSlot",
            id: string,
            owner?: string | null,
            venueID?: string | null,
            eventID?: string | null,
            type?: TimeSlotType | null,
            capacity?: number | null,
            position?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      },
      menu:  {
        __typename: "Menu",
        id: string,
        owner?: string | null,
        venueID: string,
        name?: string | null,
        description?: string | null,
        active?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        events?:  {
          __typename: "ModelEventMenuConnection",
          items?:  Array< {
            __typename: "EventMenu",
            id: string,
            owner?: string | null,
            eventID: string,
            menuID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        foods?:  {
          __typename: "ModelMenuFoodConnection",
          items?:  Array< {
            __typename: "MenuFood",
            id: string,
            owner?: string | null,
            menuID: string,
            foodID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncEventMenusQueryVariables = {
  filter?: ModelEventMenuFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncEventMenusQuery = {
  syncEventMenus?:  {
    __typename: "ModelEventMenuConnection",
    items?:  Array< {
      __typename: "EventMenu",
      id: string,
      owner?: string | null,
      eventID: string,
      menuID: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      event:  {
        __typename: "Event",
        id: string,
        owner?: string | null,
        venueID: string,
        name?: string | null,
        description?: string | null,
        active?: boolean | null,
        recurrenceFrequency?: RecurrenceFrequency | null,
        recurrenceInterval?: number | null,
        recurrenceCount?: number | null,
        recurrentDays?: Array< Weekday | null > | null,
        startDate?: string | null,
        endDate?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        duration?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        exceptions?:  {
          __typename: "ModelEventExceptionConnection",
          items?:  Array< {
            __typename: "EventException",
            id: string,
            owner?: string | null,
            eventID: string,
            name?: string | null,
            description?: string | null,
            type?: EventExceptionType | null,
            date?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        menus?:  {
          __typename: "ModelEventMenuConnection",
          items?:  Array< {
            __typename: "EventMenu",
            id: string,
            owner?: string | null,
            eventID: string,
            menuID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        timeSlots?:  {
          __typename: "ModelTimeSlotConnection",
          items?:  Array< {
            __typename: "TimeSlot",
            id: string,
            owner?: string | null,
            venueID?: string | null,
            eventID?: string | null,
            type?: TimeSlotType | null,
            capacity?: number | null,
            position?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      },
      menu:  {
        __typename: "Menu",
        id: string,
        owner?: string | null,
        venueID: string,
        name?: string | null,
        description?: string | null,
        active?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        events?:  {
          __typename: "ModelEventMenuConnection",
          items?:  Array< {
            __typename: "EventMenu",
            id: string,
            owner?: string | null,
            eventID: string,
            menuID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        foods?:  {
          __typename: "ModelMenuFoodConnection",
          items?:  Array< {
            __typename: "MenuFood",
            id: string,
            owner?: string | null,
            menuID: string,
            foodID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMenuQueryVariables = {
  id?: string,
};

export type GetMenuQuery = {
  getMenu?:  {
    __typename: "Menu",
    id: string,
    owner?: string | null,
    venueID: string,
    name?: string | null,
    description?: string | null,
    active?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    events?:  {
      __typename: "ModelEventMenuConnection",
      items?:  Array< {
        __typename: "EventMenu",
        id: string,
        owner?: string | null,
        eventID: string,
        menuID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        event:  {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    foods?:  {
      __typename: "ModelMenuFoodConnection",
      items?:  Array< {
        __typename: "MenuFood",
        id: string,
        owner?: string | null,
        menuID: string,
        foodID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        food:  {
          __typename: "Food",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          active?: boolean | null,
          calories?: number | null,
          foodType?: FoodType | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type ListMenusQueryVariables = {
  filter?: ModelMenuFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMenusQuery = {
  listMenus?:  {
    __typename: "ModelMenuConnection",
    items?:  Array< {
      __typename: "Menu",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      active?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventMenuConnection",
        items?:  Array< {
          __typename: "EventMenu",
          id: string,
          owner?: string | null,
          eventID: string,
          menuID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          event:  {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      foods?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type MenusByVenueQueryVariables = {
  venueID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMenuFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MenusByVenueQuery = {
  menusByVenue?:  {
    __typename: "ModelMenuConnection",
    items?:  Array< {
      __typename: "Menu",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      active?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventMenuConnection",
        items?:  Array< {
          __typename: "EventMenu",
          id: string,
          owner?: string | null,
          eventID: string,
          menuID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          event:  {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      foods?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMenusQueryVariables = {
  filter?: ModelMenuFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMenusQuery = {
  syncMenus?:  {
    __typename: "ModelMenuConnection",
    items?:  Array< {
      __typename: "Menu",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      active?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventMenuConnection",
        items?:  Array< {
          __typename: "EventMenu",
          id: string,
          owner?: string | null,
          eventID: string,
          menuID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          event:  {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      foods?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMenuFoodQueryVariables = {
  id?: string,
};

export type GetMenuFoodQuery = {
  getMenuFood?:  {
    __typename: "MenuFood",
    id: string,
    owner?: string | null,
    menuID: string,
    foodID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    menu:  {
      __typename: "Menu",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      active?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventMenuConnection",
        items?:  Array< {
          __typename: "EventMenu",
          id: string,
          owner?: string | null,
          eventID: string,
          menuID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          event:  {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      foods?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
    food:  {
      __typename: "Food",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      price?: number | null,
      active?: boolean | null,
      calories?: number | null,
      foodType?: FoodType | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      menus?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
  } | null,
};

export type ListMenuFoodsQueryVariables = {
  filter?: ModelMenuFoodFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMenuFoodsQuery = {
  listMenuFoods?:  {
    __typename: "ModelMenuFoodConnection",
    items?:  Array< {
      __typename: "MenuFood",
      id: string,
      owner?: string | null,
      menuID: string,
      foodID: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      menu:  {
        __typename: "Menu",
        id: string,
        owner?: string | null,
        venueID: string,
        name?: string | null,
        description?: string | null,
        active?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        events?:  {
          __typename: "ModelEventMenuConnection",
          items?:  Array< {
            __typename: "EventMenu",
            id: string,
            owner?: string | null,
            eventID: string,
            menuID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        foods?:  {
          __typename: "ModelMenuFoodConnection",
          items?:  Array< {
            __typename: "MenuFood",
            id: string,
            owner?: string | null,
            menuID: string,
            foodID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      },
      food:  {
        __typename: "Food",
        id: string,
        owner?: string | null,
        venueID: string,
        name?: string | null,
        description?: string | null,
        price?: number | null,
        active?: boolean | null,
        calories?: number | null,
        foodType?: FoodType | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        menus?:  {
          __typename: "ModelMenuFoodConnection",
          items?:  Array< {
            __typename: "MenuFood",
            id: string,
            owner?: string | null,
            menuID: string,
            foodID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type MenuFoodLinksByMenuQueryVariables = {
  menuID?: string | null,
  foodID?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMenuFoodFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MenuFoodLinksByMenuQuery = {
  menuFoodLinksByMenu?:  {
    __typename: "ModelMenuFoodConnection",
    items?:  Array< {
      __typename: "MenuFood",
      id: string,
      owner?: string | null,
      menuID: string,
      foodID: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      menu:  {
        __typename: "Menu",
        id: string,
        owner?: string | null,
        venueID: string,
        name?: string | null,
        description?: string | null,
        active?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        events?:  {
          __typename: "ModelEventMenuConnection",
          items?:  Array< {
            __typename: "EventMenu",
            id: string,
            owner?: string | null,
            eventID: string,
            menuID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        foods?:  {
          __typename: "ModelMenuFoodConnection",
          items?:  Array< {
            __typename: "MenuFood",
            id: string,
            owner?: string | null,
            menuID: string,
            foodID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      },
      food:  {
        __typename: "Food",
        id: string,
        owner?: string | null,
        venueID: string,
        name?: string | null,
        description?: string | null,
        price?: number | null,
        active?: boolean | null,
        calories?: number | null,
        foodType?: FoodType | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        menus?:  {
          __typename: "ModelMenuFoodConnection",
          items?:  Array< {
            __typename: "MenuFood",
            id: string,
            owner?: string | null,
            menuID: string,
            foodID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type MenuFoodLinksByFoodQueryVariables = {
  foodID?: string | null,
  menuID?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMenuFoodFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MenuFoodLinksByFoodQuery = {
  menuFoodLinksByFood?:  {
    __typename: "ModelMenuFoodConnection",
    items?:  Array< {
      __typename: "MenuFood",
      id: string,
      owner?: string | null,
      menuID: string,
      foodID: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      menu:  {
        __typename: "Menu",
        id: string,
        owner?: string | null,
        venueID: string,
        name?: string | null,
        description?: string | null,
        active?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        events?:  {
          __typename: "ModelEventMenuConnection",
          items?:  Array< {
            __typename: "EventMenu",
            id: string,
            owner?: string | null,
            eventID: string,
            menuID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        foods?:  {
          __typename: "ModelMenuFoodConnection",
          items?:  Array< {
            __typename: "MenuFood",
            id: string,
            owner?: string | null,
            menuID: string,
            foodID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      },
      food:  {
        __typename: "Food",
        id: string,
        owner?: string | null,
        venueID: string,
        name?: string | null,
        description?: string | null,
        price?: number | null,
        active?: boolean | null,
        calories?: number | null,
        foodType?: FoodType | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        menus?:  {
          __typename: "ModelMenuFoodConnection",
          items?:  Array< {
            __typename: "MenuFood",
            id: string,
            owner?: string | null,
            menuID: string,
            foodID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMenuFoodsQueryVariables = {
  filter?: ModelMenuFoodFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMenuFoodsQuery = {
  syncMenuFoods?:  {
    __typename: "ModelMenuFoodConnection",
    items?:  Array< {
      __typename: "MenuFood",
      id: string,
      owner?: string | null,
      menuID: string,
      foodID: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      menu:  {
        __typename: "Menu",
        id: string,
        owner?: string | null,
        venueID: string,
        name?: string | null,
        description?: string | null,
        active?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        events?:  {
          __typename: "ModelEventMenuConnection",
          items?:  Array< {
            __typename: "EventMenu",
            id: string,
            owner?: string | null,
            eventID: string,
            menuID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        foods?:  {
          __typename: "ModelMenuFoodConnection",
          items?:  Array< {
            __typename: "MenuFood",
            id: string,
            owner?: string | null,
            menuID: string,
            foodID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      },
      food:  {
        __typename: "Food",
        id: string,
        owner?: string | null,
        venueID: string,
        name?: string | null,
        description?: string | null,
        price?: number | null,
        active?: boolean | null,
        calories?: number | null,
        foodType?: FoodType | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        menus?:  {
          __typename: "ModelMenuFoodConnection",
          items?:  Array< {
            __typename: "MenuFood",
            id: string,
            owner?: string | null,
            menuID: string,
            foodID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetFoodQueryVariables = {
  id?: string,
};

export type GetFoodQuery = {
  getFood?:  {
    __typename: "Food",
    id: string,
    owner?: string | null,
    venueID: string,
    name?: string | null,
    description?: string | null,
    price?: number | null,
    active?: boolean | null,
    calories?: number | null,
    foodType?: FoodType | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    menus?:  {
      __typename: "ModelMenuFoodConnection",
      items?:  Array< {
        __typename: "MenuFood",
        id: string,
        owner?: string | null,
        menuID: string,
        foodID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        food:  {
          __typename: "Food",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          active?: boolean | null,
          calories?: number | null,
          foodType?: FoodType | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type ListFoodsQueryVariables = {
  filter?: ModelFoodFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFoodsQuery = {
  listFoods?:  {
    __typename: "ModelFoodConnection",
    items?:  Array< {
      __typename: "Food",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      price?: number | null,
      active?: boolean | null,
      calories?: number | null,
      foodType?: FoodType | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      menus?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type FoodsByVenueQueryVariables = {
  venueID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFoodFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FoodsByVenueQuery = {
  foodsByVenue?:  {
    __typename: "ModelFoodConnection",
    items?:  Array< {
      __typename: "Food",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      price?: number | null,
      active?: boolean | null,
      calories?: number | null,
      foodType?: FoodType | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      menus?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncFoodsQueryVariables = {
  filter?: ModelFoodFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncFoodsQuery = {
  syncFoods?:  {
    __typename: "ModelFoodConnection",
    items?:  Array< {
      __typename: "Food",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      price?: number | null,
      active?: boolean | null,
      calories?: number | null,
      foodType?: FoodType | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      menus?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTimeSlotQueryVariables = {
  id?: string,
};

export type GetTimeSlotQuery = {
  getTimeSlot?:  {
    __typename: "TimeSlot",
    id: string,
    owner?: string | null,
    venueID?: string | null,
    eventID?: string | null,
    type?: TimeSlotType | null,
    capacity?: number | null,
    position?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTimeSlotsQueryVariables = {
  filter?: ModelTimeSlotFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTimeSlotsQuery = {
  listTimeSlots?:  {
    __typename: "ModelTimeSlotConnection",
    items?:  Array< {
      __typename: "TimeSlot",
      id: string,
      owner?: string | null,
      venueID?: string | null,
      eventID?: string | null,
      type?: TimeSlotType | null,
      capacity?: number | null,
      position?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type TimeSlotsByEventQueryVariables = {
  eventID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTimeSlotFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TimeSlotsByEventQuery = {
  timeSlotsByEvent?:  {
    __typename: "ModelTimeSlotConnection",
    items?:  Array< {
      __typename: "TimeSlot",
      id: string,
      owner?: string | null,
      venueID?: string | null,
      eventID?: string | null,
      type?: TimeSlotType | null,
      capacity?: number | null,
      position?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type TimeSlotsByVenueQueryVariables = {
  venueID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTimeSlotFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TimeSlotsByVenueQuery = {
  timeSlotsByVenue?:  {
    __typename: "ModelTimeSlotConnection",
    items?:  Array< {
      __typename: "TimeSlot",
      id: string,
      owner?: string | null,
      venueID?: string | null,
      eventID?: string | null,
      type?: TimeSlotType | null,
      capacity?: number | null,
      position?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OrderedTimeSlotsByVenueQueryVariables = {
  venueID?: string | null,
  position?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTimeSlotFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrderedTimeSlotsByVenueQuery = {
  orderedTimeSlotsByVenue?:  {
    __typename: "ModelTimeSlotConnection",
    items?:  Array< {
      __typename: "TimeSlot",
      id: string,
      owner?: string | null,
      venueID?: string | null,
      eventID?: string | null,
      type?: TimeSlotType | null,
      capacity?: number | null,
      position?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTimeSlotsQueryVariables = {
  filter?: ModelTimeSlotFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTimeSlotsQuery = {
  syncTimeSlots?:  {
    __typename: "ModelTimeSlotConnection",
    items?:  Array< {
      __typename: "TimeSlot",
      id: string,
      owner?: string | null,
      venueID?: string | null,
      eventID?: string | null,
      type?: TimeSlotType | null,
      capacity?: number | null,
      position?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetReportQueryVariables = {
  id?: string,
};

export type GetReportQuery = {
  getReport?:  {
    __typename: "Report",
    id: string,
    owner?: string | null,
    venueID: string,
    reportUri?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListReportsQueryVariables = {
  filter?: ModelReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportsQuery = {
  listReports?:  {
    __typename: "ModelReportConnection",
    items?:  Array< {
      __typename: "Report",
      id: string,
      owner?: string | null,
      venueID: string,
      reportUri?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ReporstByVenueQueryVariables = {
  venueID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReporstByVenueQuery = {
  reporstByVenue?:  {
    __typename: "ModelReportConnection",
    items?:  Array< {
      __typename: "Report",
      id: string,
      owner?: string | null,
      venueID: string,
      reportUri?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncReportsQueryVariables = {
  filter?: ModelReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncReportsQuery = {
  syncReports?:  {
    __typename: "ModelReportConnection",
    items?:  Array< {
      __typename: "Report",
      id: string,
      owner?: string | null,
      venueID: string,
      reportUri?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAvailabilityQueryVariables = {
  id?: string,
};

export type GetAvailabilityQuery = {
  getAvailability?:  {
    __typename: "Availability",
    id: string,
    owner?: string | null,
    businessID: string,
    name: string,
    description?: string | null,
    active: boolean,
    recurrentDays: Array< Weekday | null >,
    startDate: string,
    endDate?: string | null,
    startTime: string,
    duration: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    exceptions?:  {
      __typename: "ModelAvailabilityExceptionConnection",
      items?:  Array< {
        __typename: "AvailabilityException",
        id: string,
        owner?: string | null,
        availabilityID: string,
        name?: string | null,
        description?: string | null,
        type?: EventExceptionType | null,
        date: string,
        startTime: string,
        duration: number,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type ListAvailabilitysQueryVariables = {
  filter?: ModelAvailabilityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAvailabilitysQuery = {
  listAvailabilitys?:  {
    __typename: "ModelAvailabilityConnection",
    items?:  Array< {
      __typename: "Availability",
      id: string,
      owner?: string | null,
      businessID: string,
      name: string,
      description?: string | null,
      active: boolean,
      recurrentDays: Array< Weekday | null >,
      startDate: string,
      endDate?: string | null,
      startTime: string,
      duration: number,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      exceptions?:  {
        __typename: "ModelAvailabilityExceptionConnection",
        items?:  Array< {
          __typename: "AvailabilityException",
          id: string,
          owner?: string | null,
          availabilityID: string,
          name?: string | null,
          description?: string | null,
          type?: EventExceptionType | null,
          date: string,
          startTime: string,
          duration: number,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type AvailabilitiesByBusinessQueryVariables = {
  businessID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAvailabilityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AvailabilitiesByBusinessQuery = {
  availabilitiesByBusiness?:  {
    __typename: "ModelAvailabilityConnection",
    items?:  Array< {
      __typename: "Availability",
      id: string,
      owner?: string | null,
      businessID: string,
      name: string,
      description?: string | null,
      active: boolean,
      recurrentDays: Array< Weekday | null >,
      startDate: string,
      endDate?: string | null,
      startTime: string,
      duration: number,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      exceptions?:  {
        __typename: "ModelAvailabilityExceptionConnection",
        items?:  Array< {
          __typename: "AvailabilityException",
          id: string,
          owner?: string | null,
          availabilityID: string,
          name?: string | null,
          description?: string | null,
          type?: EventExceptionType | null,
          date: string,
          startTime: string,
          duration: number,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAvailabilitiesQueryVariables = {
  filter?: ModelAvailabilityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAvailabilitiesQuery = {
  syncAvailabilities?:  {
    __typename: "ModelAvailabilityConnection",
    items?:  Array< {
      __typename: "Availability",
      id: string,
      owner?: string | null,
      businessID: string,
      name: string,
      description?: string | null,
      active: boolean,
      recurrentDays: Array< Weekday | null >,
      startDate: string,
      endDate?: string | null,
      startTime: string,
      duration: number,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      exceptions?:  {
        __typename: "ModelAvailabilityExceptionConnection",
        items?:  Array< {
          __typename: "AvailabilityException",
          id: string,
          owner?: string | null,
          availabilityID: string,
          name?: string | null,
          description?: string | null,
          type?: EventExceptionType | null,
          date: string,
          startTime: string,
          duration: number,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAvailabilityExceptionQueryVariables = {
  id?: string,
};

export type GetAvailabilityExceptionQuery = {
  getAvailabilityException?:  {
    __typename: "AvailabilityException",
    id: string,
    owner?: string | null,
    availabilityID: string,
    name?: string | null,
    description?: string | null,
    type?: EventExceptionType | null,
    date: string,
    startTime: string,
    duration: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAvailabilityExceptionsQueryVariables = {
  filter?: ModelAvailabilityExceptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAvailabilityExceptionsQuery = {
  listAvailabilityExceptions?:  {
    __typename: "ModelAvailabilityExceptionConnection",
    items?:  Array< {
      __typename: "AvailabilityException",
      id: string,
      owner?: string | null,
      availabilityID: string,
      name?: string | null,
      description?: string | null,
      type?: EventExceptionType | null,
      date: string,
      startTime: string,
      duration: number,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ExceptionsByAvailabilityQueryVariables = {
  availabilityID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAvailabilityExceptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ExceptionsByAvailabilityQuery = {
  exceptionsByAvailability?:  {
    __typename: "ModelAvailabilityExceptionConnection",
    items?:  Array< {
      __typename: "AvailabilityException",
      id: string,
      owner?: string | null,
      availabilityID: string,
      name?: string | null,
      description?: string | null,
      type?: EventExceptionType | null,
      date: string,
      startTime: string,
      duration: number,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAvailabilityExceptionsQueryVariables = {
  filter?: ModelAvailabilityExceptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAvailabilityExceptionsQuery = {
  syncAvailabilityExceptions?:  {
    __typename: "ModelAvailabilityExceptionConnection",
    items?:  Array< {
      __typename: "AvailabilityException",
      id: string,
      owner?: string | null,
      availabilityID: string,
      name?: string | null,
      description?: string | null,
      type?: EventExceptionType | null,
      date: string,
      startTime: string,
      duration: number,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetServiceQueryVariables = {
  id?: string,
};

export type GetServiceQuery = {
  getService?:  {
    __typename: "Service",
    id: string,
    owner?: string | null,
    businessID: string,
    name: string,
    description: string,
    duration: number,
    price: number,
    active: boolean,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    business?:  {
      __typename: "Business",
      id: string,
      universityID: string,
      providerID: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      locationOptionOnSite?: boolean | null,
      locationOptionVisit?: boolean | null,
      shortLocation?: string | null,
      telephone?: string | null,
      email?: string | null,
      links?: Array< string | null > | null,
      status?: BusinessStatus | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      categories?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      provider?:  {
        __typename: "Provider",
        id: string,
        userID: string,
        universityID: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        schoolMajor?: string | null,
        schoolYear?: string | null,
        schoolOrganizations?: Array< string | null > | null,
        links?: Array< string | null > | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: AccountStatus | null,
        invitationalEmail?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type ListServicesQueryVariables = {
  filter?: ModelServiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListServicesQuery = {
  listServices?:  {
    __typename: "ModelServiceConnection",
    items?:  Array< {
      __typename: "Service",
      id: string,
      owner?: string | null,
      businessID: string,
      name: string,
      description: string,
      duration: number,
      price: number,
      active: boolean,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        universityID: string,
        providerID: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        locationOptionOnSite?: boolean | null,
        locationOptionVisit?: boolean | null,
        shortLocation?: string | null,
        telephone?: string | null,
        email?: string | null,
        links?: Array< string | null > | null,
        status?: BusinessStatus | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        categories?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        provider?:  {
          __typename: "Provider",
          id: string,
          userID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          schoolMajor?: string | null,
          schoolYear?: string | null,
          schoolOrganizations?: Array< string | null > | null,
          links?: Array< string | null > | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ServicesByBusinessQueryVariables = {
  businessID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelServiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ServicesByBusinessQuery = {
  servicesByBusiness?:  {
    __typename: "ModelServiceConnection",
    items?:  Array< {
      __typename: "Service",
      id: string,
      owner?: string | null,
      businessID: string,
      name: string,
      description: string,
      duration: number,
      price: number,
      active: boolean,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        universityID: string,
        providerID: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        locationOptionOnSite?: boolean | null,
        locationOptionVisit?: boolean | null,
        shortLocation?: string | null,
        telephone?: string | null,
        email?: string | null,
        links?: Array< string | null > | null,
        status?: BusinessStatus | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        categories?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        provider?:  {
          __typename: "Provider",
          id: string,
          userID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          schoolMajor?: string | null,
          schoolYear?: string | null,
          schoolOrganizations?: Array< string | null > | null,
          links?: Array< string | null > | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncServicesQueryVariables = {
  filter?: ModelServiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncServicesQuery = {
  syncServices?:  {
    __typename: "ModelServiceConnection",
    items?:  Array< {
      __typename: "Service",
      id: string,
      owner?: string | null,
      businessID: string,
      name: string,
      description: string,
      duration: number,
      price: number,
      active: boolean,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        universityID: string,
        providerID: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        locationOptionOnSite?: boolean | null,
        locationOptionVisit?: boolean | null,
        shortLocation?: string | null,
        telephone?: string | null,
        email?: string | null,
        links?: Array< string | null > | null,
        status?: BusinessStatus | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        categories?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        provider?:  {
          __typename: "Provider",
          id: string,
          userID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          schoolMajor?: string | null,
          schoolYear?: string | null,
          schoolOrganizations?: Array< string | null > | null,
          links?: Array< string | null > | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCategoryQueryVariables = {
  id?: string,
};

export type GetCategoryQuery = {
  getCategory?:  {
    __typename: "Category",
    id: string,
    owner?: string | null,
    title: string,
    description: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCategorysQueryVariables = {
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCategorysQuery = {
  listCategorys?:  {
    __typename: "ModelCategoryConnection",
    items?:  Array< {
      __typename: "Category",
      id: string,
      owner?: string | null,
      title: string,
      description: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCategoriesQueryVariables = {
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCategoriesQuery = {
  syncCategories?:  {
    __typename: "ModelCategoryConnection",
    items?:  Array< {
      __typename: "Category",
      id: string,
      owner?: string | null,
      title: string,
      description: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetBookingQueryVariables = {
  id?: string,
};

export type GetBookingQuery = {
  getBooking?:  {
    __typename: "Booking",
    id: string,
    owner?: string | null,
    businessID: string,
    customerID: string,
    serviceID: string,
    price?: number | null,
    status?: BookingStatus | null,
    createdAt?: string | null,
    chargeID?: string | null,
    note?: string | null,
    locationName?: string | null,
    date: string,
    time: string,
    duration: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null,
    business?:  {
      __typename: "Business",
      id: string,
      universityID: string,
      providerID: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      locationOptionOnSite?: boolean | null,
      locationOptionVisit?: boolean | null,
      shortLocation?: string | null,
      telephone?: string | null,
      email?: string | null,
      links?: Array< string | null > | null,
      status?: BusinessStatus | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      categories?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      provider?:  {
        __typename: "Provider",
        id: string,
        userID: string,
        universityID: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        schoolMajor?: string | null,
        schoolYear?: string | null,
        schoolOrganizations?: Array< string | null > | null,
        links?: Array< string | null > | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: AccountStatus | null,
        invitationalEmail?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null,
    service?:  {
      __typename: "Service",
      id: string,
      owner?: string | null,
      businessID: string,
      name: string,
      description: string,
      duration: number,
      price: number,
      active: boolean,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        universityID: string,
        providerID: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        locationOptionOnSite?: boolean | null,
        locationOptionVisit?: boolean | null,
        shortLocation?: string | null,
        telephone?: string | null,
        email?: string | null,
        links?: Array< string | null > | null,
        status?: BusinessStatus | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        categories?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        provider?:  {
          __typename: "Provider",
          id: string,
          userID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          schoolMajor?: string | null,
          schoolYear?: string | null,
          schoolOrganizations?: Array< string | null > | null,
          links?: Array< string | null > | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type ListBookingsQueryVariables = {
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBookingsQuery = {
  listBookings?:  {
    __typename: "ModelBookingConnection",
    items?:  Array< {
      __typename: "Booking",
      id: string,
      owner?: string | null,
      businessID: string,
      customerID: string,
      serviceID: string,
      price?: number | null,
      status?: BookingStatus | null,
      createdAt?: string | null,
      chargeID?: string | null,
      note?: string | null,
      locationName?: string | null,
      date: string,
      time: string,
      duration: number,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      updatedAt: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        userID: string,
        universityID: string,
        studentID?: string | null,
        cartID: string,
        cbordPaymentToken?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        readGroups?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        cart?:  {
          __typename: "Cart",
          id: string,
          owner?: string | null,
          content?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
        } | null,
      } | null,
      business?:  {
        __typename: "Business",
        id: string,
        universityID: string,
        providerID: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        locationOptionOnSite?: boolean | null,
        locationOptionVisit?: boolean | null,
        shortLocation?: string | null,
        telephone?: string | null,
        email?: string | null,
        links?: Array< string | null > | null,
        status?: BusinessStatus | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        categories?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        provider?:  {
          __typename: "Provider",
          id: string,
          userID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          schoolMajor?: string | null,
          schoolYear?: string | null,
          schoolOrganizations?: Array< string | null > | null,
          links?: Array< string | null > | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null,
      service?:  {
        __typename: "Service",
        id: string,
        owner?: string | null,
        businessID: string,
        name: string,
        description: string,
        duration: number,
        price: number,
        active: boolean,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type BookingsByBusinessQueryVariables = {
  businessID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BookingsByBusinessQuery = {
  bookingsByBusiness?:  {
    __typename: "ModelBookingConnection",
    items?:  Array< {
      __typename: "Booking",
      id: string,
      owner?: string | null,
      businessID: string,
      customerID: string,
      serviceID: string,
      price?: number | null,
      status?: BookingStatus | null,
      createdAt?: string | null,
      chargeID?: string | null,
      note?: string | null,
      locationName?: string | null,
      date: string,
      time: string,
      duration: number,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      updatedAt: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        userID: string,
        universityID: string,
        studentID?: string | null,
        cartID: string,
        cbordPaymentToken?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        readGroups?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        cart?:  {
          __typename: "Cart",
          id: string,
          owner?: string | null,
          content?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
        } | null,
      } | null,
      business?:  {
        __typename: "Business",
        id: string,
        universityID: string,
        providerID: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        locationOptionOnSite?: boolean | null,
        locationOptionVisit?: boolean | null,
        shortLocation?: string | null,
        telephone?: string | null,
        email?: string | null,
        links?: Array< string | null > | null,
        status?: BusinessStatus | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        categories?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        provider?:  {
          __typename: "Provider",
          id: string,
          userID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          schoolMajor?: string | null,
          schoolYear?: string | null,
          schoolOrganizations?: Array< string | null > | null,
          links?: Array< string | null > | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null,
      service?:  {
        __typename: "Service",
        id: string,
        owner?: string | null,
        businessID: string,
        name: string,
        description: string,
        duration: number,
        price: number,
        active: boolean,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type BookingsByCustomerQueryVariables = {
  customerID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BookingsByCustomerQuery = {
  bookingsByCustomer?:  {
    __typename: "ModelBookingConnection",
    items?:  Array< {
      __typename: "Booking",
      id: string,
      owner?: string | null,
      businessID: string,
      customerID: string,
      serviceID: string,
      price?: number | null,
      status?: BookingStatus | null,
      createdAt?: string | null,
      chargeID?: string | null,
      note?: string | null,
      locationName?: string | null,
      date: string,
      time: string,
      duration: number,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      updatedAt: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        userID: string,
        universityID: string,
        studentID?: string | null,
        cartID: string,
        cbordPaymentToken?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        readGroups?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        cart?:  {
          __typename: "Cart",
          id: string,
          owner?: string | null,
          content?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
        } | null,
      } | null,
      business?:  {
        __typename: "Business",
        id: string,
        universityID: string,
        providerID: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        locationOptionOnSite?: boolean | null,
        locationOptionVisit?: boolean | null,
        shortLocation?: string | null,
        telephone?: string | null,
        email?: string | null,
        links?: Array< string | null > | null,
        status?: BusinessStatus | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        categories?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        provider?:  {
          __typename: "Provider",
          id: string,
          userID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          schoolMajor?: string | null,
          schoolYear?: string | null,
          schoolOrganizations?: Array< string | null > | null,
          links?: Array< string | null > | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null,
      service?:  {
        __typename: "Service",
        id: string,
        owner?: string | null,
        businessID: string,
        name: string,
        description: string,
        duration: number,
        price: number,
        active: boolean,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type BookingsByServiceQueryVariables = {
  serviceID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BookingsByServiceQuery = {
  bookingsByService?:  {
    __typename: "ModelBookingConnection",
    items?:  Array< {
      __typename: "Booking",
      id: string,
      owner?: string | null,
      businessID: string,
      customerID: string,
      serviceID: string,
      price?: number | null,
      status?: BookingStatus | null,
      createdAt?: string | null,
      chargeID?: string | null,
      note?: string | null,
      locationName?: string | null,
      date: string,
      time: string,
      duration: number,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      updatedAt: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        userID: string,
        universityID: string,
        studentID?: string | null,
        cartID: string,
        cbordPaymentToken?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        readGroups?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        cart?:  {
          __typename: "Cart",
          id: string,
          owner?: string | null,
          content?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
        } | null,
      } | null,
      business?:  {
        __typename: "Business",
        id: string,
        universityID: string,
        providerID: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        locationOptionOnSite?: boolean | null,
        locationOptionVisit?: boolean | null,
        shortLocation?: string | null,
        telephone?: string | null,
        email?: string | null,
        links?: Array< string | null > | null,
        status?: BusinessStatus | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        categories?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        provider?:  {
          __typename: "Provider",
          id: string,
          userID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          schoolMajor?: string | null,
          schoolYear?: string | null,
          schoolOrganizations?: Array< string | null > | null,
          links?: Array< string | null > | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null,
      service?:  {
        __typename: "Service",
        id: string,
        owner?: string | null,
        businessID: string,
        name: string,
        description: string,
        duration: number,
        price: number,
        active: boolean,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncBookingsQueryVariables = {
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncBookingsQuery = {
  syncBookings?:  {
    __typename: "ModelBookingConnection",
    items?:  Array< {
      __typename: "Booking",
      id: string,
      owner?: string | null,
      businessID: string,
      customerID: string,
      serviceID: string,
      price?: number | null,
      status?: BookingStatus | null,
      createdAt?: string | null,
      chargeID?: string | null,
      note?: string | null,
      locationName?: string | null,
      date: string,
      time: string,
      duration: number,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      updatedAt: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        userID: string,
        universityID: string,
        studentID?: string | null,
        cartID: string,
        cbordPaymentToken?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        readGroups?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        cart?:  {
          __typename: "Cart",
          id: string,
          owner?: string | null,
          content?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
        } | null,
      } | null,
      business?:  {
        __typename: "Business",
        id: string,
        universityID: string,
        providerID: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        locationOptionOnSite?: boolean | null,
        locationOptionVisit?: boolean | null,
        shortLocation?: string | null,
        telephone?: string | null,
        email?: string | null,
        links?: Array< string | null > | null,
        status?: BusinessStatus | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        categories?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        provider?:  {
          __typename: "Provider",
          id: string,
          userID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          schoolMajor?: string | null,
          schoolYear?: string | null,
          schoolOrganizations?: Array< string | null > | null,
          links?: Array< string | null > | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null,
      service?:  {
        __typename: "Service",
        id: string,
        owner?: string | null,
        businessID: string,
        name: string,
        description: string,
        duration: number,
        price: number,
        active: boolean,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OnCreateOrderByVenueSubscriptionVariables = {
  venueID?: string,
};

export type OnCreateOrderByVenueSubscription = {
  onCreateOrderByVenue?:  {
    __typename: "Order",
    id: string,
    owner?: string | null,
    customerID: string,
    venueID: string,
    eventID?: string | null,
    delivererID?: string | null,
    price?: number | null,
    totalPrice?: number | null,
    status?: OrderStatus | null,
    notes?: string | null,
    createdAt?: string | null,
    timeSlot?: string | null,
    timeSlotID?: string | null,
    paymentMethod?: string | null,
    chargeID?: string | null,
    serviceOption?: TimeSlotType | null,
    locationName?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null,
    history?:  {
      __typename: "ModelOrderStateConnection",
      items?:  Array< {
        __typename: "OrderState",
        id: string,
        owner?: string | null,
        orderID: string,
        status?: OrderStatus | null,
        createdAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    orderItems?:  {
      __typename: "ModelOrderItemConnection",
      items?:  Array< {
        __typename: "OrderItem",
        id: string,
        owner?: string | null,
        orderID: string,
        foodID: string,
        price?: number | null,
        amount: number,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        food?:  {
          __typename: "Food",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          active?: boolean | null,
          calories?: number | null,
          foodType?: FoodType | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    venue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateOrderByCustomerSubscriptionVariables = {
  customerID?: string,
};

export type OnUpdateOrderByCustomerSubscription = {
  onUpdateOrderByCustomer?:  {
    __typename: "Order",
    id: string,
    owner?: string | null,
    customerID: string,
    venueID: string,
    eventID?: string | null,
    delivererID?: string | null,
    price?: number | null,
    totalPrice?: number | null,
    status?: OrderStatus | null,
    notes?: string | null,
    createdAt?: string | null,
    timeSlot?: string | null,
    timeSlotID?: string | null,
    paymentMethod?: string | null,
    chargeID?: string | null,
    serviceOption?: TimeSlotType | null,
    locationName?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null,
    history?:  {
      __typename: "ModelOrderStateConnection",
      items?:  Array< {
        __typename: "OrderState",
        id: string,
        owner?: string | null,
        orderID: string,
        status?: OrderStatus | null,
        createdAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    orderItems?:  {
      __typename: "ModelOrderItemConnection",
      items?:  Array< {
        __typename: "OrderItem",
        id: string,
        owner?: string | null,
        orderID: string,
        foodID: string,
        price?: number | null,
        amount: number,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        food?:  {
          __typename: "Food",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          active?: boolean | null,
          calories?: number | null,
          foodType?: FoodType | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    venue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateOrderByVenueSubscriptionVariables = {
  venueID?: string,
};

export type OnUpdateOrderByVenueSubscription = {
  onUpdateOrderByVenue?:  {
    __typename: "Order",
    id: string,
    owner?: string | null,
    customerID: string,
    venueID: string,
    eventID?: string | null,
    delivererID?: string | null,
    price?: number | null,
    totalPrice?: number | null,
    status?: OrderStatus | null,
    notes?: string | null,
    createdAt?: string | null,
    timeSlot?: string | null,
    timeSlotID?: string | null,
    paymentMethod?: string | null,
    chargeID?: string | null,
    serviceOption?: TimeSlotType | null,
    locationName?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null,
    history?:  {
      __typename: "ModelOrderStateConnection",
      items?:  Array< {
        __typename: "OrderState",
        id: string,
        owner?: string | null,
        orderID: string,
        status?: OrderStatus | null,
        createdAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    orderItems?:  {
      __typename: "ModelOrderItemConnection",
      items?:  Array< {
        __typename: "OrderItem",
        id: string,
        owner?: string | null,
        orderID: string,
        foodID: string,
        price?: number | null,
        amount: number,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        food?:  {
          __typename: "Food",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          active?: boolean | null,
          calories?: number | null,
          foodType?: FoodType | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    venue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateReservationByVenueSubscriptionVariables = {
  venueID?: string,
};

export type OnCreateReservationByVenueSubscription = {
  onCreateReservationByVenue?:  {
    __typename: "Reservation",
    id: string,
    owner?: string | null,
    customerID: string,
    venueID: string,
    eventID: string,
    status?: ReservationStatus | null,
    createdAt?: string | null,
    timeSlot?: string | null,
    timeSlotID?: string | null,
    serviceOption?: TimeSlotType | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null,
    venue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateReservationByCustomerSubscriptionVariables = {
  customerID?: string,
};

export type OnUpdateReservationByCustomerSubscription = {
  onUpdateReservationByCustomer?:  {
    __typename: "Reservation",
    id: string,
    owner?: string | null,
    customerID: string,
    venueID: string,
    eventID: string,
    status?: ReservationStatus | null,
    createdAt?: string | null,
    timeSlot?: string | null,
    timeSlotID?: string | null,
    serviceOption?: TimeSlotType | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null,
    venue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateReservationByVenueSubscriptionVariables = {
  venueID?: string,
};

export type OnUpdateReservationByVenueSubscription = {
  onUpdateReservationByVenue?:  {
    __typename: "Reservation",
    id: string,
    owner?: string | null,
    customerID: string,
    venueID: string,
    eventID: string,
    status?: ReservationStatus | null,
    createdAt?: string | null,
    timeSlot?: string | null,
    timeSlotID?: string | null,
    serviceOption?: TimeSlotType | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null,
    venue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateBookingByBusinessSubscriptionVariables = {
  businessID?: string,
};

export type OnCreateBookingByBusinessSubscription = {
  onCreateBookingByBusiness?:  {
    __typename: "Booking",
    id: string,
    owner?: string | null,
    businessID: string,
    customerID: string,
    serviceID: string,
    price?: number | null,
    status?: BookingStatus | null,
    createdAt?: string | null,
    chargeID?: string | null,
    note?: string | null,
    locationName?: string | null,
    date: string,
    time: string,
    duration: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null,
    business?:  {
      __typename: "Business",
      id: string,
      universityID: string,
      providerID: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      locationOptionOnSite?: boolean | null,
      locationOptionVisit?: boolean | null,
      shortLocation?: string | null,
      telephone?: string | null,
      email?: string | null,
      links?: Array< string | null > | null,
      status?: BusinessStatus | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      categories?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      provider?:  {
        __typename: "Provider",
        id: string,
        userID: string,
        universityID: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        schoolMajor?: string | null,
        schoolYear?: string | null,
        schoolOrganizations?: Array< string | null > | null,
        links?: Array< string | null > | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: AccountStatus | null,
        invitationalEmail?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null,
    service?:  {
      __typename: "Service",
      id: string,
      owner?: string | null,
      businessID: string,
      name: string,
      description: string,
      duration: number,
      price: number,
      active: boolean,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        universityID: string,
        providerID: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        locationOptionOnSite?: boolean | null,
        locationOptionVisit?: boolean | null,
        shortLocation?: string | null,
        telephone?: string | null,
        email?: string | null,
        links?: Array< string | null > | null,
        status?: BusinessStatus | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        categories?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        provider?:  {
          __typename: "Provider",
          id: string,
          userID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          schoolMajor?: string | null,
          schoolYear?: string | null,
          schoolOrganizations?: Array< string | null > | null,
          links?: Array< string | null > | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateBookingByBusinessSubscriptionVariables = {
  businessID?: string,
};

export type OnUpdateBookingByBusinessSubscription = {
  onUpdateBookingByBusiness?:  {
    __typename: "Booking",
    id: string,
    owner?: string | null,
    businessID: string,
    customerID: string,
    serviceID: string,
    price?: number | null,
    status?: BookingStatus | null,
    createdAt?: string | null,
    chargeID?: string | null,
    note?: string | null,
    locationName?: string | null,
    date: string,
    time: string,
    duration: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null,
    business?:  {
      __typename: "Business",
      id: string,
      universityID: string,
      providerID: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      locationOptionOnSite?: boolean | null,
      locationOptionVisit?: boolean | null,
      shortLocation?: string | null,
      telephone?: string | null,
      email?: string | null,
      links?: Array< string | null > | null,
      status?: BusinessStatus | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      categories?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      provider?:  {
        __typename: "Provider",
        id: string,
        userID: string,
        universityID: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        schoolMajor?: string | null,
        schoolYear?: string | null,
        schoolOrganizations?: Array< string | null > | null,
        links?: Array< string | null > | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: AccountStatus | null,
        invitationalEmail?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null,
    service?:  {
      __typename: "Service",
      id: string,
      owner?: string | null,
      businessID: string,
      name: string,
      description: string,
      duration: number,
      price: number,
      active: boolean,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        universityID: string,
        providerID: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        locationOptionOnSite?: boolean | null,
        locationOptionVisit?: boolean | null,
        shortLocation?: string | null,
        telephone?: string | null,
        email?: string | null,
        links?: Array< string | null > | null,
        status?: BusinessStatus | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        categories?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        provider?:  {
          __typename: "Provider",
          id: string,
          userID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          schoolMajor?: string | null,
          schoolYear?: string | null,
          schoolOrganizations?: Array< string | null > | null,
          links?: Array< string | null > | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateFoodsByVenueSubscriptionVariables = {
  venueID?: string,
};

export type OnUpdateFoodsByVenueSubscription = {
  onUpdateFoodsByVenue?:  {
    __typename: "Food",
    id: string,
    owner?: string | null,
    venueID: string,
    name?: string | null,
    description?: string | null,
    price?: number | null,
    active?: boolean | null,
    calories?: number | null,
    foodType?: FoodType | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    menus?:  {
      __typename: "ModelMenuFoodConnection",
      items?:  Array< {
        __typename: "MenuFood",
        id: string,
        owner?: string | null,
        menuID: string,
        foodID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        food:  {
          __typename: "Food",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          active?: boolean | null,
          calories?: number | null,
          foodType?: FoodType | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnUpdateTimeSlotByVenueSubscriptionVariables = {
  venueID?: string,
};

export type OnUpdateTimeSlotByVenueSubscription = {
  onUpdateTimeSlotByVenue?:  {
    __typename: "TimeSlot",
    id: string,
    owner?: string | null,
    venueID?: string | null,
    eventID?: string | null,
    type?: TimeSlotType | null,
    capacity?: number | null,
    position?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateReportByVenueSubscriptionVariables = {
  venueID?: string,
};

export type OnCreateReportByVenueSubscription = {
  onCreateReportByVenue?:  {
    __typename: "Report",
    id: string,
    owner?: string | null,
    venueID: string,
    reportUri?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCustomerSubscriptionVariables = {
  userID?: string | null,
};

export type OnCreateCustomerSubscription = {
  onCreateCustomer?:  {
    __typename: "Customer",
    id: string,
    userID: string,
    universityID: string,
    studentID?: string | null,
    cartID: string,
    cbordPaymentToken?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    readGroups?: Array< string | null > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    cart?:  {
      __typename: "Cart",
      id: string,
      owner?: string | null,
      content?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
    } | null,
  } | null,
};

export type OnUpdateCustomerSubscriptionVariables = {
  userID?: string | null,
};

export type OnUpdateCustomerSubscription = {
  onUpdateCustomer?:  {
    __typename: "Customer",
    id: string,
    userID: string,
    universityID: string,
    studentID?: string | null,
    cartID: string,
    cbordPaymentToken?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    readGroups?: Array< string | null > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    cart?:  {
      __typename: "Cart",
      id: string,
      owner?: string | null,
      content?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
    } | null,
  } | null,
};

export type OnDeleteCustomerSubscriptionVariables = {
  userID?: string | null,
};

export type OnDeleteCustomerSubscription = {
  onDeleteCustomer?:  {
    __typename: "Customer",
    id: string,
    userID: string,
    universityID: string,
    studentID?: string | null,
    cartID: string,
    cbordPaymentToken?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    readGroups?: Array< string | null > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    cart?:  {
      __typename: "Cart",
      id: string,
      owner?: string | null,
      content?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
    } | null,
  } | null,
};

export type OnCreateDelivererSubscriptionVariables = {
  userID?: string | null,
};

export type OnCreateDelivererSubscription = {
  onCreateDeliverer?:  {
    __typename: "Deliverer",
    id: string,
    userID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    active?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    orders?:  {
      __typename: "ModelOrderConnection",
      items?:  Array< {
        __typename: "Order",
        id: string,
        owner?: string | null,
        customerID: string,
        venueID: string,
        eventID?: string | null,
        delivererID?: string | null,
        price?: number | null,
        totalPrice?: number | null,
        status?: OrderStatus | null,
        notes?: string | null,
        createdAt?: string | null,
        timeSlot?: string | null,
        timeSlotID?: string | null,
        paymentMethod?: string | null,
        chargeID?: string | null,
        serviceOption?: TimeSlotType | null,
        locationName?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        updatedAt: string,
        customer?:  {
          __typename: "Customer",
          id: string,
          userID: string,
          universityID: string,
          studentID?: string | null,
          cartID: string,
          cbordPaymentToken?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          readGroups?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          cart?:  {
            __typename: "Cart",
            id: string,
            owner?: string | null,
            content?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
          } | null,
        } | null,
        history?:  {
          __typename: "ModelOrderStateConnection",
          items?:  Array< {
            __typename: "OrderState",
            id: string,
            owner?: string | null,
            orderID: string,
            status?: OrderStatus | null,
            createdAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orderItems?:  {
          __typename: "ModelOrderItemConnection",
          items?:  Array< {
            __typename: "OrderItem",
            id: string,
            owner?: string | null,
            orderID: string,
            foodID: string,
            price?: number | null,
            amount: number,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venue?:  {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    university?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateDelivererSubscriptionVariables = {
  userID?: string | null,
};

export type OnUpdateDelivererSubscription = {
  onUpdateDeliverer?:  {
    __typename: "Deliverer",
    id: string,
    userID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    active?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    orders?:  {
      __typename: "ModelOrderConnection",
      items?:  Array< {
        __typename: "Order",
        id: string,
        owner?: string | null,
        customerID: string,
        venueID: string,
        eventID?: string | null,
        delivererID?: string | null,
        price?: number | null,
        totalPrice?: number | null,
        status?: OrderStatus | null,
        notes?: string | null,
        createdAt?: string | null,
        timeSlot?: string | null,
        timeSlotID?: string | null,
        paymentMethod?: string | null,
        chargeID?: string | null,
        serviceOption?: TimeSlotType | null,
        locationName?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        updatedAt: string,
        customer?:  {
          __typename: "Customer",
          id: string,
          userID: string,
          universityID: string,
          studentID?: string | null,
          cartID: string,
          cbordPaymentToken?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          readGroups?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          cart?:  {
            __typename: "Cart",
            id: string,
            owner?: string | null,
            content?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
          } | null,
        } | null,
        history?:  {
          __typename: "ModelOrderStateConnection",
          items?:  Array< {
            __typename: "OrderState",
            id: string,
            owner?: string | null,
            orderID: string,
            status?: OrderStatus | null,
            createdAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orderItems?:  {
          __typename: "ModelOrderItemConnection",
          items?:  Array< {
            __typename: "OrderItem",
            id: string,
            owner?: string | null,
            orderID: string,
            foodID: string,
            price?: number | null,
            amount: number,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venue?:  {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    university?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteDelivererSubscriptionVariables = {
  userID?: string | null,
};

export type OnDeleteDelivererSubscription = {
  onDeleteDeliverer?:  {
    __typename: "Deliverer",
    id: string,
    userID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    active?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    orders?:  {
      __typename: "ModelOrderConnection",
      items?:  Array< {
        __typename: "Order",
        id: string,
        owner?: string | null,
        customerID: string,
        venueID: string,
        eventID?: string | null,
        delivererID?: string | null,
        price?: number | null,
        totalPrice?: number | null,
        status?: OrderStatus | null,
        notes?: string | null,
        createdAt?: string | null,
        timeSlot?: string | null,
        timeSlotID?: string | null,
        paymentMethod?: string | null,
        chargeID?: string | null,
        serviceOption?: TimeSlotType | null,
        locationName?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        updatedAt: string,
        customer?:  {
          __typename: "Customer",
          id: string,
          userID: string,
          universityID: string,
          studentID?: string | null,
          cartID: string,
          cbordPaymentToken?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          readGroups?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          cart?:  {
            __typename: "Cart",
            id: string,
            owner?: string | null,
            content?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
          } | null,
        } | null,
        history?:  {
          __typename: "ModelOrderStateConnection",
          items?:  Array< {
            __typename: "OrderState",
            id: string,
            owner?: string | null,
            orderID: string,
            status?: OrderStatus | null,
            createdAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orderItems?:  {
          __typename: "ModelOrderItemConnection",
          items?:  Array< {
            __typename: "OrderItem",
            id: string,
            owner?: string | null,
            orderID: string,
            foodID: string,
            price?: number | null,
            amount: number,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venue?:  {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    university?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateDirectorSubscriptionVariables = {
  userID?: string | null,
};

export type OnCreateDirectorSubscription = {
  onCreateDirector?:  {
    __typename: "Director",
    id: string,
    userID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    university?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateDirectorSubscriptionVariables = {
  userID?: string | null,
};

export type OnUpdateDirectorSubscription = {
  onUpdateDirector?:  {
    __typename: "Director",
    id: string,
    userID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    university?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteDirectorSubscriptionVariables = {
  userID?: string | null,
};

export type OnDeleteDirectorSubscription = {
  onDeleteDirector?:  {
    __typename: "Director",
    id: string,
    userID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    university?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateProviderSubscriptionVariables = {
  userID?: string | null,
};

export type OnCreateProviderSubscription = {
  onCreateProvider?:  {
    __typename: "Provider",
    id: string,
    userID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    schoolMajor?: string | null,
    schoolYear?: string | null,
    schoolOrganizations?: Array< string | null > | null,
    links?: Array< string | null > | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    university?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    businesses?:  {
      __typename: "ModelBusinessConnection",
      items?:  Array< {
        __typename: "Business",
        id: string,
        universityID: string,
        providerID: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        locationOptionOnSite?: boolean | null,
        locationOptionVisit?: boolean | null,
        shortLocation?: string | null,
        telephone?: string | null,
        email?: string | null,
        links?: Array< string | null > | null,
        status?: BusinessStatus | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        categories?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        provider?:  {
          __typename: "Provider",
          id: string,
          userID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          schoolMajor?: string | null,
          schoolYear?: string | null,
          schoolOrganizations?: Array< string | null > | null,
          links?: Array< string | null > | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnUpdateProviderSubscriptionVariables = {
  userID?: string | null,
};

export type OnUpdateProviderSubscription = {
  onUpdateProvider?:  {
    __typename: "Provider",
    id: string,
    userID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    schoolMajor?: string | null,
    schoolYear?: string | null,
    schoolOrganizations?: Array< string | null > | null,
    links?: Array< string | null > | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    university?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    businesses?:  {
      __typename: "ModelBusinessConnection",
      items?:  Array< {
        __typename: "Business",
        id: string,
        universityID: string,
        providerID: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        locationOptionOnSite?: boolean | null,
        locationOptionVisit?: boolean | null,
        shortLocation?: string | null,
        telephone?: string | null,
        email?: string | null,
        links?: Array< string | null > | null,
        status?: BusinessStatus | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        categories?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        provider?:  {
          __typename: "Provider",
          id: string,
          userID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          schoolMajor?: string | null,
          schoolYear?: string | null,
          schoolOrganizations?: Array< string | null > | null,
          links?: Array< string | null > | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnDeleteProviderSubscriptionVariables = {
  userID?: string | null,
};

export type OnDeleteProviderSubscription = {
  onDeleteProvider?:  {
    __typename: "Provider",
    id: string,
    userID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    schoolMajor?: string | null,
    schoolYear?: string | null,
    schoolOrganizations?: Array< string | null > | null,
    links?: Array< string | null > | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    university?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    businesses?:  {
      __typename: "ModelBusinessConnection",
      items?:  Array< {
        __typename: "Business",
        id: string,
        universityID: string,
        providerID: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        locationOptionOnSite?: boolean | null,
        locationOptionVisit?: boolean | null,
        shortLocation?: string | null,
        telephone?: string | null,
        email?: string | null,
        links?: Array< string | null > | null,
        status?: BusinessStatus | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        categories?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        provider?:  {
          __typename: "Provider",
          id: string,
          userID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          schoolMajor?: string | null,
          schoolYear?: string | null,
          schoolOrganizations?: Array< string | null > | null,
          links?: Array< string | null > | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnCreateVendorSubscriptionVariables = {
  userID?: string | null,
};

export type OnCreateVendorSubscription = {
  onCreateVendor?:  {
    __typename: "Vendor",
    id: string,
    userID: string,
    venueID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    parentUniversity?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    parentVenue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateVendorSubscriptionVariables = {
  userID?: string | null,
};

export type OnUpdateVendorSubscription = {
  onUpdateVendor?:  {
    __typename: "Vendor",
    id: string,
    userID: string,
    venueID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    parentUniversity?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    parentVenue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteVendorSubscriptionVariables = {
  userID?: string | null,
};

export type OnDeleteVendorSubscription = {
  onDeleteVendor?:  {
    __typename: "Vendor",
    id: string,
    userID: string,
    venueID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    parentUniversity?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    parentVenue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateStaffSubscriptionVariables = {
  userID?: string | null,
};

export type OnCreateStaffSubscription = {
  onCreateStaff?:  {
    __typename: "Staff",
    id: string,
    userID: string,
    venueID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    parentUniversity?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    parentVenue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateStaffSubscriptionVariables = {
  userID?: string | null,
};

export type OnUpdateStaffSubscription = {
  onUpdateStaff?:  {
    __typename: "Staff",
    id: string,
    userID: string,
    venueID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    parentUniversity?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    parentVenue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteStaffSubscriptionVariables = {
  userID?: string | null,
};

export type OnDeleteStaffSubscription = {
  onDeleteStaff?:  {
    __typename: "Staff",
    id: string,
    userID: string,
    venueID: string,
    universityID: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: AccountStatus | null,
    invitationalEmail?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    parentUniversity?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    parentVenue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateCartSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateCartSubscription = {
  onCreateCart?:  {
    __typename: "Cart",
    id: string,
    owner?: string | null,
    content?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
  } | null,
};

export type OnUpdateCartSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateCartSubscription = {
  onUpdateCart?:  {
    __typename: "Cart",
    id: string,
    owner?: string | null,
    content?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
  } | null,
};

export type OnDeleteCartSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteCartSubscription = {
  onDeleteCart?:  {
    __typename: "Cart",
    id: string,
    owner?: string | null,
    content?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
  } | null,
};

export type OnCreateReservationSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateReservationSubscription = {
  onCreateReservation?:  {
    __typename: "Reservation",
    id: string,
    owner?: string | null,
    customerID: string,
    venueID: string,
    eventID: string,
    status?: ReservationStatus | null,
    createdAt?: string | null,
    timeSlot?: string | null,
    timeSlotID?: string | null,
    serviceOption?: TimeSlotType | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null,
    venue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateReservationSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateReservationSubscription = {
  onUpdateReservation?:  {
    __typename: "Reservation",
    id: string,
    owner?: string | null,
    customerID: string,
    venueID: string,
    eventID: string,
    status?: ReservationStatus | null,
    createdAt?: string | null,
    timeSlot?: string | null,
    timeSlotID?: string | null,
    serviceOption?: TimeSlotType | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null,
    venue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteReservationSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteReservationSubscription = {
  onDeleteReservation?:  {
    __typename: "Reservation",
    id: string,
    owner?: string | null,
    customerID: string,
    venueID: string,
    eventID: string,
    status?: ReservationStatus | null,
    createdAt?: string | null,
    timeSlot?: string | null,
    timeSlotID?: string | null,
    serviceOption?: TimeSlotType | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null,
    venue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateOrderStateSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateOrderStateSubscription = {
  onCreateOrderState?:  {
    __typename: "OrderState",
    id: string,
    owner?: string | null,
    orderID: string,
    status?: OrderStatus | null,
    createdAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
  } | null,
};

export type OnUpdateOrderStateSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateOrderStateSubscription = {
  onUpdateOrderState?:  {
    __typename: "OrderState",
    id: string,
    owner?: string | null,
    orderID: string,
    status?: OrderStatus | null,
    createdAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
  } | null,
};

export type OnDeleteOrderStateSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteOrderStateSubscription = {
  onDeleteOrderState?:  {
    __typename: "OrderState",
    id: string,
    owner?: string | null,
    orderID: string,
    status?: OrderStatus | null,
    createdAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
  } | null,
};

export type OnCreateOrderSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateOrderSubscription = {
  onCreateOrder?:  {
    __typename: "Order",
    id: string,
    owner?: string | null,
    customerID: string,
    venueID: string,
    eventID?: string | null,
    delivererID?: string | null,
    price?: number | null,
    totalPrice?: number | null,
    status?: OrderStatus | null,
    notes?: string | null,
    createdAt?: string | null,
    timeSlot?: string | null,
    timeSlotID?: string | null,
    paymentMethod?: string | null,
    chargeID?: string | null,
    serviceOption?: TimeSlotType | null,
    locationName?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null,
    history?:  {
      __typename: "ModelOrderStateConnection",
      items?:  Array< {
        __typename: "OrderState",
        id: string,
        owner?: string | null,
        orderID: string,
        status?: OrderStatus | null,
        createdAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    orderItems?:  {
      __typename: "ModelOrderItemConnection",
      items?:  Array< {
        __typename: "OrderItem",
        id: string,
        owner?: string | null,
        orderID: string,
        foodID: string,
        price?: number | null,
        amount: number,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        food?:  {
          __typename: "Food",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          active?: boolean | null,
          calories?: number | null,
          foodType?: FoodType | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    venue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateOrderSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateOrderSubscription = {
  onUpdateOrder?:  {
    __typename: "Order",
    id: string,
    owner?: string | null,
    customerID: string,
    venueID: string,
    eventID?: string | null,
    delivererID?: string | null,
    price?: number | null,
    totalPrice?: number | null,
    status?: OrderStatus | null,
    notes?: string | null,
    createdAt?: string | null,
    timeSlot?: string | null,
    timeSlotID?: string | null,
    paymentMethod?: string | null,
    chargeID?: string | null,
    serviceOption?: TimeSlotType | null,
    locationName?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null,
    history?:  {
      __typename: "ModelOrderStateConnection",
      items?:  Array< {
        __typename: "OrderState",
        id: string,
        owner?: string | null,
        orderID: string,
        status?: OrderStatus | null,
        createdAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    orderItems?:  {
      __typename: "ModelOrderItemConnection",
      items?:  Array< {
        __typename: "OrderItem",
        id: string,
        owner?: string | null,
        orderID: string,
        foodID: string,
        price?: number | null,
        amount: number,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        food?:  {
          __typename: "Food",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          active?: boolean | null,
          calories?: number | null,
          foodType?: FoodType | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    venue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteOrderSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteOrderSubscription = {
  onDeleteOrder?:  {
    __typename: "Order",
    id: string,
    owner?: string | null,
    customerID: string,
    venueID: string,
    eventID?: string | null,
    delivererID?: string | null,
    price?: number | null,
    totalPrice?: number | null,
    status?: OrderStatus | null,
    notes?: string | null,
    createdAt?: string | null,
    timeSlot?: string | null,
    timeSlotID?: string | null,
    paymentMethod?: string | null,
    chargeID?: string | null,
    serviceOption?: TimeSlotType | null,
    locationName?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null,
    history?:  {
      __typename: "ModelOrderStateConnection",
      items?:  Array< {
        __typename: "OrderState",
        id: string,
        owner?: string | null,
        orderID: string,
        status?: OrderStatus | null,
        createdAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    orderItems?:  {
      __typename: "ModelOrderItemConnection",
      items?:  Array< {
        __typename: "OrderItem",
        id: string,
        owner?: string | null,
        orderID: string,
        foodID: string,
        price?: number | null,
        amount: number,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        food?:  {
          __typename: "Food",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          active?: boolean | null,
          calories?: number | null,
          foodType?: FoodType | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    venue?:  {
      __typename: "Venue",
      id: string,
      universityID: string,
      owner?: string | null,
      name?: string | null,
      businessName?: string | null,
      description?: string | null,
      status?: VendorStatus | null,
      type?: EventType | null,
      categoty?: VenueCategory | null,
      buffetPrice?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      payInApp?: boolean | null,
      payInPerson?: boolean | null,
      payWithCard?: boolean | null,
      payWithCash?: boolean | null,
      payWithStudentID?: boolean | null,
      payWithCurrency?: boolean | null,
      currency?: string | null,
      dineInAvailable?: boolean | null,
      reservationAvailable?: boolean | null,
      pickUpAvailable?: boolean | null,
      deliveryAvailable?: boolean | null,
      timeSlotsDineInCapacity?: number | null,
      timeSlotsPickUpCapacity?: number | null,
      timeSlotsDeliveryCapacity?: number | null,
      timeslotsIgnore?: boolean | null,
      timeslotsInterval?: number | null,
      timeSlotsStatus?: TimeSlotsStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      vendors?:  {
        __typename: "ModelVendorConnection",
        items?:  Array< {
          __typename: "Vendor",
          id: string,
          userID: string,
          venueID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          parentUniversity?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentVenue?:  {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items?:  Array< {
          __typename: "Report",
          id: string,
          owner?: string | null,
          venueID: string,
          reportUri?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateOrderItemSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateOrderItemSubscription = {
  onCreateOrderItem?:  {
    __typename: "OrderItem",
    id: string,
    owner?: string | null,
    orderID: string,
    foodID: string,
    price?: number | null,
    amount: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    food?:  {
      __typename: "Food",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      price?: number | null,
      active?: boolean | null,
      calories?: number | null,
      foodType?: FoodType | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      menus?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateOrderItemSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateOrderItemSubscription = {
  onUpdateOrderItem?:  {
    __typename: "OrderItem",
    id: string,
    owner?: string | null,
    orderID: string,
    foodID: string,
    price?: number | null,
    amount: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    food?:  {
      __typename: "Food",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      price?: number | null,
      active?: boolean | null,
      calories?: number | null,
      foodType?: FoodType | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      menus?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteOrderItemSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteOrderItemSubscription = {
  onDeleteOrderItem?:  {
    __typename: "OrderItem",
    id: string,
    owner?: string | null,
    orderID: string,
    foodID: string,
    price?: number | null,
    amount: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    food?:  {
      __typename: "Food",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      price?: number | null,
      active?: boolean | null,
      calories?: number | null,
      foodType?: FoodType | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      menus?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateUniversitySubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateUniversitySubscription = {
  onCreateUniversity?:  {
    __typename: "University",
    id: string,
    owner?: string | null,
    name?: string | null,
    description?: string | null,
    domain?: string | null,
    cbordUniversity?: string | null,
    cbordEnabled?: boolean | null,
    utcOffset?: number | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    businesses?:  {
      __typename: "ModelBusinessConnection",
      items?:  Array< {
        __typename: "Business",
        id: string,
        universityID: string,
        providerID: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        locationOptionOnSite?: boolean | null,
        locationOptionVisit?: boolean | null,
        shortLocation?: string | null,
        telephone?: string | null,
        email?: string | null,
        links?: Array< string | null > | null,
        status?: BusinessStatus | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        categories?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        provider?:  {
          __typename: "Provider",
          id: string,
          userID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          schoolMajor?: string | null,
          schoolYear?: string | null,
          schoolOrganizations?: Array< string | null > | null,
          links?: Array< string | null > | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    venues?:  {
      __typename: "ModelVenueConnection",
      items?:  Array< {
        __typename: "Venue",
        id: string,
        universityID: string,
        owner?: string | null,
        name?: string | null,
        businessName?: string | null,
        description?: string | null,
        status?: VendorStatus | null,
        type?: EventType | null,
        categoty?: VenueCategory | null,
        buffetPrice?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        payInApp?: boolean | null,
        payInPerson?: boolean | null,
        payWithCard?: boolean | null,
        payWithCash?: boolean | null,
        payWithStudentID?: boolean | null,
        payWithCurrency?: boolean | null,
        currency?: string | null,
        dineInAvailable?: boolean | null,
        reservationAvailable?: boolean | null,
        pickUpAvailable?: boolean | null,
        deliveryAvailable?: boolean | null,
        timeSlotsDineInCapacity?: number | null,
        timeSlotsPickUpCapacity?: number | null,
        timeSlotsDeliveryCapacity?: number | null,
        timeslotsIgnore?: boolean | null,
        timeslotsInterval?: number | null,
        timeSlotsStatus?: TimeSlotsStatus | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        vendors?:  {
          __typename: "ModelVendorConnection",
          items?:  Array< {
            __typename: "Vendor",
            id: string,
            userID: string,
            venueID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        reports?:  {
          __typename: "ModelReportConnection",
          items?:  Array< {
            __typename: "Report",
            id: string,
            owner?: string | null,
            venueID: string,
            reportUri?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnUpdateUniversitySubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateUniversitySubscription = {
  onUpdateUniversity?:  {
    __typename: "University",
    id: string,
    owner?: string | null,
    name?: string | null,
    description?: string | null,
    domain?: string | null,
    cbordUniversity?: string | null,
    cbordEnabled?: boolean | null,
    utcOffset?: number | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    businesses?:  {
      __typename: "ModelBusinessConnection",
      items?:  Array< {
        __typename: "Business",
        id: string,
        universityID: string,
        providerID: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        locationOptionOnSite?: boolean | null,
        locationOptionVisit?: boolean | null,
        shortLocation?: string | null,
        telephone?: string | null,
        email?: string | null,
        links?: Array< string | null > | null,
        status?: BusinessStatus | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        categories?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        provider?:  {
          __typename: "Provider",
          id: string,
          userID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          schoolMajor?: string | null,
          schoolYear?: string | null,
          schoolOrganizations?: Array< string | null > | null,
          links?: Array< string | null > | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    venues?:  {
      __typename: "ModelVenueConnection",
      items?:  Array< {
        __typename: "Venue",
        id: string,
        universityID: string,
        owner?: string | null,
        name?: string | null,
        businessName?: string | null,
        description?: string | null,
        status?: VendorStatus | null,
        type?: EventType | null,
        categoty?: VenueCategory | null,
        buffetPrice?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        payInApp?: boolean | null,
        payInPerson?: boolean | null,
        payWithCard?: boolean | null,
        payWithCash?: boolean | null,
        payWithStudentID?: boolean | null,
        payWithCurrency?: boolean | null,
        currency?: string | null,
        dineInAvailable?: boolean | null,
        reservationAvailable?: boolean | null,
        pickUpAvailable?: boolean | null,
        deliveryAvailable?: boolean | null,
        timeSlotsDineInCapacity?: number | null,
        timeSlotsPickUpCapacity?: number | null,
        timeSlotsDeliveryCapacity?: number | null,
        timeslotsIgnore?: boolean | null,
        timeslotsInterval?: number | null,
        timeSlotsStatus?: TimeSlotsStatus | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        vendors?:  {
          __typename: "ModelVendorConnection",
          items?:  Array< {
            __typename: "Vendor",
            id: string,
            userID: string,
            venueID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        reports?:  {
          __typename: "ModelReportConnection",
          items?:  Array< {
            __typename: "Report",
            id: string,
            owner?: string | null,
            venueID: string,
            reportUri?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnDeleteUniversitySubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteUniversitySubscription = {
  onDeleteUniversity?:  {
    __typename: "University",
    id: string,
    owner?: string | null,
    name?: string | null,
    description?: string | null,
    domain?: string | null,
    cbordUniversity?: string | null,
    cbordEnabled?: boolean | null,
    utcOffset?: number | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    businesses?:  {
      __typename: "ModelBusinessConnection",
      items?:  Array< {
        __typename: "Business",
        id: string,
        universityID: string,
        providerID: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        locationOptionOnSite?: boolean | null,
        locationOptionVisit?: boolean | null,
        shortLocation?: string | null,
        telephone?: string | null,
        email?: string | null,
        links?: Array< string | null > | null,
        status?: BusinessStatus | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        categories?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        provider?:  {
          __typename: "Provider",
          id: string,
          userID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          schoolMajor?: string | null,
          schoolYear?: string | null,
          schoolOrganizations?: Array< string | null > | null,
          links?: Array< string | null > | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    venues?:  {
      __typename: "ModelVenueConnection",
      items?:  Array< {
        __typename: "Venue",
        id: string,
        universityID: string,
        owner?: string | null,
        name?: string | null,
        businessName?: string | null,
        description?: string | null,
        status?: VendorStatus | null,
        type?: EventType | null,
        categoty?: VenueCategory | null,
        buffetPrice?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        payInApp?: boolean | null,
        payInPerson?: boolean | null,
        payWithCard?: boolean | null,
        payWithCash?: boolean | null,
        payWithStudentID?: boolean | null,
        payWithCurrency?: boolean | null,
        currency?: string | null,
        dineInAvailable?: boolean | null,
        reservationAvailable?: boolean | null,
        pickUpAvailable?: boolean | null,
        deliveryAvailable?: boolean | null,
        timeSlotsDineInCapacity?: number | null,
        timeSlotsPickUpCapacity?: number | null,
        timeSlotsDeliveryCapacity?: number | null,
        timeslotsIgnore?: boolean | null,
        timeslotsInterval?: number | null,
        timeSlotsStatus?: TimeSlotsStatus | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        vendors?:  {
          __typename: "ModelVendorConnection",
          items?:  Array< {
            __typename: "Vendor",
            id: string,
            userID: string,
            venueID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        reports?:  {
          __typename: "ModelReportConnection",
          items?:  Array< {
            __typename: "Report",
            id: string,
            owner?: string | null,
            venueID: string,
            reportUri?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnCreateBusinessSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateBusinessSubscription = {
  onCreateBusiness?:  {
    __typename: "Business",
    id: string,
    universityID: string,
    providerID: string,
    owner?: string | null,
    name?: string | null,
    description?: string | null,
    locationOptionOnSite?: boolean | null,
    locationOptionVisit?: boolean | null,
    shortLocation?: string | null,
    telephone?: string | null,
    email?: string | null,
    links?: Array< string | null > | null,
    status?: BusinessStatus | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    categories?: Array< string | null > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    provider?:  {
      __typename: "Provider",
      id: string,
      userID: string,
      universityID: string,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      schoolMajor?: string | null,
      schoolYear?: string | null,
      schoolOrganizations?: Array< string | null > | null,
      links?: Array< string | null > | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: AccountStatus | null,
      invitationalEmail?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    university?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateBusinessSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateBusinessSubscription = {
  onUpdateBusiness?:  {
    __typename: "Business",
    id: string,
    universityID: string,
    providerID: string,
    owner?: string | null,
    name?: string | null,
    description?: string | null,
    locationOptionOnSite?: boolean | null,
    locationOptionVisit?: boolean | null,
    shortLocation?: string | null,
    telephone?: string | null,
    email?: string | null,
    links?: Array< string | null > | null,
    status?: BusinessStatus | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    categories?: Array< string | null > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    provider?:  {
      __typename: "Provider",
      id: string,
      userID: string,
      universityID: string,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      schoolMajor?: string | null,
      schoolYear?: string | null,
      schoolOrganizations?: Array< string | null > | null,
      links?: Array< string | null > | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: AccountStatus | null,
      invitationalEmail?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    university?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteBusinessSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteBusinessSubscription = {
  onDeleteBusiness?:  {
    __typename: "Business",
    id: string,
    universityID: string,
    providerID: string,
    owner?: string | null,
    name?: string | null,
    description?: string | null,
    locationOptionOnSite?: boolean | null,
    locationOptionVisit?: boolean | null,
    shortLocation?: string | null,
    telephone?: string | null,
    email?: string | null,
    links?: Array< string | null > | null,
    status?: BusinessStatus | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    categories?: Array< string | null > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    provider?:  {
      __typename: "Provider",
      id: string,
      userID: string,
      universityID: string,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      schoolMajor?: string | null,
      schoolYear?: string | null,
      schoolOrganizations?: Array< string | null > | null,
      links?: Array< string | null > | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: AccountStatus | null,
      invitationalEmail?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    university?:  {
      __typename: "University",
      id: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      domain?: string | null,
      cbordUniversity?: string | null,
      cbordEnabled?: boolean | null,
      utcOffset?: number | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        items?:  Array< {
          __typename: "Business",
          id: string,
          universityID: string,
          providerID: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          locationOptionOnSite?: boolean | null,
          locationOptionVisit?: boolean | null,
          shortLocation?: string | null,
          telephone?: string | null,
          email?: string | null,
          links?: Array< string | null > | null,
          status?: BusinessStatus | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          categories?: Array< string | null > | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          provider?:  {
            __typename: "Provider",
            id: string,
            userID: string,
            universityID: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            schoolMajor?: string | null,
            schoolYear?: string | null,
            schoolOrganizations?: Array< string | null > | null,
            links?: Array< string | null > | null,
            image_url?: string | null,
            status?: AccountStatus | null,
            invitationalEmail?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      venues?:  {
        __typename: "ModelVenueConnection",
        items?:  Array< {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateVenueSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateVenueSubscription = {
  onCreateVenue?:  {
    __typename: "Venue",
    id: string,
    universityID: string,
    owner?: string | null,
    name?: string | null,
    businessName?: string | null,
    description?: string | null,
    status?: VendorStatus | null,
    type?: EventType | null,
    categoty?: VenueCategory | null,
    buffetPrice?: number | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    payInApp?: boolean | null,
    payInPerson?: boolean | null,
    payWithCard?: boolean | null,
    payWithCash?: boolean | null,
    payWithStudentID?: boolean | null,
    payWithCurrency?: boolean | null,
    currency?: string | null,
    dineInAvailable?: boolean | null,
    reservationAvailable?: boolean | null,
    pickUpAvailable?: boolean | null,
    deliveryAvailable?: boolean | null,
    timeSlotsDineInCapacity?: number | null,
    timeSlotsPickUpCapacity?: number | null,
    timeSlotsDeliveryCapacity?: number | null,
    timeslotsIgnore?: boolean | null,
    timeslotsInterval?: number | null,
    timeSlotsStatus?: TimeSlotsStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    vendors?:  {
      __typename: "ModelVendorConnection",
      items?:  Array< {
        __typename: "Vendor",
        id: string,
        userID: string,
        venueID: string,
        universityID: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: AccountStatus | null,
        invitationalEmail?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        parentUniversity?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        parentVenue?:  {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items?:  Array< {
        __typename: "Event",
        id: string,
        owner?: string | null,
        venueID: string,
        name?: string | null,
        description?: string | null,
        active?: boolean | null,
        recurrenceFrequency?: RecurrenceFrequency | null,
        recurrenceInterval?: number | null,
        recurrenceCount?: number | null,
        recurrentDays?: Array< Weekday | null > | null,
        startDate?: string | null,
        endDate?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        duration?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        exceptions?:  {
          __typename: "ModelEventExceptionConnection",
          items?:  Array< {
            __typename: "EventException",
            id: string,
            owner?: string | null,
            eventID: string,
            name?: string | null,
            description?: string | null,
            type?: EventExceptionType | null,
            date?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        menus?:  {
          __typename: "ModelEventMenuConnection",
          items?:  Array< {
            __typename: "EventMenu",
            id: string,
            owner?: string | null,
            eventID: string,
            menuID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        timeSlots?:  {
          __typename: "ModelTimeSlotConnection",
          items?:  Array< {
            __typename: "TimeSlot",
            id: string,
            owner?: string | null,
            venueID?: string | null,
            eventID?: string | null,
            type?: TimeSlotType | null,
            capacity?: number | null,
            position?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    reports?:  {
      __typename: "ModelReportConnection",
      items?:  Array< {
        __typename: "Report",
        id: string,
        owner?: string | null,
        venueID: string,
        reportUri?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnUpdateVenueSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateVenueSubscription = {
  onUpdateVenue?:  {
    __typename: "Venue",
    id: string,
    universityID: string,
    owner?: string | null,
    name?: string | null,
    businessName?: string | null,
    description?: string | null,
    status?: VendorStatus | null,
    type?: EventType | null,
    categoty?: VenueCategory | null,
    buffetPrice?: number | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    payInApp?: boolean | null,
    payInPerson?: boolean | null,
    payWithCard?: boolean | null,
    payWithCash?: boolean | null,
    payWithStudentID?: boolean | null,
    payWithCurrency?: boolean | null,
    currency?: string | null,
    dineInAvailable?: boolean | null,
    reservationAvailable?: boolean | null,
    pickUpAvailable?: boolean | null,
    deliveryAvailable?: boolean | null,
    timeSlotsDineInCapacity?: number | null,
    timeSlotsPickUpCapacity?: number | null,
    timeSlotsDeliveryCapacity?: number | null,
    timeslotsIgnore?: boolean | null,
    timeslotsInterval?: number | null,
    timeSlotsStatus?: TimeSlotsStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    vendors?:  {
      __typename: "ModelVendorConnection",
      items?:  Array< {
        __typename: "Vendor",
        id: string,
        userID: string,
        venueID: string,
        universityID: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: AccountStatus | null,
        invitationalEmail?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        parentUniversity?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        parentVenue?:  {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items?:  Array< {
        __typename: "Event",
        id: string,
        owner?: string | null,
        venueID: string,
        name?: string | null,
        description?: string | null,
        active?: boolean | null,
        recurrenceFrequency?: RecurrenceFrequency | null,
        recurrenceInterval?: number | null,
        recurrenceCount?: number | null,
        recurrentDays?: Array< Weekday | null > | null,
        startDate?: string | null,
        endDate?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        duration?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        exceptions?:  {
          __typename: "ModelEventExceptionConnection",
          items?:  Array< {
            __typename: "EventException",
            id: string,
            owner?: string | null,
            eventID: string,
            name?: string | null,
            description?: string | null,
            type?: EventExceptionType | null,
            date?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        menus?:  {
          __typename: "ModelEventMenuConnection",
          items?:  Array< {
            __typename: "EventMenu",
            id: string,
            owner?: string | null,
            eventID: string,
            menuID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        timeSlots?:  {
          __typename: "ModelTimeSlotConnection",
          items?:  Array< {
            __typename: "TimeSlot",
            id: string,
            owner?: string | null,
            venueID?: string | null,
            eventID?: string | null,
            type?: TimeSlotType | null,
            capacity?: number | null,
            position?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    reports?:  {
      __typename: "ModelReportConnection",
      items?:  Array< {
        __typename: "Report",
        id: string,
        owner?: string | null,
        venueID: string,
        reportUri?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnDeleteVenueSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteVenueSubscription = {
  onDeleteVenue?:  {
    __typename: "Venue",
    id: string,
    universityID: string,
    owner?: string | null,
    name?: string | null,
    businessName?: string | null,
    description?: string | null,
    status?: VendorStatus | null,
    type?: EventType | null,
    categoty?: VenueCategory | null,
    buffetPrice?: number | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    payInApp?: boolean | null,
    payInPerson?: boolean | null,
    payWithCard?: boolean | null,
    payWithCash?: boolean | null,
    payWithStudentID?: boolean | null,
    payWithCurrency?: boolean | null,
    currency?: string | null,
    dineInAvailable?: boolean | null,
    reservationAvailable?: boolean | null,
    pickUpAvailable?: boolean | null,
    deliveryAvailable?: boolean | null,
    timeSlotsDineInCapacity?: number | null,
    timeSlotsPickUpCapacity?: number | null,
    timeSlotsDeliveryCapacity?: number | null,
    timeslotsIgnore?: boolean | null,
    timeslotsInterval?: number | null,
    timeSlotsStatus?: TimeSlotsStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    vendors?:  {
      __typename: "ModelVendorConnection",
      items?:  Array< {
        __typename: "Vendor",
        id: string,
        userID: string,
        venueID: string,
        universityID: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: AccountStatus | null,
        invitationalEmail?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        parentUniversity?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        parentVenue?:  {
          __typename: "Venue",
          id: string,
          universityID: string,
          owner?: string | null,
          name?: string | null,
          businessName?: string | null,
          description?: string | null,
          status?: VendorStatus | null,
          type?: EventType | null,
          categoty?: VenueCategory | null,
          buffetPrice?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          payInApp?: boolean | null,
          payInPerson?: boolean | null,
          payWithCard?: boolean | null,
          payWithCash?: boolean | null,
          payWithStudentID?: boolean | null,
          payWithCurrency?: boolean | null,
          currency?: string | null,
          dineInAvailable?: boolean | null,
          reservationAvailable?: boolean | null,
          pickUpAvailable?: boolean | null,
          deliveryAvailable?: boolean | null,
          timeSlotsDineInCapacity?: number | null,
          timeSlotsPickUpCapacity?: number | null,
          timeSlotsDeliveryCapacity?: number | null,
          timeslotsIgnore?: boolean | null,
          timeslotsInterval?: number | null,
          timeSlotsStatus?: TimeSlotsStatus | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          vendors?:  {
            __typename: "ModelVendorConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items?:  Array< {
        __typename: "Event",
        id: string,
        owner?: string | null,
        venueID: string,
        name?: string | null,
        description?: string | null,
        active?: boolean | null,
        recurrenceFrequency?: RecurrenceFrequency | null,
        recurrenceInterval?: number | null,
        recurrenceCount?: number | null,
        recurrentDays?: Array< Weekday | null > | null,
        startDate?: string | null,
        endDate?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        duration?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        exceptions?:  {
          __typename: "ModelEventExceptionConnection",
          items?:  Array< {
            __typename: "EventException",
            id: string,
            owner?: string | null,
            eventID: string,
            name?: string | null,
            description?: string | null,
            type?: EventExceptionType | null,
            date?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        menus?:  {
          __typename: "ModelEventMenuConnection",
          items?:  Array< {
            __typename: "EventMenu",
            id: string,
            owner?: string | null,
            eventID: string,
            menuID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        timeSlots?:  {
          __typename: "ModelTimeSlotConnection",
          items?:  Array< {
            __typename: "TimeSlot",
            id: string,
            owner?: string | null,
            venueID?: string | null,
            eventID?: string | null,
            type?: TimeSlotType | null,
            capacity?: number | null,
            position?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    reports?:  {
      __typename: "ModelReportConnection",
      items?:  Array< {
        __typename: "Report",
        id: string,
        owner?: string | null,
        venueID: string,
        reportUri?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnCreateEventSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateEventSubscription = {
  onCreateEvent?:  {
    __typename: "Event",
    id: string,
    owner?: string | null,
    venueID: string,
    name?: string | null,
    description?: string | null,
    active?: boolean | null,
    recurrenceFrequency?: RecurrenceFrequency | null,
    recurrenceInterval?: number | null,
    recurrenceCount?: number | null,
    recurrentDays?: Array< Weekday | null > | null,
    startDate?: string | null,
    endDate?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    duration?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    exceptions?:  {
      __typename: "ModelEventExceptionConnection",
      items?:  Array< {
        __typename: "EventException",
        id: string,
        owner?: string | null,
        eventID: string,
        name?: string | null,
        description?: string | null,
        type?: EventExceptionType | null,
        date?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        menus?:  {
          __typename: "ModelEventMenuConnection",
          items?:  Array< {
            __typename: "EventMenu",
            id: string,
            owner?: string | null,
            eventID: string,
            menuID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    menus?:  {
      __typename: "ModelEventMenuConnection",
      items?:  Array< {
        __typename: "EventMenu",
        id: string,
        owner?: string | null,
        eventID: string,
        menuID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        event:  {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    timeSlots?:  {
      __typename: "ModelTimeSlotConnection",
      items?:  Array< {
        __typename: "TimeSlot",
        id: string,
        owner?: string | null,
        venueID?: string | null,
        eventID?: string | null,
        type?: TimeSlotType | null,
        capacity?: number | null,
        position?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnUpdateEventSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateEventSubscription = {
  onUpdateEvent?:  {
    __typename: "Event",
    id: string,
    owner?: string | null,
    venueID: string,
    name?: string | null,
    description?: string | null,
    active?: boolean | null,
    recurrenceFrequency?: RecurrenceFrequency | null,
    recurrenceInterval?: number | null,
    recurrenceCount?: number | null,
    recurrentDays?: Array< Weekday | null > | null,
    startDate?: string | null,
    endDate?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    duration?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    exceptions?:  {
      __typename: "ModelEventExceptionConnection",
      items?:  Array< {
        __typename: "EventException",
        id: string,
        owner?: string | null,
        eventID: string,
        name?: string | null,
        description?: string | null,
        type?: EventExceptionType | null,
        date?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        menus?:  {
          __typename: "ModelEventMenuConnection",
          items?:  Array< {
            __typename: "EventMenu",
            id: string,
            owner?: string | null,
            eventID: string,
            menuID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    menus?:  {
      __typename: "ModelEventMenuConnection",
      items?:  Array< {
        __typename: "EventMenu",
        id: string,
        owner?: string | null,
        eventID: string,
        menuID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        event:  {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    timeSlots?:  {
      __typename: "ModelTimeSlotConnection",
      items?:  Array< {
        __typename: "TimeSlot",
        id: string,
        owner?: string | null,
        venueID?: string | null,
        eventID?: string | null,
        type?: TimeSlotType | null,
        capacity?: number | null,
        position?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnDeleteEventSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteEventSubscription = {
  onDeleteEvent?:  {
    __typename: "Event",
    id: string,
    owner?: string | null,
    venueID: string,
    name?: string | null,
    description?: string | null,
    active?: boolean | null,
    recurrenceFrequency?: RecurrenceFrequency | null,
    recurrenceInterval?: number | null,
    recurrenceCount?: number | null,
    recurrentDays?: Array< Weekday | null > | null,
    startDate?: string | null,
    endDate?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    duration?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    exceptions?:  {
      __typename: "ModelEventExceptionConnection",
      items?:  Array< {
        __typename: "EventException",
        id: string,
        owner?: string | null,
        eventID: string,
        name?: string | null,
        description?: string | null,
        type?: EventExceptionType | null,
        date?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        menus?:  {
          __typename: "ModelEventMenuConnection",
          items?:  Array< {
            __typename: "EventMenu",
            id: string,
            owner?: string | null,
            eventID: string,
            menuID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    menus?:  {
      __typename: "ModelEventMenuConnection",
      items?:  Array< {
        __typename: "EventMenu",
        id: string,
        owner?: string | null,
        eventID: string,
        menuID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        event:  {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    timeSlots?:  {
      __typename: "ModelTimeSlotConnection",
      items?:  Array< {
        __typename: "TimeSlot",
        id: string,
        owner?: string | null,
        venueID?: string | null,
        eventID?: string | null,
        type?: TimeSlotType | null,
        capacity?: number | null,
        position?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnCreateEventExceptionSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateEventExceptionSubscription = {
  onCreateEventException?:  {
    __typename: "EventException",
    id: string,
    owner?: string | null,
    eventID: string,
    name?: string | null,
    description?: string | null,
    type?: EventExceptionType | null,
    date?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    menus?:  {
      __typename: "ModelEventMenuConnection",
      items?:  Array< {
        __typename: "EventMenu",
        id: string,
        owner?: string | null,
        eventID: string,
        menuID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        event:  {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnUpdateEventExceptionSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateEventExceptionSubscription = {
  onUpdateEventException?:  {
    __typename: "EventException",
    id: string,
    owner?: string | null,
    eventID: string,
    name?: string | null,
    description?: string | null,
    type?: EventExceptionType | null,
    date?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    menus?:  {
      __typename: "ModelEventMenuConnection",
      items?:  Array< {
        __typename: "EventMenu",
        id: string,
        owner?: string | null,
        eventID: string,
        menuID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        event:  {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnDeleteEventExceptionSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteEventExceptionSubscription = {
  onDeleteEventException?:  {
    __typename: "EventException",
    id: string,
    owner?: string | null,
    eventID: string,
    name?: string | null,
    description?: string | null,
    type?: EventExceptionType | null,
    date?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    menus?:  {
      __typename: "ModelEventMenuConnection",
      items?:  Array< {
        __typename: "EventMenu",
        id: string,
        owner?: string | null,
        eventID: string,
        menuID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        event:  {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnCreateEventMenuSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateEventMenuSubscription = {
  onCreateEventMenu?:  {
    __typename: "EventMenu",
    id: string,
    owner?: string | null,
    eventID: string,
    menuID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    event:  {
      __typename: "Event",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      active?: boolean | null,
      recurrenceFrequency?: RecurrenceFrequency | null,
      recurrenceInterval?: number | null,
      recurrenceCount?: number | null,
      recurrentDays?: Array< Weekday | null > | null,
      startDate?: string | null,
      endDate?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      duration?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      exceptions?:  {
        __typename: "ModelEventExceptionConnection",
        items?:  Array< {
          __typename: "EventException",
          id: string,
          owner?: string | null,
          eventID: string,
          name?: string | null,
          description?: string | null,
          type?: EventExceptionType | null,
          date?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      menus?:  {
        __typename: "ModelEventMenuConnection",
        items?:  Array< {
          __typename: "EventMenu",
          id: string,
          owner?: string | null,
          eventID: string,
          menuID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          event:  {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeSlots?:  {
        __typename: "ModelTimeSlotConnection",
        items?:  Array< {
          __typename: "TimeSlot",
          id: string,
          owner?: string | null,
          venueID?: string | null,
          eventID?: string | null,
          type?: TimeSlotType | null,
          capacity?: number | null,
          position?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
    menu:  {
      __typename: "Menu",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      active?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventMenuConnection",
        items?:  Array< {
          __typename: "EventMenu",
          id: string,
          owner?: string | null,
          eventID: string,
          menuID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          event:  {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      foods?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
  } | null,
};

export type OnUpdateEventMenuSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateEventMenuSubscription = {
  onUpdateEventMenu?:  {
    __typename: "EventMenu",
    id: string,
    owner?: string | null,
    eventID: string,
    menuID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    event:  {
      __typename: "Event",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      active?: boolean | null,
      recurrenceFrequency?: RecurrenceFrequency | null,
      recurrenceInterval?: number | null,
      recurrenceCount?: number | null,
      recurrentDays?: Array< Weekday | null > | null,
      startDate?: string | null,
      endDate?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      duration?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      exceptions?:  {
        __typename: "ModelEventExceptionConnection",
        items?:  Array< {
          __typename: "EventException",
          id: string,
          owner?: string | null,
          eventID: string,
          name?: string | null,
          description?: string | null,
          type?: EventExceptionType | null,
          date?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      menus?:  {
        __typename: "ModelEventMenuConnection",
        items?:  Array< {
          __typename: "EventMenu",
          id: string,
          owner?: string | null,
          eventID: string,
          menuID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          event:  {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeSlots?:  {
        __typename: "ModelTimeSlotConnection",
        items?:  Array< {
          __typename: "TimeSlot",
          id: string,
          owner?: string | null,
          venueID?: string | null,
          eventID?: string | null,
          type?: TimeSlotType | null,
          capacity?: number | null,
          position?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
    menu:  {
      __typename: "Menu",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      active?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventMenuConnection",
        items?:  Array< {
          __typename: "EventMenu",
          id: string,
          owner?: string | null,
          eventID: string,
          menuID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          event:  {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      foods?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
  } | null,
};

export type OnDeleteEventMenuSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteEventMenuSubscription = {
  onDeleteEventMenu?:  {
    __typename: "EventMenu",
    id: string,
    owner?: string | null,
    eventID: string,
    menuID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    event:  {
      __typename: "Event",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      active?: boolean | null,
      recurrenceFrequency?: RecurrenceFrequency | null,
      recurrenceInterval?: number | null,
      recurrenceCount?: number | null,
      recurrentDays?: Array< Weekday | null > | null,
      startDate?: string | null,
      endDate?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      duration?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      exceptions?:  {
        __typename: "ModelEventExceptionConnection",
        items?:  Array< {
          __typename: "EventException",
          id: string,
          owner?: string | null,
          eventID: string,
          name?: string | null,
          description?: string | null,
          type?: EventExceptionType | null,
          date?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      menus?:  {
        __typename: "ModelEventMenuConnection",
        items?:  Array< {
          __typename: "EventMenu",
          id: string,
          owner?: string | null,
          eventID: string,
          menuID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          event:  {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeSlots?:  {
        __typename: "ModelTimeSlotConnection",
        items?:  Array< {
          __typename: "TimeSlot",
          id: string,
          owner?: string | null,
          venueID?: string | null,
          eventID?: string | null,
          type?: TimeSlotType | null,
          capacity?: number | null,
          position?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
    menu:  {
      __typename: "Menu",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      active?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventMenuConnection",
        items?:  Array< {
          __typename: "EventMenu",
          id: string,
          owner?: string | null,
          eventID: string,
          menuID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          event:  {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      foods?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
  } | null,
};

export type OnCreateMenuSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateMenuSubscription = {
  onCreateMenu?:  {
    __typename: "Menu",
    id: string,
    owner?: string | null,
    venueID: string,
    name?: string | null,
    description?: string | null,
    active?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    events?:  {
      __typename: "ModelEventMenuConnection",
      items?:  Array< {
        __typename: "EventMenu",
        id: string,
        owner?: string | null,
        eventID: string,
        menuID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        event:  {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    foods?:  {
      __typename: "ModelMenuFoodConnection",
      items?:  Array< {
        __typename: "MenuFood",
        id: string,
        owner?: string | null,
        menuID: string,
        foodID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        food:  {
          __typename: "Food",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          active?: boolean | null,
          calories?: number | null,
          foodType?: FoodType | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnUpdateMenuSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateMenuSubscription = {
  onUpdateMenu?:  {
    __typename: "Menu",
    id: string,
    owner?: string | null,
    venueID: string,
    name?: string | null,
    description?: string | null,
    active?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    events?:  {
      __typename: "ModelEventMenuConnection",
      items?:  Array< {
        __typename: "EventMenu",
        id: string,
        owner?: string | null,
        eventID: string,
        menuID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        event:  {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    foods?:  {
      __typename: "ModelMenuFoodConnection",
      items?:  Array< {
        __typename: "MenuFood",
        id: string,
        owner?: string | null,
        menuID: string,
        foodID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        food:  {
          __typename: "Food",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          active?: boolean | null,
          calories?: number | null,
          foodType?: FoodType | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnDeleteMenuSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteMenuSubscription = {
  onDeleteMenu?:  {
    __typename: "Menu",
    id: string,
    owner?: string | null,
    venueID: string,
    name?: string | null,
    description?: string | null,
    active?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    events?:  {
      __typename: "ModelEventMenuConnection",
      items?:  Array< {
        __typename: "EventMenu",
        id: string,
        owner?: string | null,
        eventID: string,
        menuID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        event:  {
          __typename: "Event",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          recurrenceFrequency?: RecurrenceFrequency | null,
          recurrenceInterval?: number | null,
          recurrenceCount?: number | null,
          recurrentDays?: Array< Weekday | null > | null,
          startDate?: string | null,
          endDate?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          duration?: number | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          exceptions?:  {
            __typename: "ModelEventExceptionConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          menus?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          timeSlots?:  {
            __typename: "ModelTimeSlotConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    foods?:  {
      __typename: "ModelMenuFoodConnection",
      items?:  Array< {
        __typename: "MenuFood",
        id: string,
        owner?: string | null,
        menuID: string,
        foodID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        food:  {
          __typename: "Food",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          active?: boolean | null,
          calories?: number | null,
          foodType?: FoodType | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnCreateMenuFoodSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateMenuFoodSubscription = {
  onCreateMenuFood?:  {
    __typename: "MenuFood",
    id: string,
    owner?: string | null,
    menuID: string,
    foodID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    menu:  {
      __typename: "Menu",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      active?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventMenuConnection",
        items?:  Array< {
          __typename: "EventMenu",
          id: string,
          owner?: string | null,
          eventID: string,
          menuID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          event:  {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      foods?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
    food:  {
      __typename: "Food",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      price?: number | null,
      active?: boolean | null,
      calories?: number | null,
      foodType?: FoodType | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      menus?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
  } | null,
};

export type OnUpdateMenuFoodSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateMenuFoodSubscription = {
  onUpdateMenuFood?:  {
    __typename: "MenuFood",
    id: string,
    owner?: string | null,
    menuID: string,
    foodID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    menu:  {
      __typename: "Menu",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      active?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventMenuConnection",
        items?:  Array< {
          __typename: "EventMenu",
          id: string,
          owner?: string | null,
          eventID: string,
          menuID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          event:  {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      foods?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
    food:  {
      __typename: "Food",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      price?: number | null,
      active?: boolean | null,
      calories?: number | null,
      foodType?: FoodType | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      menus?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
  } | null,
};

export type OnDeleteMenuFoodSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteMenuFoodSubscription = {
  onDeleteMenuFood?:  {
    __typename: "MenuFood",
    id: string,
    owner?: string | null,
    menuID: string,
    foodID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    menu:  {
      __typename: "Menu",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      active?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventMenuConnection",
        items?:  Array< {
          __typename: "EventMenu",
          id: string,
          owner?: string | null,
          eventID: string,
          menuID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          event:  {
            __typename: "Event",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            recurrenceFrequency?: RecurrenceFrequency | null,
            recurrenceInterval?: number | null,
            recurrenceCount?: number | null,
            recurrentDays?: Array< Weekday | null > | null,
            startDate?: string | null,
            endDate?: string | null,
            startTime?: string | null,
            endTime?: string | null,
            duration?: number | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      foods?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
    food:  {
      __typename: "Food",
      id: string,
      owner?: string | null,
      venueID: string,
      name?: string | null,
      description?: string | null,
      price?: number | null,
      active?: boolean | null,
      calories?: number | null,
      foodType?: FoodType | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      menus?:  {
        __typename: "ModelMenuFoodConnection",
        items?:  Array< {
          __typename: "MenuFood",
          id: string,
          owner?: string | null,
          menuID: string,
          foodID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menu:  {
            __typename: "Menu",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            active?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
          food:  {
            __typename: "Food",
            id: string,
            owner?: string | null,
            venueID: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            active?: boolean | null,
            calories?: number | null,
            foodType?: FoodType | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
  } | null,
};

export type OnCreateFoodSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateFoodSubscription = {
  onCreateFood?:  {
    __typename: "Food",
    id: string,
    owner?: string | null,
    venueID: string,
    name?: string | null,
    description?: string | null,
    price?: number | null,
    active?: boolean | null,
    calories?: number | null,
    foodType?: FoodType | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    menus?:  {
      __typename: "ModelMenuFoodConnection",
      items?:  Array< {
        __typename: "MenuFood",
        id: string,
        owner?: string | null,
        menuID: string,
        foodID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        food:  {
          __typename: "Food",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          active?: boolean | null,
          calories?: number | null,
          foodType?: FoodType | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnUpdateFoodSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateFoodSubscription = {
  onUpdateFood?:  {
    __typename: "Food",
    id: string,
    owner?: string | null,
    venueID: string,
    name?: string | null,
    description?: string | null,
    price?: number | null,
    active?: boolean | null,
    calories?: number | null,
    foodType?: FoodType | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    menus?:  {
      __typename: "ModelMenuFoodConnection",
      items?:  Array< {
        __typename: "MenuFood",
        id: string,
        owner?: string | null,
        menuID: string,
        foodID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        food:  {
          __typename: "Food",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          active?: boolean | null,
          calories?: number | null,
          foodType?: FoodType | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnDeleteFoodSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteFoodSubscription = {
  onDeleteFood?:  {
    __typename: "Food",
    id: string,
    owner?: string | null,
    venueID: string,
    name?: string | null,
    description?: string | null,
    price?: number | null,
    active?: boolean | null,
    calories?: number | null,
    foodType?: FoodType | null,
    image_url?: string | null,
    image?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    menus?:  {
      __typename: "ModelMenuFoodConnection",
      items?:  Array< {
        __typename: "MenuFood",
        id: string,
        owner?: string | null,
        menuID: string,
        foodID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        menu:  {
          __typename: "Menu",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          active?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventMenuConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          foods?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
        food:  {
          __typename: "Food",
          id: string,
          owner?: string | null,
          venueID: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          active?: boolean | null,
          calories?: number | null,
          foodType?: FoodType | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          menus?:  {
            __typename: "ModelMenuFoodConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnCreateTimeSlotSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateTimeSlotSubscription = {
  onCreateTimeSlot?:  {
    __typename: "TimeSlot",
    id: string,
    owner?: string | null,
    venueID?: string | null,
    eventID?: string | null,
    type?: TimeSlotType | null,
    capacity?: number | null,
    position?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTimeSlotSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateTimeSlotSubscription = {
  onUpdateTimeSlot?:  {
    __typename: "TimeSlot",
    id: string,
    owner?: string | null,
    venueID?: string | null,
    eventID?: string | null,
    type?: TimeSlotType | null,
    capacity?: number | null,
    position?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTimeSlotSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteTimeSlotSubscription = {
  onDeleteTimeSlot?:  {
    __typename: "TimeSlot",
    id: string,
    owner?: string | null,
    venueID?: string | null,
    eventID?: string | null,
    type?: TimeSlotType | null,
    capacity?: number | null,
    position?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateReportSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateReportSubscription = {
  onCreateReport?:  {
    __typename: "Report",
    id: string,
    owner?: string | null,
    venueID: string,
    reportUri?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateReportSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateReportSubscription = {
  onUpdateReport?:  {
    __typename: "Report",
    id: string,
    owner?: string | null,
    venueID: string,
    reportUri?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteReportSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteReportSubscription = {
  onDeleteReport?:  {
    __typename: "Report",
    id: string,
    owner?: string | null,
    venueID: string,
    reportUri?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAvailabilitySubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateAvailabilitySubscription = {
  onCreateAvailability?:  {
    __typename: "Availability",
    id: string,
    owner?: string | null,
    businessID: string,
    name: string,
    description?: string | null,
    active: boolean,
    recurrentDays: Array< Weekday | null >,
    startDate: string,
    endDate?: string | null,
    startTime: string,
    duration: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    exceptions?:  {
      __typename: "ModelAvailabilityExceptionConnection",
      items?:  Array< {
        __typename: "AvailabilityException",
        id: string,
        owner?: string | null,
        availabilityID: string,
        name?: string | null,
        description?: string | null,
        type?: EventExceptionType | null,
        date: string,
        startTime: string,
        duration: number,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnUpdateAvailabilitySubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateAvailabilitySubscription = {
  onUpdateAvailability?:  {
    __typename: "Availability",
    id: string,
    owner?: string | null,
    businessID: string,
    name: string,
    description?: string | null,
    active: boolean,
    recurrentDays: Array< Weekday | null >,
    startDate: string,
    endDate?: string | null,
    startTime: string,
    duration: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    exceptions?:  {
      __typename: "ModelAvailabilityExceptionConnection",
      items?:  Array< {
        __typename: "AvailabilityException",
        id: string,
        owner?: string | null,
        availabilityID: string,
        name?: string | null,
        description?: string | null,
        type?: EventExceptionType | null,
        date: string,
        startTime: string,
        duration: number,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnDeleteAvailabilitySubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteAvailabilitySubscription = {
  onDeleteAvailability?:  {
    __typename: "Availability",
    id: string,
    owner?: string | null,
    businessID: string,
    name: string,
    description?: string | null,
    active: boolean,
    recurrentDays: Array< Weekday | null >,
    startDate: string,
    endDate?: string | null,
    startTime: string,
    duration: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    exceptions?:  {
      __typename: "ModelAvailabilityExceptionConnection",
      items?:  Array< {
        __typename: "AvailabilityException",
        id: string,
        owner?: string | null,
        availabilityID: string,
        name?: string | null,
        description?: string | null,
        type?: EventExceptionType | null,
        date: string,
        startTime: string,
        duration: number,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnCreateAvailabilityExceptionSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateAvailabilityExceptionSubscription = {
  onCreateAvailabilityException?:  {
    __typename: "AvailabilityException",
    id: string,
    owner?: string | null,
    availabilityID: string,
    name?: string | null,
    description?: string | null,
    type?: EventExceptionType | null,
    date: string,
    startTime: string,
    duration: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAvailabilityExceptionSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateAvailabilityExceptionSubscription = {
  onUpdateAvailabilityException?:  {
    __typename: "AvailabilityException",
    id: string,
    owner?: string | null,
    availabilityID: string,
    name?: string | null,
    description?: string | null,
    type?: EventExceptionType | null,
    date: string,
    startTime: string,
    duration: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAvailabilityExceptionSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteAvailabilityExceptionSubscription = {
  onDeleteAvailabilityException?:  {
    __typename: "AvailabilityException",
    id: string,
    owner?: string | null,
    availabilityID: string,
    name?: string | null,
    description?: string | null,
    type?: EventExceptionType | null,
    date: string,
    startTime: string,
    duration: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateServiceSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateServiceSubscription = {
  onCreateService?:  {
    __typename: "Service",
    id: string,
    owner?: string | null,
    businessID: string,
    name: string,
    description: string,
    duration: number,
    price: number,
    active: boolean,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    business?:  {
      __typename: "Business",
      id: string,
      universityID: string,
      providerID: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      locationOptionOnSite?: boolean | null,
      locationOptionVisit?: boolean | null,
      shortLocation?: string | null,
      telephone?: string | null,
      email?: string | null,
      links?: Array< string | null > | null,
      status?: BusinessStatus | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      categories?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      provider?:  {
        __typename: "Provider",
        id: string,
        userID: string,
        universityID: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        schoolMajor?: string | null,
        schoolYear?: string | null,
        schoolOrganizations?: Array< string | null > | null,
        links?: Array< string | null > | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: AccountStatus | null,
        invitationalEmail?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateServiceSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateServiceSubscription = {
  onUpdateService?:  {
    __typename: "Service",
    id: string,
    owner?: string | null,
    businessID: string,
    name: string,
    description: string,
    duration: number,
    price: number,
    active: boolean,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    business?:  {
      __typename: "Business",
      id: string,
      universityID: string,
      providerID: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      locationOptionOnSite?: boolean | null,
      locationOptionVisit?: boolean | null,
      shortLocation?: string | null,
      telephone?: string | null,
      email?: string | null,
      links?: Array< string | null > | null,
      status?: BusinessStatus | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      categories?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      provider?:  {
        __typename: "Provider",
        id: string,
        userID: string,
        universityID: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        schoolMajor?: string | null,
        schoolYear?: string | null,
        schoolOrganizations?: Array< string | null > | null,
        links?: Array< string | null > | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: AccountStatus | null,
        invitationalEmail?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteServiceSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteServiceSubscription = {
  onDeleteService?:  {
    __typename: "Service",
    id: string,
    owner?: string | null,
    businessID: string,
    name: string,
    description: string,
    duration: number,
    price: number,
    active: boolean,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    business?:  {
      __typename: "Business",
      id: string,
      universityID: string,
      providerID: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      locationOptionOnSite?: boolean | null,
      locationOptionVisit?: boolean | null,
      shortLocation?: string | null,
      telephone?: string | null,
      email?: string | null,
      links?: Array< string | null > | null,
      status?: BusinessStatus | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      categories?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      provider?:  {
        __typename: "Provider",
        id: string,
        userID: string,
        universityID: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        schoolMajor?: string | null,
        schoolYear?: string | null,
        schoolOrganizations?: Array< string | null > | null,
        links?: Array< string | null > | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: AccountStatus | null,
        invitationalEmail?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateCategorySubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateCategorySubscription = {
  onCreateCategory?:  {
    __typename: "Category",
    id: string,
    owner?: string | null,
    title: string,
    description: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCategorySubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateCategorySubscription = {
  onUpdateCategory?:  {
    __typename: "Category",
    id: string,
    owner?: string | null,
    title: string,
    description: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCategorySubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteCategorySubscription = {
  onDeleteCategory?:  {
    __typename: "Category",
    id: string,
    owner?: string | null,
    title: string,
    description: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateBookingSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateBookingSubscription = {
  onCreateBooking?:  {
    __typename: "Booking",
    id: string,
    owner?: string | null,
    businessID: string,
    customerID: string,
    serviceID: string,
    price?: number | null,
    status?: BookingStatus | null,
    createdAt?: string | null,
    chargeID?: string | null,
    note?: string | null,
    locationName?: string | null,
    date: string,
    time: string,
    duration: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null,
    business?:  {
      __typename: "Business",
      id: string,
      universityID: string,
      providerID: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      locationOptionOnSite?: boolean | null,
      locationOptionVisit?: boolean | null,
      shortLocation?: string | null,
      telephone?: string | null,
      email?: string | null,
      links?: Array< string | null > | null,
      status?: BusinessStatus | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      categories?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      provider?:  {
        __typename: "Provider",
        id: string,
        userID: string,
        universityID: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        schoolMajor?: string | null,
        schoolYear?: string | null,
        schoolOrganizations?: Array< string | null > | null,
        links?: Array< string | null > | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: AccountStatus | null,
        invitationalEmail?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null,
    service?:  {
      __typename: "Service",
      id: string,
      owner?: string | null,
      businessID: string,
      name: string,
      description: string,
      duration: number,
      price: number,
      active: boolean,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        universityID: string,
        providerID: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        locationOptionOnSite?: boolean | null,
        locationOptionVisit?: boolean | null,
        shortLocation?: string | null,
        telephone?: string | null,
        email?: string | null,
        links?: Array< string | null > | null,
        status?: BusinessStatus | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        categories?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        provider?:  {
          __typename: "Provider",
          id: string,
          userID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          schoolMajor?: string | null,
          schoolYear?: string | null,
          schoolOrganizations?: Array< string | null > | null,
          links?: Array< string | null > | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateBookingSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateBookingSubscription = {
  onUpdateBooking?:  {
    __typename: "Booking",
    id: string,
    owner?: string | null,
    businessID: string,
    customerID: string,
    serviceID: string,
    price?: number | null,
    status?: BookingStatus | null,
    createdAt?: string | null,
    chargeID?: string | null,
    note?: string | null,
    locationName?: string | null,
    date: string,
    time: string,
    duration: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null,
    business?:  {
      __typename: "Business",
      id: string,
      universityID: string,
      providerID: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      locationOptionOnSite?: boolean | null,
      locationOptionVisit?: boolean | null,
      shortLocation?: string | null,
      telephone?: string | null,
      email?: string | null,
      links?: Array< string | null > | null,
      status?: BusinessStatus | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      categories?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      provider?:  {
        __typename: "Provider",
        id: string,
        userID: string,
        universityID: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        schoolMajor?: string | null,
        schoolYear?: string | null,
        schoolOrganizations?: Array< string | null > | null,
        links?: Array< string | null > | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: AccountStatus | null,
        invitationalEmail?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null,
    service?:  {
      __typename: "Service",
      id: string,
      owner?: string | null,
      businessID: string,
      name: string,
      description: string,
      duration: number,
      price: number,
      active: boolean,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        universityID: string,
        providerID: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        locationOptionOnSite?: boolean | null,
        locationOptionVisit?: boolean | null,
        shortLocation?: string | null,
        telephone?: string | null,
        email?: string | null,
        links?: Array< string | null > | null,
        status?: BusinessStatus | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        categories?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        provider?:  {
          __typename: "Provider",
          id: string,
          userID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          schoolMajor?: string | null,
          schoolYear?: string | null,
          schoolOrganizations?: Array< string | null > | null,
          links?: Array< string | null > | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteBookingSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteBookingSubscription = {
  onDeleteBooking?:  {
    __typename: "Booking",
    id: string,
    owner?: string | null,
    businessID: string,
    customerID: string,
    serviceID: string,
    price?: number | null,
    status?: BookingStatus | null,
    createdAt?: string | null,
    chargeID?: string | null,
    note?: string | null,
    locationName?: string | null,
    date: string,
    time: string,
    duration: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    updatedAt: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      userID: string,
      universityID: string,
      studentID?: string | null,
      cartID: string,
      cbordPaymentToken?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phoneNumber?: string | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      readGroups?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      cart?:  {
        __typename: "Cart",
        id: string,
        owner?: string | null,
        content?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
      } | null,
    } | null,
    business?:  {
      __typename: "Business",
      id: string,
      universityID: string,
      providerID: string,
      owner?: string | null,
      name?: string | null,
      description?: string | null,
      locationOptionOnSite?: boolean | null,
      locationOptionVisit?: boolean | null,
      shortLocation?: string | null,
      telephone?: string | null,
      email?: string | null,
      links?: Array< string | null > | null,
      status?: BusinessStatus | null,
      image_url?: string | null,
      image?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      categories?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      provider?:  {
        __typename: "Provider",
        id: string,
        userID: string,
        universityID: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        schoolMajor?: string | null,
        schoolYear?: string | null,
        schoolOrganizations?: Array< string | null > | null,
        links?: Array< string | null > | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: AccountStatus | null,
        invitationalEmail?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
      university?:  {
        __typename: "University",
        id: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        domain?: string | null,
        cbordUniversity?: string | null,
        cbordEnabled?: boolean | null,
        utcOffset?: number | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        businesses?:  {
          __typename: "ModelBusinessConnection",
          items?:  Array< {
            __typename: "Business",
            id: string,
            universityID: string,
            providerID: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            locationOptionOnSite?: boolean | null,
            locationOptionVisit?: boolean | null,
            shortLocation?: string | null,
            telephone?: string | null,
            email?: string | null,
            links?: Array< string | null > | null,
            status?: BusinessStatus | null,
            image_url?: string | null,
            categories?: Array< string | null > | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        venues?:  {
          __typename: "ModelVenueConnection",
          items?:  Array< {
            __typename: "Venue",
            id: string,
            universityID: string,
            owner?: string | null,
            name?: string | null,
            businessName?: string | null,
            description?: string | null,
            status?: VendorStatus | null,
            type?: EventType | null,
            categoty?: VenueCategory | null,
            buffetPrice?: number | null,
            image_url?: string | null,
            payInApp?: boolean | null,
            payInPerson?: boolean | null,
            payWithCard?: boolean | null,
            payWithCash?: boolean | null,
            payWithStudentID?: boolean | null,
            payWithCurrency?: boolean | null,
            currency?: string | null,
            dineInAvailable?: boolean | null,
            reservationAvailable?: boolean | null,
            pickUpAvailable?: boolean | null,
            deliveryAvailable?: boolean | null,
            timeSlotsDineInCapacity?: number | null,
            timeSlotsPickUpCapacity?: number | null,
            timeSlotsDeliveryCapacity?: number | null,
            timeslotsIgnore?: boolean | null,
            timeslotsInterval?: number | null,
            timeSlotsStatus?: TimeSlotsStatus | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      } | null,
    } | null,
    service?:  {
      __typename: "Service",
      id: string,
      owner?: string | null,
      businessID: string,
      name: string,
      description: string,
      duration: number,
      price: number,
      active: boolean,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        universityID: string,
        providerID: string,
        owner?: string | null,
        name?: string | null,
        description?: string | null,
        locationOptionOnSite?: boolean | null,
        locationOptionVisit?: boolean | null,
        shortLocation?: string | null,
        telephone?: string | null,
        email?: string | null,
        links?: Array< string | null > | null,
        status?: BusinessStatus | null,
        image_url?: string | null,
        image?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        categories?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        provider?:  {
          __typename: "Provider",
          id: string,
          userID: string,
          universityID: string,
          firstName?: string | null,
          lastName?: string | null,
          phoneNumber?: string | null,
          schoolMajor?: string | null,
          schoolYear?: string | null,
          schoolOrganizations?: Array< string | null > | null,
          links?: Array< string | null > | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          status?: AccountStatus | null,
          invitationalEmail?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          university?:  {
            __typename: "University",
            id: string,
            owner?: string | null,
            name?: string | null,
            description?: string | null,
            domain?: string | null,
            cbordUniversity?: string | null,
            cbordEnabled?: boolean | null,
            utcOffset?: number | null,
            image_url?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
        university?:  {
          __typename: "University",
          id: string,
          owner?: string | null,
          name?: string | null,
          description?: string | null,
          domain?: string | null,
          cbordUniversity?: string | null,
          cbordEnabled?: boolean | null,
          utcOffset?: number | null,
          image_url?: string | null,
          image?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          businesses?:  {
            __typename: "ModelBusinessConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          venues?:  {
            __typename: "ModelVenueConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null,
      } | null,
    } | null,
  } | null,
};
