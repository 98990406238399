import Amplify, { API, Auth } from 'aws-amplify';
import { AWS_CONFIG } from '@campus/backend';
import { configureApisEndpointsAmplify } from './AmplifyApiConfig';

// console.log("AWSCONFGI", AWS_CONFIG);

const envPointer = AWS_CONFIG.aws_user_files_s3_bucket;
const env = envPointer.substring(envPointer.lastIndexOf('-') + 1);
const AmplifyEnv = env;

function configureAmplify(extraConfig?) {
  Amplify.configure({
    ...AWS_CONFIG,
    ...extraConfig,
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_cloud_logic_custom: undefined,
    API: {
      endpoints: configureApisEndpointsAmplify(env)
    }
  });
}

class AmpAPI {
  static async get(apiName, path, init): Promise<any> {
    init = {
      ...init,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    };
    return API.get(apiName, path, init);
  }

  static async post(apiName, path, init): Promise<any> {
    init = {
      ...init,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    };
    return API.post(apiName, path, init);
  }

  static async patch(apiName, path, init): Promise<any> {
    init = {
      ...init,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    };
    return API.patch(apiName, path, init);
  }

  static async del(apiName, path, init): Promise<any> {
    init = {
      ...init,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    };
    return API.del(apiName, path, init);
  }
}

export { configureAmplify, AmpAPI, AmplifyEnv };
