import { API, graphqlOperation } from "aws-amplify";
import { AmpAPI, configureAmplify } from "./AmplifyService";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { MUTATIONS, QUERIES, SUBS } from "@campus/backend";
import {
  GetEventQuery,
  GetOrderQuery,
  OrdersByCustomerQuery,
  OrdersByDelivererQuery,
  OrdersByVenueQuery,
  OrderStatus,
  UpdateOrderInput
} from "@campus/backend/API";
import {
  getCustomer,
  getStripeID,
  getDeliverer,
  getUserAttributes
} from "./UserService";
import { checkIfValidEvent } from "./EventService";

configureAmplify();

const ORDER_FILTER = Object.freeze({
  NO_DRAFT: {
    status: {
      ne: OrderStatus.DRAFT
    }
  },
  COMPLETED: {
    status: {
      eq: OrderStatus.COMPLETED
    }
  }
});

const getOrderPrice = async (customer, serviceOption) => {
  try {
    return await AmpAPI.get("x-campusOrderApi", "/order/price", {
      queryStringParameters: {
        customerID: customer.id,
        serviceOption
      }
    });
  } catch (e) {}
};

const placeOrder = async (
  customer,
  eventID,
  venueID,
  cardID,
  timeSlot,
  paymentMethod,
  notes = "",
  locationName,
  email,
  studentPayment
) => {
  let errorMessage = "";
  try {
    const { id: customerID, cbordPaymentToken: paymentToken } = customer;

    // TODO: Move this to a lambda
    const stripeID = await getStripeID();

    return await AmpAPI.post("x-campusOrderApi", "/order/place", {
      body: {
        customerID,
        timeSlot,
        paymentMethod,
        notes,
        cardID,
        paymentToken,
        studentPayment,
        stripeID,
        eventID,
        locationName,
        email
      }
    });
  } catch (e) {
    console.error(e);
    // TODO: Clean up after error. Delete any stranded data.
    return { order: null, error: errorMessage + " err-log: " + e.message };
  }
};

const getOrdersByVenue = async (venueID: String) => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.ordersByVenue, {
        venueID,
        filter: ORDER_FILTER.NO_DRAFT
      })
    )) as GraphQLResult<OrdersByVenueQuery>;
    return data.ordersByVenue.items;
  } catch (e) {
    console.error(e);
  }
};

const getOrderByID = async (orderID: string) => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.getOrder, {
        id: orderID
      })
    )) as GraphQLResult<GetOrderQuery>;
    return data.getOrder;
  } catch (e) {
    console.error(e);
  }
};

const getOrdersByVenueAndDate = async (venueID: String, startDate, endDate) => {
  try {
    console.log("Final TIMES: ", startDate, endDate);
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.ordersByVenue, {
        venueID,
        filter: {
          ...ORDER_FILTER.NO_DRAFT,
          createdAt: {
            ge: startDate,
            le: endDate
          }
        }
      })
    )) as GraphQLResult<OrdersByVenueQuery>;
    return data.ordersByVenue.items;
  } catch (e) {
    console.error(e);
  }
};

const updateOrderStatus = async (status: String, order) => {
  try {
    const response = await AmpAPI.post("x-campusOrderApi", "/order/update", {
      body: { status, orderID: order.id }
    });
    if (!response.error) {
      // on success send notification
      console.log("Successfully updated status");
      await sendPushNotification(status, order);
    }

    return response?.order;
    // return data["updateOrder"]
  } catch (e) {
    console.error(e);
  }
};

const assignOrderToDeliverer = async (status: String, order) => {
  try {
    const deliverer = await getDeliverer();
    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.updateOrder, {
        input: {
          id: order.id,
          _version: order._version,
          status,
          delivererID: deliverer.id
        }
      })
    )) as GraphQLResult<UpdateOrderInput>;
    // on success send notification
    console.log("Successfully updated status");
    await sendPushNotification(status, order);
    console.log("Sent Push Notification");

    return data;
  } catch (e) {
    console.error(e);
  }
};

const getPastOrdersByDeliverer = async () => {
  try {
    const deliverer = await getDeliverer();
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.ordersByDeliverer, {
        delivererID: deliverer.id,
        filter: ORDER_FILTER.COMPLETED
      })
    )) as GraphQLResult<OrdersByDelivererQuery>;
    return data.ordersByDeliverer.items;
  } catch (e) {
    console.error(e);
  }
};

const cancelOrder = async (orderID, paymentToken) => {
  try {
    return await AmpAPI.post("x-campusOrderApi", "/order/cancel", {
      body: { orderID, paymentToken }
    });
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const acceptOrder = async (orderID, directorUserID) => {
  try {
    return await AmpAPI.post("x-campusOrderApi", "/order/accept", {
      body: { orderID, directorUserID }
    });
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const rejectOrder = async orderID => {
  try {
    return await AmpAPI.post("x-campusOrderApi", "/order/reject", {
      body: { orderID }
    });
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const onUpdateOrderByCustomerID$ = customerID => {
  return API.graphql(
    graphqlOperation(SUBS.onUpdateOrderByCustomer, {
      customerID
    })
  );
};

const onUpdateOrderByVenueID$ = venueID => {
  return API.graphql(
    graphqlOperation(SUBS.onUpdateOrderByVenue, {
      venueID
    })
  );
};

const onUpdateTimeSlotByVenue$ = venueID => {
  return API.graphql(
    graphqlOperation(SUBS.onUpdateTimeSlotByVenue, {
      venueID
    })
  );
};

const getOrdersByDeliverer = async pastLimit => {
  try {
    const deliverer = await getDeliverer();
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.ordersByDeliverer, {
        delivererID: deliverer.id,
        filter: {
          createdAt: {
            ge: pastLimit
          }
        }
      })
    )) as GraphQLResult<OrdersByDelivererQuery>;
    return data.ordersByDeliverer.items;
  } catch (e) {
    console.error(e);
  }
};

const getOrdersByCustomer = async (venueID: String) => {
  try {
    const customer = await getCustomer();
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.ordersByCustomer, {
        customerID: customer.id
      })
    )) as GraphQLResult<OrdersByCustomerQuery>;
    return data.ordersByCustomer.items;
  } catch (e) {
    console.error(e);
  }
};

const getEventDetails = async (eventID: String) => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.getEvent, {
        id: eventID
      })
    )) as GraphQLResult<GetEventQuery>;

    const event = data.getEvent;

    const isValid = checkIfValidEvent(event);
    return { event, isValid };
  } catch (e) {
    console.error(e);
  }
};

const sendPushNotification = async (status, order) => {
  // compose message
  let message = "";
  switch (status) {
    case "IN_PROGRESS":
      message = `Your Order #${order.id.split("-")[0]} has been Accepted.`;
    case "COMPLETED":
      message = `Your Order #${order.id.split("-")[0]} has been Completed.`;
    case "IN_TRANSIT":
      message = `Your Order #${
        order.id.split("-")[0]
      } has been picked up and is on it's way.`;
    case "REJECTED":
      message = `Your Order #${order.id.split("-")[0]} has been Declined.`;
    default:
      message = `Your Order #${
        order.id.split("-")[0]
      } has a new status ${status}.`;
  }
  // send push request
  const resp = await AmpAPI.post("x-PushServiceAPI", "/notification", {
    body: {
      data: order.customer.userID,
      message: message,
      name: order.customer.firstName
    }
  });
  console.log("Successfully sent notification.", resp);
};

export {
  placeOrder,
  cancelOrder,
  acceptOrder,
  rejectOrder,
  getOrderByID,
  getOrdersByVenue,
  getOrdersByVenueAndDate,
  updateOrderStatus,
  assignOrderToDeliverer,
  getOrdersByCustomer,
  getOrdersByDeliverer,
  getPastOrdersByDeliverer,
  getEventDetails,
  getOrderPrice,
  onUpdateOrderByCustomerID$,
  onUpdateOrderByVenueID$,
  onUpdateTimeSlotByVenue$
};
