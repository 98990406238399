import { Auth } from 'aws-amplify';
import { AmpAPI } from './AmplifyService';
import { AWS_CONFIG } from "../../backend";

const envPointer = AWS_CONFIG.aws_user_files_s3_bucket;
const env = envPointer.substring(envPointer.lastIndexOf('-') + 1);

function getStripeClientID() {
  switch (env) {
    case 'develop':
      return 'ca_GqLIcFXaWSp5MkgYAkcejdz0HuwaPtwh';
    case 'staging':
      return 'ca_GqLIcFXaWSp5MkgYAkcejdz0HuwaPtwh';
    case 'qa':
      return 'ca_GqLIcFXaWSp5MkgYAkcejdz0HuwaPtwh';
    case 'prod':
      return 'ca_GqLImLgsAsHQrWkY6mnKnj9PL8to8XXu';
  }
}

const createStripeCustomer = async () => {
  const user = await Auth.currentAuthenticatedUser();
  const { email } = user.attributes;
  
  try {
    return await AmpAPI.post('CampusAPI', '/payments/customer', {
      body: { email }
    });
  } catch (e) {
    console.error(e);
  }
};

const createCard = async (source, customerID, metadata) => {
  try {
    console.log(source, customerID, metadata);
    return await AmpAPI.post('CampusAPI', '/payments/card', {
      body: { source, customerID, metadata }
    });
  } catch (e) {
    console.error(e);
  }
};

const createCardToken = async (number, exp_month, exp_year, cvc, currency) => {
  try {
    return await AmpAPI.post('CampusAPI', '/payments/card-token', {
      body: { number, exp_month, exp_year, cvc, currency }
    });
  } catch (e) {
    console.error(e);
  }
};

const deleteCard = async (source, customerID) => {
  try {
    return await AmpAPI.del('CampusAPI', '/payments/card', {
      body: { source, customerID }
    });
  } catch (e) {
    console.error(e);
  }
};

/* ---------------------------------------------------
 * funcionality:
    List all the card belong to the customer
    The maximu number of card they can store is 10
 * Parameters:
    @customer_id: required
 *
 * Return:
    if successful, list all the card belong to the customer
    else, this call raise an error
 * --------------------------------------------------- */
const listAllCards = async customerID => {
  try {
    return await AmpAPI.post('x-PaymentsAPI', '/payments/cards', {
      body: { customerID }
    });
  } catch (e) {
    console.error(e);
  }
};

export {
  createStripeCustomer,
  createCard,
  createCardToken,
  deleteCard,
  listAllCards,
  getStripeClientID
};
