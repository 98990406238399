import { API, Auth, graphqlOperation, Storage } from 'aws-amplify';
import { configureAmplify, AmplifyEnv } from './AmplifyService';
import { AWS_CONFIG, MUTATIONS, QUERIES } from '@campus/backend';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import {
  CreateCustomerMutation,
  DelivererByUserQuery,
  DirectorsByUserQuery,
  GetCustomerQuery,
  GetProviderQuery,
  ProvidersByUserQuery,
  StaffByUserQuery,
  UpdateCustomerMutation,
  UpdateDirectorMutation,
  UpdateProviderMutation,
  UpdateStaffMutation,
  UpdateVendorMutation,
  VendorsByUserQuery
} from '@campus/backend/API';
import { createCart } from './CartService';
import { createStripeCustomer } from './PaymentService';

configureAmplify();

const {
  aws_user_files_s3_bucket_region: region,
  aws_user_files_s3_bucket: bucket
} = AWS_CONFIG;

const getUserAttributes = async () => {
  try {
    const { attributes } = await Auth.currentAuthenticatedUser({
      bypassCache: true
    });
    return attributes;
  } catch (e) {
    console.log('not signed in. ' + e);
    throw e;
  }
};

const updateUserAttributes = async attributes => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return await Auth.updateUserAttributes(user, {
      ...attributes
    });
  } catch (e) {
    console.error(e);
  }
};

const getStripeID = async () => {
  try {
    const { attributes } = await Auth.currentAuthenticatedUser();
    if (!attributes['custom:stripe_id']) {
      const { customer } = await createStripeCustomer();
      attributes['custom:stripe_id'] = await updateUserAttributes({
        'custom:stripe_id': customer.id
      });
    }
    return attributes['custom:stripe_id'];
  } catch (e) {
    console.error(e);
  }
};

const getCustomer = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.customerByUser, { userID: user.getUsername() })
      // graphqlOperation(QUERIES.customerByUser, { userID: "doesnotexist" })
    )) as GraphQLResult<GetCustomerQuery>;
    let existingCustomer = data['customerByUser']['items'][0];
    // console.log("no customer: ", existingCustomer)
    return existingCustomer;
  } catch (e) {
    console.error(e);
  }
};

const getDeliverer = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    console.log('getting deliverer: ', user.getUsername());
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.delivererByUser, { userID: user.getUsername() })
    )) as GraphQLResult<DelivererByUserQuery>;
    let existingDeliverer = data['delivererByUser']['items'][0];
    console.log(data);
    if (!existingDeliverer) {
    }

    return existingDeliverer;
  } catch (e) {
    console.error(e);
  }
};

const getDirector = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    console.log('getting director: ', user.getUsername());
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.directorsByUser, { userID: user.getUsername() })
    )) as GraphQLResult<DirectorsByUserQuery>;
    let existingDirector = data['directorsByUser']['items'][0];
    return existingDirector;
  } catch (e) {
    console.error(e);
  }
};

const getVendor = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    console.log('getting vendor: ', user.getUsername());
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.vendorsByUser, { userID: user.getUsername() })
    )) as GraphQLResult<VendorsByUserQuery>;
    let existingVendor = data['vendorsByUser']['items'][0];
    return existingVendor;
  } catch (e) {
    console.error(e);
  }
};

const getProviderByID = async providerID => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.getProvider, { id: providerID })
    )) as GraphQLResult<GetProviderQuery>;
    return data.getProvider;
  } catch (e) {
    console.error(e);
  }
};

const getProvider = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    console.log('getting provider: ', user.getUsername());
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.providersByUser, { userID: user.getUsername() })
    )) as GraphQLResult<ProvidersByUserQuery>;
    let existingProvider = data['providersByUser']['items'][0];
    return existingProvider;
  } catch (e) {
    console.error(e);
  }
};

const getStaff = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    console.log('getting staff: ', user.getUsername());
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.staffByUser, { userID: user.getUsername() })
    )) as GraphQLResult<StaffByUserQuery>;
    return data['staffByUser']['items'][0];
  } catch (e) {
    console.error(e);
  }
};
function downloadBlob(blob, filename) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'download';
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
  return a;
}

const getProfilePicture = async customer => {
  try {
    const { image } = customer;
    if (image) {
      return await Storage.get(image.key);
      // return URL.createObjectURL(result.Body);
    } else {
      return null; //Placeholder image if there is nothing saved yet
    }
  } catch (e) {
    console.log(e);
  }
};
const updateProviderPicture = async (file, providerID = null) => {
  const { id, _version } = providerID
    ? await getProviderByID(providerID)
    : await getProvider();
  const { type } = file;
  console.log('Got provider: ', id);
  try {
    // get the file extension. eg. jpeg
    const fileExt = type.split('/').pop();
    // create a unique name for the uploaded file + extension
    const key = `provider/${id}-food-picture.${fileExt}`;
    // prepared gql mutation parameter
    const fileForUpload = {
      bucket,
      key,
      region
    };

    // uploads the image
    await Storage.put(key, file, {
      contentType: type
    });
    console.log('uploaded image, ', key);
    // Prepare data to be updated
    const inputData = { image: fileForUpload };
    // Update Provider
    const data = await updateProvider(inputData, providerID);

    return data;
  } catch (e) {
    console.error(e);
  }
};

const updateProfilePicture = async file => {
  const { uri, type: mimeType } = file;

  // This is converts string uri to blob... Magic!
  const response = await fetch(uri);
  const blob = await response.blob();

  const { id, _version } = await getCustomer();

  try {
    // get the file extension. eg. jpeg
    const fileExt = uri.split('.').pop();
    // create a unique name for the uploaded file + extension
    const key = `${id}-profile-picture.${fileExt}`;
    // prepared gql mutation parameter
    const fileForUpload = {
      bucket,
      key,
      region
    };

    // uploads the image
    await Storage.put(key, blob, {
      contentType: mimeType
    });
    console.log('uploaded image, ', key);
    // Prepare data to be updated
    const inputData = { image: fileForUpload };

    // Update customer OR deliver OR vendor
    const data = await updateCustomer(inputData);

    // Return customer OR deliver OR vendor
    return data;
  } catch (e) {
    console.error(e);
  }
};

const getTempCartID = () => {
  switch (AmplifyEnv) {
    case 'develop':
      return '1e5e2004-d2ba-48af-ac17-80155c5790e9';
    case 'staging':
      return '0730b052-7d72-42af-962a-b146e44e08f2';
    case 'prod':
      return '1036e165c-0b99-4739-b2cf-92cbebcc4f9c';
    default:
      return '111';
  }
};

const addCustomer = async params => {
  try {
    console.log(params);
    const cartID = getTempCartID();
    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.createCustomer, {
        input: { ...params, cartID }
      })
    )) as GraphQLResult<CreateCustomerMutation>;
    return data['createCustomer'];
  } catch (e) {
    console.error(e);
    return { error: e };
  }
};

const createCustomer = async (universityID, firstName, lastName) => {
  try {
    const { username: userID } = await Auth.currentAuthenticatedUser();
    const cartID = getTempCartID();
    let input = {
      userID,
      cartID,
      universityID,
      firstName,
      lastName
    };
    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.createCustomer, {
        input
      })
    )) as GraphQLResult<CreateCustomerMutation>;
    console.log(data['createCustomer']);
    return data['createCustomer'];
  } catch (e) {
    console.error(e);
    return { error: e };
  }
};

const updateCustomer = async params => {
  try {
    const { id, _version } = await getCustomer();
    if (!id) return;

    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.updateCustomer, {
        input: {
          id,
          _version,
          ...params
        }
      })
    )) as GraphQLResult<UpdateCustomerMutation>;
    return data['updateCustomer'];
  } catch (e) {
    console.error(e);
  }
};

const updateDeliverer = async params => {
  try {
    const { id, _version } = await getDeliverer();
    if (!id) return;

    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.updateDeliverer, {
        input: {
          id,
          _version,
          ...params
        }
      })
    )) as GraphQLResult<UpdateCustomerMutation>;
    return data['updateDeliverer'];
  } catch (e) {
    console.error(e);
  }
};

const updateDirector = async params => {
  try {
    const { id, _version } = await getDirector();
    if (!id) return;

    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.updateDirector, {
        input: {
          id,
          _version,
          ...params
        }
      })
    )) as GraphQLResult<UpdateDirectorMutation>;
    return data['updateDirector'];
  } catch (e) {
    console.error(e);
  }
};

const updateVendor = async params => {
  try {
    const { id, _version } = await getVendor();
    if (!id) return;

    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.updateVendor, {
        input: {
          id,
          _version,
          ...params
        }
      })
    )) as GraphQLResult<UpdateVendorMutation>;
    return data['updateVendor'];
  } catch (e) {
    console.error(e);
  }
};

const updateProvider = async (params, providerID = null) => {
  try {
    const { id, _version } = providerID
      ? await getProviderByID(providerID)
      : await getProvider();
    if (!id) return;

    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.updateProvider, {
        input: {
          id,
          _version,
          ...params
        }
      })
    )) as GraphQLResult<UpdateProviderMutation>;
    return data['updateProvider'];
  } catch (e) {
    console.error(e);
  }
};

const updateStaff = async params => {
  try {
    const { id, _version } = await getStaff();
    if (!id) return;

    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.updateStaff, {
        input: {
          id,
          _version,
          ...params
        }
      })
    )) as GraphQLResult<UpdateStaffMutation>;
    return data['updateStaff'];
  } catch (e) {
    console.error(e);
  }
};

export {
  getUserAttributes,
  getCustomer,
  getDeliverer,
  getVendor,
  getProvider,
  getStaff,
  getDirector,
  updateCustomer,
  updateDeliverer,
  updateDirector,
  updateVendor,
  updateProvider,
  updateStaff,
  addCustomer,
  createCustomer,
  getStripeID,
  updateUserAttributes,
  updateProfilePicture,
  updateProviderPicture,
  getProfilePicture,
  getProviderByID
};
