/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://b6cwanignzhcjdtqs5r5q6tqfy.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-bbb77a6yp5ahbcjbveabjqrbzu",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://l4g7hz0yof.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "campusOrderApi",
            "endpoint": "https://3jur87rjzg.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "PaymentsAPI",
            "endpoint": "https://35dpddcnc8.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "PushServiceAPI",
            "endpoint": "https://o1e09t6wka.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "VenueAPI",
            "endpoint": "https://upvai4yni1.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "MenuHelperAPI",
            "endpoint": "https://x4csmhotpf.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "ReportAPI",
            "endpoint": "https://o9b3dvabhk.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:747fd592-4b0d-4a71-afcf-92e17fc1bae0",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_jERECHluS",
    "aws_user_pools_web_client_id": "37v5u32117jslcrg4mo7l50u69",
    "oauth": {},
    "aws_user_files_s3_bucket": "campus-s3-storageprod-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
