import { API, graphqlOperation } from "aws-amplify";
import {
  GetEventQuery,
  GetFoodQuery,
  GetUniversityQuery,
  GetVenueQuery,
  ListUniversitysQuery,
  VenuesByUniversityQuery
} from "@campus/backend/API";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { configureAmplify } from "./AmplifyService";
import { CUSTOM_QUERIES, QUERIES } from "@campus/backend";
// @ts-ignore
import moment from "moment-timezone";
// import { timezone } from 'expo-localization';

configureAmplify();

const getCurrentTime = () => {
  //Fake time
  // console.log("FAKE TIME: ", moment.utc("02:35", "HH:mm").add(1, 'day').date(), moment().utc().date())
  // return moment.utc("22:35", "HH:mm");
  // return moment("20:30", "HH:mm").utc();
  //Real time with timezone
  return moment().utc(); //.subtract(2, "hours");
};

const getFormattedTime = (start, end) => {
  const startTime = moment.utc(start, "HH:mm").local();
  const endTime = moment.utc(end, "HH:mm").local();

  return startTime.format("LT") + " - " + endTime.format("LT");
};

const getDateString = date => {
  if (!date) return "";
  const orderDate = moment(date);
  const today = moment();
  if (orderDate.isSame(today, "day")) {
    return orderDate.calendar();
  }
  return orderDate.format("L");
};

const getLocalTimeslot = time => {
  return moment
    .utc(time, "HH:mm a")
    .local()
    .format("hh:mm a");
};

const getUniversityByID = async input => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.getUniversity, { id: input })
    )) as GraphQLResult<GetUniversityQuery>;
    // console.log(data.listUniversitys.items);
    return data.getUniversity;
  } catch (e) {
    console.error(e);
  }
};

const getVenuesByUniversity = async input => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.venuesByUniversity, { universityID: input })
    )) as GraphQLResult<VenuesByUniversityQuery>;
    // console.log("GetVenuesByUniversity: ", data.venuesByUniversity.items)
    return data.venuesByUniversity.items;
  } catch (e) {
    console.error(e);
  }
};

const getEventDetails = async input => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.getEvent, { id: input })
    )) as GraphQLResult<GetEventQuery>;

    return data.getEvent;
  } catch (e) {
    console.error(e);
  }
};

export {
  getCurrentTime,
  getUniversityByID,
  getEventDetails,
  getFormattedTime,
  getVenuesByUniversity,
  getDateString,
  getLocalTimeslot
};
