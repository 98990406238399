function configureApisEndpointsAmplify(env) {
  switch (env) {
    case 'develop':
      return [
        {
          name: 'CampusAPI',
          endpoint:
            'https://0kp8fqd4ga.execute-api.us-east-1.amazonaws.com/v1'
        },
        {
          name: 'x-campusOrderApi',
          endpoint:
            'https://0kp8fqd4ga.execute-api.us-east-1.amazonaws.com/develop'
        },
        {
          name: 'x-PaymentsAPI',
          endpoint:
            'https://0kp8fqd4ga.execute-api.us-east-1.amazonaws.com/develop'
        },
        {
          name: 'x-PushServiceAPI',
          endpoint:
            'https://0kp8fqd4ga.execute-api.us-east-1.amazonaws.com/develop'
        },
        {
          name: 'x-VenueAPI',
          endpoint:
            'https://0kp8fqd4ga.execute-api.us-east-1.amazonaws.com/develop'
        },
        {
          name: 'x-MenuHelperAPI',
          endpoint:
            'https://0kp8fqd4ga.execute-api.us-east-1.amazonaws.com/develop'
        },
        {
          name: 'x-ReportAPI',
          endpoint:
            'https://0kp8fqd4ga.execute-api.us-east-1.amazonaws.com/develop'
        },
        {
          name: 'x-SupportAPI',
          endpoint:
            'https://0kp8fqd4ga.execute-api.us-east-1.amazonaws.com/develop'
        },
        {
          name: 'AdminQueries',
          endpoint:
            'https://mtbfcho4b5.execute-api.us-east-1.amazonaws.com/develop'
        }
      ];
    case 'staging':
      return [
        {
          name: 'CampusAPI',
          endpoint:
            'https://fat6ye63kl.execute-api.us-east-1.amazonaws.com/staging'
        },
        {
          name: 'x-campusOrderApi',
          endpoint:
            'https://fat6ye63kl.execute-api.us-east-1.amazonaws.com/staging'
        },
        {
          name: 'x-PaymentsAPI',
          endpoint:
            'https://fat6ye63kl.execute-api.us-east-1.amazonaws.com/staging'
        },
        {
          name: 'x-PushServiceAPI',
          endpoint:
            'https://fat6ye63kl.execute-api.us-east-1.amazonaws.com/staging'
        },
        {
          name: 'x-VenueAPI',
          endpoint:
            'https://fat6ye63kl.execute-api.us-east-1.amazonaws.com/staging'
        },
        {
          name: 'x-MenuHelperAPI',
          endpoint:
            'https://fat6ye63kl.execute-api.us-east-1.amazonaws.com/staging'
        },
        {
          name: 'x-ReportAPI',
          endpoint:
            'https://fat6ye63kl.execute-api.us-east-1.amazonaws.com/staging'
        },
        {
          name: 'x-SupportAPI',
          endpoint:
            'https://fat6ye63kl.execute-api.us-east-1.amazonaws.com/staging'
        },
        {
          name: 'AdminQueries',
          endpoint:
            'https://qj8w58tl96.execute-api.us-east-1.amazonaws.com/staging'
        }
      ];
    case 'prod':
      return [
        {
          name: 'CampusAPI',
          endpoint:
            'https://h8r69titfj.execute-api.us-east-1.amazonaws.com/prod'
        },
        {
          name: 'x-campusOrderApi',
          endpoint:
            'https://h8r69titfj.execute-api.us-east-1.amazonaws.com/prod'
        },
        {
          name: 'x-PaymentsAPI',
          endpoint:
            'https://h8r69titfj.execute-api.us-east-1.amazonaws.com/prod'
        },
        {
          name: 'x-PushServiceAPI',
          endpoint:
            'https://h8r69titfj.execute-api.us-east-1.amazonaws.com/prod'
        },
        {
          name: 'x-VenueAPI',
          endpoint:
            'https://h8r69titfj.execute-api.us-east-1.amazonaws.com/prod'
        },
        {
          name: 'x-MenuHelperAPI',
          endpoint:
            'https://h8r69titfj.execute-api.us-east-1.amazonaws.com/prod'
        },
        {
          name: 'x-ReportAPI',
          endpoint:
            'https://h8r69titfj.execute-api.us-east-1.amazonaws.com/prod'
        },
        {
          name: 'AdminQueries',
          endpoint:
            'https://l4g7hz0yof.execute-api.us-east-1.amazonaws.com/prod'
        }
      ];
    default:
      throw Error('No matching environment');
  }
}

export { configureApisEndpointsAmplify };
